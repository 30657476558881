import React from 'react';
import { Table } from 'antd';
import { AddressForm } from './AddressForm';
import { getAddressColumns } from '../../../../helpers/getAddressColumns';
import { I18n } from 'react-redux-i18n';

const ClientInfoAddress: React.FC<any> = (props: any) => {
    const {form, formItemStyle, addresses, searchAddress, 
        setSelectedAddress, selectedAddress, isFoundClient, clientAddresses} = props;

    const rowSelection = {
        onSelect: (record: any, selected: any, selectedRows: any) => {
            setSelectedAddress(record.id);
        },
        selectedRowKeys: [selectedAddress],
        hideDefaultSelections: true,
        type: 'radio' as 'radio',
    };

    return (
        <div>
            {
                isFoundClient ?
                <Table
                    rowKey="id"
                    size="small" 
                    rowSelection={rowSelection} 
                    columns={getAddressColumns(I18n)} 
                    dataSource={clientAddresses} 
                    pagination={false}/>
                :
                <AddressForm 
                    addresses={addresses} 
                    searchAddress={searchAddress}
                    form={form}
                    formItemStyle={formItemStyle}
                />
            }
        </div>
    );
};

export default ClientInfoAddress;