import React from 'react';
import {DropTarget} from 'react-dnd';
import {Card} from 'antd';
import { deliveriesDnDTypes } from '../../../../constants/deliveriesDnDTypes';

const CardGrid: any = Card.Grid;

const nodeTarget = {
    drop(props: any) {
        return {
            data: props.item,
            name: props.name,
        };
    },
};

function collect(connect: any, monitor: any) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        didDrop: monitor.didDrop(),
    };
}

const MultipleDropElement = (props: any) => {
    const isActive = props.canDrop && props.isOver;
    const isEmpty = !props.item;
    return props.connectDropTarget(
        <div>
            {
                isEmpty ?
                <div style={{ ...props.style, cursor: 'pointer' }}></div> :
                <div style={{ ...props.style, cursor: 'pointer' }}>
                    <CardGrid
                        style={{
                            padding: '10px 16px',
                            width: '100%',
                            background: isActive ? '#eaeaf1' : '',
                        }}
                        onClick={() => props.onClick(props.item)}>
                        {props.children}
                    </CardGrid>
                </div>
            }
        </div>);
};

const dropTarget = DropTarget;

export default dropTarget(['box', deliveriesDnDTypes.ORDER], nodeTarget, collect)(MultipleDropElement);