import { IODataModel } from './../helpers/generateODataQuery';
import HttpService from './common/httpService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class FunctionsService {
  private httpService: HttpService;
  private functionsURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.functionsURL = `${BASE_URL}functions`;
  }

  public getFunctions = (functionsQuery: IODataModel = {}) => {
    const query = generateODataQuery(functionsQuery);
    return this.httpService.get(`${this.functionsURL}${query}`);
  }

}

export default FunctionsService;