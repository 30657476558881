/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Icon, Modal, Spin } from 'antd';
import ClientInfoDescription from './ClientInfoDescription';
import OrderProducts from './OrderProducts';
import Products from './Products';
import ClientInfoModal from './ClientInfoModal';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import OrdersService from '../../../../services/OrdersService';
import { getSuccessNotification, getErrorNotification } from '../../../Notifications/Notifications';
import { REFRESH } from '../../../../constants/createOrder';
import { OrderModel } from '../../../../models/OrderModel';
import OrderSuccess from './OrderSuccessContent';
import { getAddress } from '../../../../helpers/getAddress';
import { withDictionaryContext } from '../../../../contexts/witDictionaryContext';
import { DictionaryContext } from '../../../../contexts/DictionaryContextProvider';
import DictionaryService from '../../../../services/DictionaryService';
import { SET_DELIVERY_TYPES, SET_DELIVERY_PRICES, SET_PAYMENT_TYPES, SET_SELLPOINTS } from '../../../../constants/dictionary';
import { IStore } from '../../../../redux/store';
import { getUser } from '../../../../redux/session/sessionSelectors';
import { connect } from 'react-redux';

import { I18n, Translate } from 'react-redux-i18n';

const ordersService = new OrdersService();
const dictionaryService = new DictionaryService();
const { confirm } = Modal;

const DrawerLayout: React.FC<any> = (props: any) => {
  const { closeDrawer, orderId, closeForm } = props;

  const { createOrder: orderContext, createOrderDispatch } = useContext(CreateOrderContext);
  const { dispatchDictionary } = useContext(DictionaryContext);
  const { height } = useWindowDimensions();
  const wHeight = height - 140;

  const [isShowModal, setIsShowModal] = useState(orderId ? false : true);
  const [isFirstOpen, setIsFirstOpen] = useState(orderId ? false : true);
  const [loading, setLoading] = useState(false);

  const self = orderContext.orderInfo && orderContext.orderInfo.deliveryType && 
    orderContext.orderInfo.deliveryType.code === 'self';

  const orderStatus = orderContext.statusCode || null;

  const confirmContentRef: any = useRef();

  const getDeliveryTypes = () => {
    dictionaryService.getDeliveryTypes()
      .then((res: any) => {
        dispatchDictionary({
          type: SET_DELIVERY_TYPES,
          deliveryTypes: res,
        });
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const getDeliveryPrices = () => {
    dictionaryService.getDeliveryPrices()
      .then((res: any) => {
        dispatchDictionary({
          type: SET_DELIVERY_PRICES,
          deliveryPrices: res,
        });
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const getPaymentsTypes = () => {
    dictionaryService.getPaymentTypes()
      .then((res: any) => {
        dispatchDictionary({
          type: SET_PAYMENT_TYPES,
          paymentTypes: res,
        });
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const getSellPoints = () => {
    dictionaryService.getSellPoints()
      .then((res: any) => {
        dispatchDictionary({
          type: SET_SELLPOINTS,
          sellPoints: res,
        });
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  useEffect(() => {
    getDeliveryTypes();
    getDeliveryPrices();
    getPaymentsTypes();
    getSellPoints();
  }, []);

  const closeClientInfo = () => {
    if (isFirstOpen) {
      setIsShowModal(false);
      if (closeDrawer) {
        closeDrawer();
      }
    } else {
      setIsShowModal(false);
    }
  };

  const getTimeToPrepare = () => {
    let timeToPrepare: any = null;
    const {current} = confirmContentRef;
    if (current && current.timeToPrepare) {
      timeToPrepare = current.timeToPrepare;
    }
    return timeToPrepare;
  };

  const approveOrder = (id: number) => {
    const timeToPrepare = getTimeToPrepare();
    ordersService.confirmOrder(id, true, timeToPrepare)
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const handleCancel = (id: number) => {
    const timeToPrepare = getTimeToPrepare();
    if (timeToPrepare) {
      ordersService.confirmOrder(id, false, timeToPrepare)
        .catch((e) => {
          console.error('ERROR: ', e);
          getErrorNotification(e);
        });
    }
  };

  const handleApproveOrder = (id: number) => {
    confirm({
      title: I18n.t('orders.children.orderDrawer.children.approveOrder.value'),
      okText: 'Так',
      cancelText: 'Ні',
      onOk() {
        approveOrder(id);
        closeForm();
      }
    });
  };

  const submitClientData = () => {
    setIsShowModal(false);
    setIsFirstOpen(false);
  };

  const showClientInfo = () => {
    setIsShowModal(true);
  };

  const submitCreateOrder = () => {
    const { orderData, orderInfo, clientInfo, } = orderContext;
    const { sellPoint, deliveryPrice, deliveryType, paymentType, timeFrom, timeTo, date, comment} = orderInfo;
    const order = {
      id: 0,
      client: {
        id: clientInfo.id,
      },
      address: clientInfo.address ? {
        ...clientInfo.address,
      } : null,
      purchases: orderData.map((item: any) => ({
        product: {
          id: item.id,
        },
        comment: item.comment || null,
        count: item.count / item.minCount,
      })),
      sellPoint: sellPoint ? {
        id: sellPoint.id,
        ...sellPoint
      } : null,
      deliveryPrice: deliveryPrice ? {
        id: deliveryPrice.id,
        ...deliveryPrice
      } : null,
      deliveryType: {
        id: deliveryType.id,
        ...deliveryType
      },
      payments: {
        paymentType: {
          id: paymentType.id,
          ...paymentType
        }
      },
      minExecuteDate: date && timeFrom ? 
        timeFrom.year(date.year()).month(date.month()).date(date.date()).valueOf() : null,
      maxExecuteDate: date && timeTo ? 
        timeTo.year(date.year()).month(date.month()).date(date.date()).valueOf() : null,
      orderAddress: clientInfo.address ? getAddress(clientInfo.address) : null,
      comment: comment || null,
    };
    
    try {
      validateOrder(order);
      setLoading(true);
      let request: any;
      if (orderId) {
        request = ordersService.updateOrder(orderId, JSON.stringify(order));
      } else {
        request = ordersService.createOrder(JSON.stringify(order));
      }

      request.then((order: OrderModel) => {
        createOrderDispatch({
          type: REFRESH,
        });
        if (orderId) {
          closeForm();
          getSuccessNotification(
            I18n.t('orders.children.orderDrawer.children.orderUpdateSuccessHeading.value').replace(/\$.*\$/, order.id.toString())
          );
        } else {
          Modal.confirm({
            content: <OrderSuccess stateRef={confirmContentRef} order={order} updated={orderId ? true : false} />,
            okText: I18n.t('orders.children.orderDrawer.children.confirm.value'),
            cancelText: I18n.t('orders.children.orderDrawer.children.postpone.value'),
            onOk: () => approveOrder(order.id),
            onCancel: () => handleCancel(order.id),
            width: 420,
          });
          closeDrawer();
        }
      })
      .catch((e: any) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setLoading(false);
      });
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const validateOrder = (order: any) => {
    if (!order.purchases.length) {
      throw new Error(I18n.t('orders.children.orderDrawer.children.noProductsErr.value'));
    } else {
      const invalidData = orderContext.orderData.find((item: any) => item.isInvalid);
      if (invalidData) {
        throw new Error(I18n.t('orders.children.orderDrawer.children.unavailableProductsErr.value'));
      }
    }
    if (!order.deliveryType) {
      throw new Error(I18n.t('orders.children.orderDrawer.children.noDeliveryTypeErr.value'));
    }
    if (order.deliveryType.code === 'courier') {
      if(!order.deliveryPrice) {
        throw new Error(I18n.t('orders.children.orderDrawer.children.noDeliveryPriceErr.value'));
      }
      if(!order.address || (order.address && !Object.entries(order.address).length)) {
        throw new Error(I18n.t('orders.children.orderDrawer.children.noOrderAddressErr.value'));
      }
    }
    if (!order.payments.paymentType) {
      throw new Error(I18n.t('orders.children.orderDrawer.children.noPaymentType.value'));
    }
    if (!order.sellPoint) {
      throw new Error(I18n.t('orders.children.orderDrawer.children.noSellPointErr.value'));
    }
  };

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={12} style={{ paddingRight: '5px' }}>
          <ClientInfoDescription showClientInfo={showClientInfo} />
          <OrderProducts 
            height={wHeight - (orderId ? 45 : 0) + (self ? 30 : 0)} 
          />
        </Col>
        <Col span={12}>
          <Products height={wHeight} />
        </Col>
      </Row>
      <div style={{ position: 'fixed', bottom: '14px', right: '25px' }}>
        {
          orderId && orderStatus === 'new' &&
            <Button type="default" onClick={() => handleApproveOrder(orderId)}>
              <Icon type="check" /> 
              <Translate value={`orders.children.orderDrawer.children.postpone.value`}/>
            </Button>
        }
        <Button type="primary" onClick={submitCreateOrder}>
          <Icon type="save" /> 
            {orderId ? 
              I18n.t('orders.children.orderDrawer.children.update.value') : 
              I18n.t('orders.children.orderDrawer.children.create.value')}
        </Button>
      </div>
      {isShowModal &&
        <ClientInfoModal
          orderId={orderId}
          getSellPoints={getSellPoints}
          getDeliveryTypes={getDeliveryTypes}
          getDeliveryPrices={getDeliveryPrices}
          getPaymentsTypes={getPaymentsTypes}
          visible={isShowModal}
          onOk={submitClientData}
          onCancel={closeClientInfo}
          wHeight={wHeight} 
          defaultDeliveryType={props.user.rolesParams ? props.user.rolesParams.DEFAULT_DELIVERY_TYPE : null}
        />
      }
    </Spin>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
});

export default connect<{}, {}, any>(mapStateToProps)(withDictionaryContext(DrawerLayout));