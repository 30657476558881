import React, { useState, FocusEvent, useEffect, ChangeEvent, useRef } from 'react';
import { Input, Row, Button, Icon } from 'antd';
import ReactDOM from 'react-dom';

import css from './ProductComment.module.css';
import { I18n } from 'react-redux-i18n';

const ProductCommentInline = ({value, saveComment}: any) => {
  const [editMode, setEditMode] = useState(false);

  const [textValue, setTextValue] = useState(value || '');
  useEffect(() => {
    setTextValue(value || '');
  }, [value]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
  };
  
  let clearBtn: any = useRef(null);
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!(e.relatedTarget === clearBtn)) {
      saveComment(textValue);
      setEditMode(false);
    } else {
      e.target.focus();
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    saveComment(textValue);
    setEditMode(false);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(textValue.length, textValue.length);
  };

  const handleClearClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setTextValue('');
  };

  const handleCommentClick = () => {
    if (!editMode) {
      setEditMode(true);
    }
  };

  return (
    <Row onClick={handleCommentClick} className={css.container}>
      {editMode ?
        <Row className={css.editContainer}>
          <Input
            onChange={handleTextChange}
            className={css.input} 
            value={textValue}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onPressEnter={handleEnter}
            autoFocus
          />
          <Button 
            size="small"
            className={css.saveButton} 
            onClick={handleClearClick} 
            ref={(node) => (clearBtn = ReactDOM.findDOMNode(node))}>
            <Icon type="delete" />
          </Button>
        </Row> : 
        <Row className={css.comment}>
          {value ? <strong>{value}</strong> : 
          <span className={css.placeholder}>
            {'<' + I18n.t('orders.children.orderDrawer.children.productCommentPlaceholder.value') + '>'}
          </span>}
        </Row>
      }
    </Row>
  );
};

export default ProductCommentInline;