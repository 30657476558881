import { PageStateModel } from './pageStateReducer';
import { categoriesInitialState as initialCategoriesState } from './../components/pages/Categories/enums/CategoriesFilters';
import { initialState as initialCouriersState} from './../components/pages/Couriers/enum/CouriersFilters';
import { initialState as initialOrdersState } from './../components/pages/Orders/enums/OrdersFilters';
import { initialState as initialUsersState } from './../components/pages/Users/enums/UserFilters';
import { RolesInitialState as initialRolesState} from './../components/pages/Roles/enum/RolesFilters';
import { deliveryPackageInitialState as initialDeliveriesState } from './../components/pages/DeliveryPackages/enums/DeliveryPackageFilters';
import { productsInitialState as initialProductsState } from './../components/pages/Products/enums/ProductsFilters';
import { ClientsInitialState as initialClientsState } from './../components/pages/Clients/enums/ClientsFilters';

const prefix = 'pagesReducer';
export const SET_ORDERS = `${prefix}/SET_ORDERS_PAGE`;
export const SET_DELIVERIES = `${prefix}/SET_DELIVERIES_PAGE`;
export const SET_COURIERS = `${prefix}/SET_COURIERS_PAGE`;
export const SET_CLIENTS = `${prefix}/SET_CLIENTS_PAGE`;
export const SET_PRODUCTS = `${prefix}/SET_PRODUCTS_PAGE`;
export const SET_USERS = `${prefix}/SET_USERS_PAGE`;
export const SET_ROLES = `${prefix}/SET_ROLES_PAGE`;
export const SET_CATEGORIES = `${prefix}/SET_CATEGORIES_PAGE`;
export const REFRESH = `${prefix}/REFRESH`;

export const LOCAL_STORAGE_PAGES = `${prefix}/PAGES`;

export const setOrders = (orders: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    orders,
  }
});

export const setDeliveries = (deliveries: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    deliveries,
  }
});

export const setCouriers = (couriers: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    couriers,
  }
});

export const setClients = (clients: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    clients,
  }
});

export const setProducts = (products: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    products,
  }
});

export const setUsers = (users: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    users,
  }
});

export const setRoles = (roles: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    roles,
  }
});

export const setCategories = (categories: PageStateModel) => ({
  type: SET_ORDERS,
  payload: {
    categories,
  }
});

export const refreshPages = () => ({
  type: REFRESH,
});

interface PagesReducerStateModel {
  userId: string | number | null;
  orders: PageStateModel;
  couriers: PageStateModel;
  users: PageStateModel;
  categories: PageStateModel;
  roles: PageStateModel;
  deliveries: PageStateModel;
  products: PageStateModel;
  clients: PageStateModel;
}

export const initialState = {
  userId: null,
  orders: initialOrdersState,
  couriers: initialCouriersState,
  users: initialUsersState,
  categories: initialCategoriesState,
  roles: initialRolesState,
  deliveries: initialDeliveriesState,
  products: initialProductsState,
  clients: initialClientsState,
};

interface PagesReducerActionModel {
  type: string;
  payload: {
    [x: string]: PageStateModel;
  };
}

const localStorageState = 
  localStorage.getItem(LOCAL_STORAGE_PAGES) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_PAGES) as string);
export const initState: PagesReducerStateModel = localStorageState  || initialState;

export const pagesReducer = (state = initialState, action: PagesReducerActionModel) => {
  switch (action.type) {
    case SET_ORDERS:
    case SET_PRODUCTS:
    case SET_DELIVERIES:
    case SET_COURIERS:
    case SET_CLIENTS:
    case SET_USERS:
    case SET_ROLES:
    case SET_CATEGORIES:
      const newState = {...state, ...action.payload };
      localStorage.setItem(`${LOCAL_STORAGE_PAGES}/${state.userId || ''}`, JSON.stringify(newState));
      return newState;
    case REFRESH:
      localStorage.removeItem(`${LOCAL_STORAGE_PAGES}/${state.userId || ''}`);
      return initialState;
    default:
      return state;
  }
};
