import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class CouriersService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private couriersURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.couriersURL = BASE_URL + 'couriers';
  }

  public getCouriersOld(filters?: any, sorter?: any, pagination?: any) {
    const URL = this.ODataService.composeQuery(this.couriersURL, filters, sorter, pagination);
    return this.httpService.get(URL);
  }

  public getCouriers(couriersQuery: any) {
    const query = generateODataQuery(couriersQuery);
    return this.httpService.get(`${this.couriersURL}${query}`);
  }

  public getCouriersList(couriersQuery: any) {
    const query = generateODataQuery(couriersQuery);
    return this.httpService.get(`${this.couriersURL}/list${query}`);
  }

  public updateCourierMoney(id: number, sum: number) {
    return this.httpService.put(`${this.couriersURL}/${id}/cash_history/${sum}`, null);
  }

  public getCourierMoneyHistory(id: number, ) {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    const query = generateODataQuery({ 
      filter: {
        setDate: { gt: d.toISOString()}
      },
      orderBy: 'setDate desc',
    });
    return this.httpService.get(`${this.couriersURL}/${id}/cash_history${query}`);
  }
}

export default CouriersService;