import { CourierModel } from '../../../../models/CourierModel';
import React from 'react';
import { CONTROLS, getRefreshButtonDefaults, ControlModel, getUpdateButtonDefaults } from '../../../Controls/Controls';
import { Icon } from 'antd';
import { I18n } from 'react-redux-i18n';

export interface CourierControlsProps {
  current: CourierModel | number[] | null;
  openOrdersList: (courier: CourierModel) => void;
  openUserForm: (courier: CourierModel) => void;
  changeUsersWorkStatus: (courierIds: number[], isWork: boolean) => Promise<void>;
  refresh: () => void;
}

export const courierControls = ({
  openOrdersList, current, refresh, changeUsersWorkStatus, openUserForm
}: CourierControlsProps): ControlModel[] => {
  const multipleMode = Array.isArray(current);

  const multipleModeContorls = [
    {
      type: CONTROLS.BUTTON,
      buttonType: 'link',
      icon: <Icon type="check-square" />,
      tooltip: {
        overlay: '"На роботі" для обраних кур\'єрів',
        placement: 'topRight' as 'topRight',
        arrowPointAtCenter: true,
      },
      disabled: multipleMode && !((current as number[]).length),
      onClick: () => multipleMode ? changeUsersWorkStatus(current as number[], true) : null,
    },
    {
      type: CONTROLS.BUTTON,
      buttonType: 'link',
      icon: <Icon type="close-square" />,
      tooltip: {
        overlay: '"Відсутній" для обраних кур\'єрів',
        placement: 'topRight' as 'topRight',
        arrowPointAtCenter: true,
      },
      disabled: multipleMode && !((current as number[]).length),
      onClick: () => multipleMode ? changeUsersWorkStatus(current as number[], false) : null,
    }
  ];

  const singleModeControls = [
    {
      type: CONTROLS.BUTTON,
      buttonType: 'link',
      icon: <Icon type="file-text" />,
      tooltip: {
        overlay: I18n.t('controls.children.orderList.value'),
        placement: 'topRight' as 'topRight',
        arrowPointAtCenter: true,
      },
      disabled: !current,
      onClick: () => (!multipleMode && current) ? openOrdersList(current as CourierModel) : null,
    },
    {
      ...getUpdateButtonDefaults(),
      disabled: !current,
      onClick: () => (!multipleMode && current) ? openUserForm(current as CourierModel) : null,
    }
  ];

  const refreshButton = {
    ...getRefreshButtonDefaults(),
    onClick: () => refresh(),
  };

  return !multipleMode ? [...singleModeControls, refreshButton] : [...multipleModeContorls, refreshButton];
};