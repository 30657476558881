import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import { Table, Row, Card, Modal } from 'antd';
import OrdersService from '../../../services/OrdersService';
import ExpandIcon from '../../icons/ExpandIcon';
import { OrderStatusModel } from '../../../models/OrderStatusModel';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import { MainContext } from '../../../contexts/MainContextProvider';
import { SET_IS_SHOW_CREATE_ORDER_DRAWER } from '../../../constants/main';
import AdditionalOrderInfo from '../../AdditionalOrderInfo/AdditionalOrderInfo';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import orderColumns from './enums/OrdersColumns';
import { ordersFilters, initialState } from './enums/OrdersFilters';
import { pageStateReducer, SET_SORTER, SET_PAGINATION, SET_FILTERS, RESET_FILTERS, PageStateModel } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import DictionaryService from '../../../services/DictionaryService';
import { DeliveryTypeModel } from '../../../models/DeliveryTypeModel';
import { SellPointModel } from '../../../models/SellPointModel';
import { PaymentTypeModel } from '../../../models/PaymentTypeModel';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { orderControls } from './enums/OrdersControls';
import TableTitle from '../../TableTitle/TableTitle';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { getRowClassName } from '../../../helpers/getRowClassName';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { OrderStatuses } from '../../../constants/orderStatuses';
import { connect } from 'react-redux';
import { getUser, getLangCode } from '../../../redux/session/sessionSelectors';
import { IStore } from '../../../redux/store';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setOrders as setOrdersPage } from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';

const service = new OrdersService();
const dictionaryService = new DictionaryService();
const { confirm } = Modal;

const Orders = (props: any) => {
  const defaultDeliveryType = props.user.rolesParams ? props.user.rolesParams.DEFAULT_DELIVERY_TYPE : undefined;
  const defaultSellPointId = props.user.sellPoint ? props.user.sellPoint.id : null;
  const [orders, setOrders] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([] as OrderStatusModel[]);
  const [deliveryTypes, setDeliveryTypes] = useState([] as DeliveryTypeModel[]);
  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  const [paymentTypes, setPaymentTypes] = useState([] as PaymentTypeModel[]);
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate } = useTableSelection();
  const { height: wHeight, width: wWidth } = useWindowDimensions();
  const { mainDispatch: dispatchMainContext } = useContext(MainContext);
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);

  useEffect(() => {
    getStatuses();
    getPaymentTypes();
    getSellPoints();
    getDeliveryTypes();
  }, []);

  const { pages: { orders: ordersPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, {
    ...ordersPage,
    filters: {
      ...ordersPage.filters,
      'deliveryType/code': {
        ...ordersPage.filters['deliveryType/code'],
        value: defaultDeliveryType || ordersPage.filters['deliveryType/code'].value,
      },
      'sellPoint/id': {
        ...ordersPage.filters['sellPoint/id'],
        value: defaultSellPointId ? [defaultSellPointId] : ordersPage.filters['sellPoint/id'].value,
      }
    },
  } as PageStateModel);
  const stateRef = useRef(state);

  useEffect(() => {
    if (state.needUpdate) {
      getOrders(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setOrdersPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setOrdersPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ------------- ORDERS HANDLING ------------- //

  const getOrders = (filters = state.filters, sorter = state.sorter, pagination = state.pagination) => {
    setFetchingData(true);
    service.getFilteredOrders(getQueryObjFromState(filters, ['deletedDate desc', sorter], pagination))
      .then((value) => {
        setOrders(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const deactivateOrder = (id: number) => {
    setFetchingData(true);
    service.deactivateOrder(id)
      .then(() => {
        getOrders();
        getSuccessNotification('Замовлення деактивовано');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
        setFetchingData(false);
      });
  };

  const approveOrder = (id: number) => {
    const status = {
      orxderStatus: {
        code: OrderStatuses.confirm,
      },
    };
    service.updateOrderStatus(id, JSON.stringify(status))
      .then(() => {
        getOrders();
        getSuccessNotification('Замовлення підтверджено');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const handleApproveOrder = (id: number) => {
    confirm({
      title: 'Підтвердити замовлення?',
      okText: 'Так',
      cancelText: 'Ні',
      onOk() {
        approveOrder(id);
      }
    });
  };

  const getStatuses = () => {
    service.getOrderStatuses()
      .then((value: any) => {
        setOrderStatuses(value);
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const getDeliveryTypes = async () => {
    try {
      const deliveryTypes = await dictionaryService.getDeliveryTypes();
      setDeliveryTypes(deliveryTypes);
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const getPaymentTypes = async () => {
    try {
      const paymentTypes = await dictionaryService.getPaymentTypes();
      setPaymentTypes(paymentTypes);
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const getSellPoints = async () => {
    try {
      const sellPoints = await dictionaryService.getSellPoints();
      setSellPoints(sellPoints);
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const setOrderStatus = (orderId: number) => (code: string) => {
    const status = {
      orderStatus: {
        code,
      },
    };
    setFetchingData(true);
    service.updateOrderStatus(orderId, JSON.stringify(status))
      .then(() => {
        getOrders();
        getSuccessNotification('Статус замовлення оновлено');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
        setFetchingData(false);
      });
  };

  const openCreateOrder = () => {
    dispatchMainContext({
      type: SET_IS_SHOW_CREATE_ORDER_DRAWER,
    });
  };

  const getAdditionalOrderInfo = (order: any) => <AdditionalOrderInfo order={order} />;

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
              <FiltersPanel
                filters={ordersFilters({
                  orderStatuses, paymentTypes, deliveryTypes, sellPoints,
                  inits: {
                    ...getFilterInitsFromPageState(ordersPage.filters),
                    'deliveryType/code': defaultDeliveryType || ordersPage.filters['deliveryType/code'].value,
                    'sellPoint/id':
                      defaultSellPointId ? [defaultSellPointId] : ordersPage.filters['sellPoint/id'].value,
                  },
                  hasDefaultDeliveryType: defaultDeliveryType!!,
                  hasDefaultSellPoint: defaultSellPointId!!,
                })}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle
            controls={
              orderControls({
                currentURL: props.match.url,
                openCreateOrder,
                deleteOrder: deactivateOrder,
                approveOrder: handleApproveOrder,
                current: selected,
                refresh: getOrders
              })
            }
          />
        )}
        className="main-table"
        onChange={handleTableChange}
        pagination={state.pagination}
        onRow={onRow}
        bordered
        dataSource={orders}
        columns={orderColumns({
          setOrderStatus, orderStatuses, defaultSort: state.sorter, defaultDeliveryType, defaultSellPointId
        })}
        rowClassName={getRowClassName(selected)}
        rowKey="id"
        size="middle"
        expandedRowRender={getAdditionalOrderInfo}
        expandIcon={ExpandIcon}
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 68 - 32 - 42, x: wWidth - 300 }}
      />
    </div>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
  langCode: getLangCode(state),
});

export default connect<{}, {}, any>(mapStateToProps)(Orders);
