import { ClientModel } from '../../../../models/ClientModel';
import { 
  ControlModel, getAddButtonDefaults, CONTROLS, 
  getUpdateButtonDefaults, getDeleteButtonDefaults, getRefreshButtonDefaults } from '../../../Controls/Controls';

export interface ClientControlsProps {
  current?: ClientModel | null;
  currentURL: string;
  createNewClient: () => void;
  deleteClient: (id: number) => void;
  refresh: () => void;
}

export const clientControls = ({
  createNewClient, deleteClient, current, currentURL, refresh
}: ClientControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      type: CONTROLS.LINK,
      url: `${currentURL}/new`,
      onClick: createNewClient,
    },
    {
      ...getUpdateButtonDefaults(),
      type: current ? CONTROLS.LINK : CONTROLS.BUTTON,
      disabled: !current,
      url: current ? `${currentURL}/${current.id}` : '',
    },
    {
      ...getDeleteButtonDefaults(),
      disabled: !current,
      onClick: current ? () => deleteClient(current.id) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};

export const clientContactsControls = ({openContactModal, deleteContact, current}: any) => ([
  {
    ...getAddButtonDefaults(),
    onClick: () => openContactModal(null),
  },
  {
    ...getUpdateButtonDefaults(),
    disabled: current === null,
    onClick: () => openContactModal(current),
  },
  {
    ...getDeleteButtonDefaults(),
    disabled: current === null,
    onClick: () => deleteContact(),
  }
]);

export const clientAddressControls = ({openAddressModal, deleteAddress, current}: any) => ([
  {
    ...getAddButtonDefaults(),
    onClick: () => openAddressModal(null),
  },
  {
    ...getUpdateButtonDefaults(),
    disabled: current === null, 
    onClick: () => openAddressModal(current),
  },
  {
    ...getDeleteButtonDefaults(),
    disabled: current === null,
    onClick: () => deleteAddress(),
  }
]);