import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import genODataQuery from '../helpers/generateODataQuery';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class ProductsService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private productsURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.productsURL = BASE_URL + 'products';
  }

  public getProducts(queryObj: any) {
    const query = generateODataQuery(queryObj);
    return this.httpService.get(this.productsURL + query);
  }

  public getFilteredProducts(filter?: any) {
    let count = true;
    let top = null;
    let skip = null;
    let orderBy = null;
    const query = genODataQuery({filter, count, top, skip, orderBy});
    return this.httpService.get(`${this.productsURL}${query}`);
  }

  public getFilteredProductsPage(top: any, skip: any, filter?: any, ) {
    let count = true;
    let orderBy = null;
    const query = genODataQuery({filter, count, top, skip: (skip - 1) * top, orderBy});
    return this.httpService.get(`${this.productsURL}${query}`);
  }

  public getProduct = (id: number) => {
    const URL = `${this.productsURL}/${id}`;

    return this.httpService.get(URL);
  }

  public createProduct = (body: any) => {
    const URL = this.productsURL;

    return this.httpService.post(URL, body);
  }

  public updateProduct = (id: number, body: any = null) => {
    const URL = `${this.productsURL}/${id}`;

    return this.httpService.put(URL, body);
  }

  public deleteProduct = (id: number) => {
    const URL = `${this.productsURL}/${id}`;

    return this.httpService.delete(URL);
  }

  public getImage = (uuid: string) => {
    const URL = `${BASE_URL}images/${uuid}`;

    return this.httpService.getFile(URL);
  }

  public uploadProductImages = (id: number, images: any) => {
    const URL = `${this.productsURL}/images/${id}`;

    return this.httpService.postFile(URL, images);
  }

  public deleteProductImages = (images: string) => {
    const URL = `${this.productsURL}/images`;

    return this.httpService.delete(URL, images);
  }

  public getCategories = () => {
    const URL = BASE_URL + 'categories?$select=id&$select=name&$filter=isActive eq true';

    return this.httpService.get(URL);
  }

  public getExternalIds = () => {
    const URL = `${this.productsURL}?$select=externalId`;

    return this.httpService.get(URL);
  }
}

export default ProductsService;
