/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useReducer, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Icon,
  Button,
  Drawer,
  notification,
  Spin,
} from 'antd';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import OrdersList from './components/OrdersList';
import CouriersList from './components/CouriersList';
import DeliveryPackagesService from '../../../services/DeliveryPackagesService';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import {
  CLEAR_MARKED_ORDERS,
  SET_IS_HIDE_DRAWER,
  REFRESH,
  SET_IS_LOADING,
  SET_IS_SHOW_COURIER_CONFIRM,
} from '../../../constants/delivery';

import './DeliveryPackages.css';
import DictionaryContextProvider from '../../../contexts/DictionaryContextProvider';
import OrdersService from '../../../services/OrdersService';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import { withDeliveryContext } from '../../../contexts/withDeliveryContextProvider';
import { DeliveryContext } from '../../../contexts/DeliveryContextProvider';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { deliveryPackageInitialState, deliveryPackageFilters } from './enums/DeliveryPackageFilters';
import { RESET_FILTERS, SET_FILTERS, pageStateReducer } from '../../../reducers/pageStateReducer';
import { SellPointModel } from '../../../models/SellPointModel';
import DictionaryService from '../../../services/DictionaryService';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setDeliveries as setDeliveriesPage } from '../../../reducers/pagesReducer';
import { getUser } from '../../../redux/session/sessionSelectors';
import { connect } from 'react-redux';
import { IStore } from '../../../redux/store';
import { I18n, Translate } from 'react-redux-i18n';
import { DeliveryTypes } from '../../../constants/deliveryTypes';

const deliveryPackagesService = new DeliveryPackagesService();
const ordersService = new OrdersService();
const dictionarySevice = new DictionaryService();

const DrawerForm = ({
  delivery,
  onCloseDrawer,
}: any) => (
    <Drawer
      title={delivery.drawer ? delivery.drawer.title : ''}
      width={720}
      onClose={onCloseDrawer}
      visible={delivery.isShowDrawer}
      destroyOnClose={true}
    >
      {delivery.drawer ? delivery.drawer.content : ''}
    </Drawer>
  );

const DeliveryPackages: React.FC = (props: any) => {
  const { delivery, deliveryDispatch } = useContext(DeliveryContext);
  const { height: wHeight } = useWindowDimensions();
  const defaultSellPointId = props.user.sellPoint ? props.user.sellPoint.id : null;
  const [orders, setOrders] = useState([]);
  const [couriers, setCouriers] = useState([]);

  const { pages: { deliveries: deliveriesPage}, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, deliveriesPage);
  const stateRef = useRef(state);
  useEffect(() => {
    deliveryPackagesService.setRefreshPage(deliveryDispatch, REFRESH);
    pagesDispatch(setDeliveriesPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setDeliveriesPage(stateRef.current));
  }, [pagesDispatch]);

  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  useEffect(() => {
    dictionarySevice.getSellPoints()
      .then((sellPoints: SellPointModel[]) => setSellPoints(sellPoints))
      .catch((e) => getErrorNotification(e));
  }, []);

  const { isLoading } = delivery;
  const setIsLoading = (isLoading: boolean) => {
    deliveryDispatch({
      type: SET_IS_LOADING,
      isLoading,
    });
  };

  const getQueryObj = (type: 'courier' | 'order') => {
    const { sellPoints, minExecuteDate } = state.filters;
    const courier = type === 'courier';

    const filter: any[] = courier ? [{isActive: true}] : [{
      'deliveryPackage': { eq: null },
      'deliveryType/code': { eq: 'courier' },
    }];

    if (sellPoints && sellPoints.value && sellPoints.value.length) {
      filter.push({ 
        or: [
          ...sellPoints.value.map((item: any) => ({ 
            [`${courier ? 'defaultSellPoint' : 'sellPoint'}/id`]: encodeURIComponent(item) 
          })),
          courier ? { 'defaultSellPoint/id': null } : null
        ]
      });
    }

    const [from, to] = minExecuteDate.value;
    if (from && to) {
      filter.push({ and: [
        { 
          [`${courier ? 'orders/' : ''}minExecuteDate`]: {
            gt: JSON.parse(JSON.stringify(from)),
          }
        },
        { 
          [`${courier ? 'orders/' : ''}minExecuteDate`]: {
            lt: JSON.parse(JSON.stringify(to)),
          }
        }
      ]});
    }

    const orderBy = courier ? null : 'minExecuteDate asc';

    return { filter, orderBy };
  };

  const getOrders = async () => {
    try {
      const orders = await ordersService.getFilteredOrders(getQueryObj('order'));
      setOrders(orders);
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const getCouriers = async () => {
    try {
      const couriers = await deliveryPackagesService.getFilteredCouriers(getQueryObj('courier'));
      setCouriers(couriers);
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const clearMarkedOrders = () => {
    deliveryDispatch({
      type: CLEAR_MARKED_ORDERS,
    });
  };

  const successNotification = (result: any) => {
    getSuccessNotification(result && result.message ? result.message : '')
  };

  const setOrdersWidthCreate = async (courier: string, fields: any[]) => {
    const sellPoint: number = fields[0].sellPoint.id;
    const orders: number[] = fields.map((item: any) => item.id);
    const result = await deliveryPackagesService.createDeliveryPackage(courier, {
      sellPoint,
      orders,
    });
    successNotification(result);
  };

  const onDropped = (fields: any[], dropItem: any) => {
    const validate = () => (fields && fields.length > 0 && dropItem);
    if (validate() && dropItem.name === DeliveryTypes.courier) {
      deliveryDispatch({
        type: SET_IS_SHOW_COURIER_CONFIRM,
        data: {
          currentCourier: dropItem.id,
          fields,
          drop: dropItem,
        },
      });
    }
  };

  const addOrdersToPackage = async (drop: any, fields: any[] = []) => {
    try {
      setIsLoading(true);
      if (drop.isCreate) {
        await setOrdersWidthCreate(drop.data.id, fields);
      } else {
        const id = drop.data.id;
        const result = await deliveryPackagesService
          .addOrdersToDeliveryPackage(id, fields.map((item: any) => item.id));
        successNotification(result);
      }
      deliveryPackagesService.setRefreshPage(deliveryDispatch, REFRESH);
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseDrawer = () => {
    deliveryDispatch({
      type: SET_IS_HIDE_DRAWER,
    });
  };

  const getPageInfo = async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        getOrders(),
        getCouriers(),
      ]);
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (delivery.isRefresh) {
      getPageInfo();
    }
  }, [delivery.isRefresh]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: deliveryPackageInitialState.filters,
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DeliveryContext.Provider value={{ delivery, deliveryDispatch }}>
        <DictionaryContextProvider>
          <DrawerForm delivery={delivery} onCloseDrawer={onCloseDrawer} />
          <Spin spinning={isLoading}>
            <Row>
              <Row className="top-panel" style={{marginBottom: '5px'}}>
                <Card>
                  <Row type="flex" align="middle" justify="start">
                    <FiltersPanel
                      filters={deliveryPackageFilters({ 
                        sellPoints, districts: [], 
                        inits: {
                          sellPoints: 
                            defaultSellPointId ? [defaultSellPointId] : deliveriesPage.filters.sellPoints.value,
                          districts: [],
                          minExecuteDate: deliveriesPage.filters.minExecuteDate.value,
                        },
                      })}
                      onFiltersConfirm={setFilters}
                      onFiltersReset={resetFilters}
                    />
                  </Row>
                </Card>
              </Row>
              <Col span={16} style={{ paddingRight: '5px' }} className="delivery-orders-list">
                <Card
                  title={I18n.t('deliveries.children.ordersList.value')}
                  extra={
                    <div>
                      <Button onClick={clearMarkedOrders}>
                        <Icon type="highlight" /> 
                        <Translate value={`deliveries.children.unselect.value`} />
                      </Button>
                    </div>
                  }
                  style={{ height: wHeight - 200 }}
                  headStyle={{
                    height: '65px',
                  }}
                  bodyStyle={{
                    padding: '0',
                    height: wHeight - 200 - 65,
                    overflow: 'auto',
                  }}
                >
                  <OrdersList
                    dataSource={orders}
                    onDropped={onDropped}
                    name="orders"
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{ height: wHeight - 200 }}
                  bodyStyle={{
                    height: '100%',
                    overflow: 'auto',
                  }}
                >
                  {
                    <CouriersList
                      dataSource={couriers}
                      name="couriers"
                      onSubmitOrders={addOrdersToPackage}
                      onDropped={onDropped}
                    />
                  }
                </Card>
              </Col>
            </Row>
          </Spin>
        </DictionaryContextProvider>
      </DeliveryContext.Provider>
    </DndProvider>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
});

export default connect<{}, {}, any>(mapStateToProps)(withDeliveryContext(DeliveryPackages));