// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FiltersPanel_filtersForm__2QzbV {\n  width: 100%; }\n\n.FiltersPanel_selectMultiple__1I7ij {\n  min-width: 200px;\n  max-width: 250px; }\n\n.FiltersPanel_select__2kkra, .FiltersPanel_input__1csId {\n  min-width: 150px;\n  max-width: 150px; }\n\n.FiltersPanel_filedsContainer__QVEYd {\n  padding-right: 12px;\n  max-height: 80px;\n  overflow-y: auto; }\n\n.FiltersPanel_buttonsContainer__3XCrX {\n  box-sizing: border-box;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center; }\n\n.FiltersPanel_button__mXM9H:first-child {\n  margin-right: 12px; }\n", ""]);
// Exports
exports.locals = {
	"filtersForm": "FiltersPanel_filtersForm__2QzbV",
	"selectMultiple": "FiltersPanel_selectMultiple__1I7ij",
	"select": "FiltersPanel_select__2kkra",
	"input": "FiltersPanel_input__1csId",
	"filedsContainer": "FiltersPanel_filedsContainer__QVEYd",
	"buttonsContainer": "FiltersPanel_buttonsContainer__3XCrX",
	"button": "FiltersPanel_button__mXM9H"
};
module.exports = exports;
