import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterListModel } from '../../models/RouterListModel';
import { NotFoundPage } from '../EmptyData/EmptyData';

interface IRoutes {
    options: RouterListModel[];
}

const Routes: React.FC<IRoutes> = ({ options }: IRoutes) => {
    const initChildRouting = () => {
        const result: RouterListModel[] = [];
        options.forEach((item: RouterListModel) => {
            if (Array.isArray(item.routes) && item.routes.length > 0) {
                result.push(...item.routes);
            }
        });
        return result;
    };

    const parseRouteItem = (items: RouterListModel[]) => {
        return items.map((route: RouterListModel, idx: number) => {
            if (route.component) {
                return (
                    <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any) => (
                            <route.component {...props} />
                        )}
                    />
                );
            }
            return;
        });
    };

    return (
        <Switch>
            {/* render childs routes */}
            {parseRouteItem(initChildRouting())}
            {/* render parents routes */}
            {parseRouteItem(options)}
            <Route path={'/'} exact={true} />
            <Route component={(props: any) => <NotFoundPage {...props} />} />
        </Switch>
    );
};

export default Routes;