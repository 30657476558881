import React, {createContext, useReducer} from 'react';
import {initialState, mainReducer} from '../reducers/mainReducer';

export const MainContext = createContext(({} as any));

const MainContextProvider: React.FC = (props: any) => {
    const [main, mainDispatch] = useReducer(mainReducer, initialState);

    return (
        <MainContext.Provider value={{ main, mainDispatch }}>
            {props.children}
        </MainContext.Provider>
    );
};

export default MainContextProvider;