import { IODataModel } from './../helpers/generateODataQuery';
import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class UsersService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private usersURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.usersURL = BASE_URL + 'users';
  }

  public getUsers(queryObj?: IODataModel) {
    const query = generateODataQuery(queryObj);
    return this.httpService.get(`${this.usersURL}${query}`);
  }

  public getUser = (id: number) => {
    const URL = `${this.usersURL}/${id}`;

    return this.httpService.get(URL);
  }

  public createUser = (body: any) => {
    const URL = this.usersURL;
    return this.httpService.post(URL, body);
  }

  public updateUser = (id: number, body: any = null) => {
    const URL = `${this.usersURL}/${id}`;

    return this.httpService.put(URL, body);
  }

  public deactivateUser = (id: number) => {
    const URL = `${this.usersURL}/deactivate/${id}`;

    return this.httpService.put(URL, null);
  }

  public activateUser = (id: number) => {
    const URL = `${this.usersURL}/activate/${id}`;

    return this.httpService.put(URL, null);
  }

  public getRoles = () => {
    const URL = BASE_URL + 'roles';

    return this.httpService.get(URL);
  }

  public changeUserWorkStatus = (id: number, isActive: boolean) => {
    return this.httpService.put(`${this.usersURL}/${id}/setWorkStatus`, JSON.stringify({ isActive }));
  }

  public changeUsersWorkStatus = (userIds: number[], isActive: boolean) => {
    return this.httpService.put(`${this.usersURL}/setWorkStatus`, JSON.stringify({ userIds, isActive }));
  }
}

export default UsersService;