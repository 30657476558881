import React, { useContext } from 'react';
import {
    Form,
    Row,
    Col,
    Radio,
    Icon,
    Card,
    DatePicker,
    Select,
    TimePicker,
} from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import { TIME_FORMAT, DATE_FORMAT } from '../../../../constants/formats';
import { SellPointModel } from '../../../../models/SellPointModel';
import { DeliveryPriceModel } from '../../../../models/DeliveryPriceModel';
import { DeliveryTypeModel } from '../../../../models/DeliveryTypeModel';
import { PaymentTypeModel } from '../../../../models/PaymentTypeModel';
import moment from 'moment';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../../../../redux/session/sessionSelectors';
import { IStore } from '../../../../redux/store';
import * as sysParams from '../../../../constants/SystemParamsVariables';
import { DeliveryTypes } from '../../../../constants/deliveryTypes';

import { I18n, Translate } from 'react-redux-i18n';

const deliveryTypeIcons: any = {
    courier: 'car',
    self: 'user',
};

const paymentTypeIcons: any = {
    credit: 'credit-card',
    cash: 'dollar',
};

const TimePickerOptions = {
    format: TIME_FORMAT,
    minuteStep: 15,
    allowClear: false,
    style: {
        width: '100%',
    },
};

const DateTimePicker = ({ form, formItemStyle }: any) => {
    const { createOrder: context } = useContext(CreateOrderContext);
    const { orderInfo } = context;

    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    const maxFrom = moment().hours(21).minutes(30);
    const maxTo = moment().hours(22).minutes(30);
    const now = getDateTime();

    const hoursRange: number[] = [];
    for (let i = 0; i < 13; i++) {
        hoursRange[i] = i + 10;
    }

    function getDateTime(incHour: number = 0) {
        const now = moment();
        now.hour(now.hour() + 1 + incHour);
        now.minute(0);
        return now;
    }

    function isSameDate(fd: any, sd: any) {
        return fd.year() === sd.year() && fd.month() === sd.month() && fd.date() === sd.date();
    }

    const handleDateChange = (date: any) => {
        if (!date) {
            return;
        }

        if (isSameDate(date, now)) {
            setFieldsValue({
                timeFrom: now,
                timeTo: getDateTime(1),
            });
        } else {
            setFieldsValue({
                timeFrom: moment().hours(10).minutes(0),
                timeTo: moment().hours(11).minutes(0),
            });
        }
    };

    const handleFromSelect = (timeFrom: any) => {
        let timeTo = moment(timeFrom);
        timeTo.hours(timeTo.hours() + 1);
        if (timeTo > maxTo) {
            timeTo = moment(maxTo);
        }
        if (timeFrom > maxFrom) {
            timeFrom.hours(maxFrom.hours()).minutes(maxFrom.minutes());
        }
        setFieldsValue({
            timeTo,
        });
    };

    const getDisabledDate = (current: any) => {
        return current < moment().hours(0);
    };

    const getDisabledHours = (picker?: string) => {
        const disabledHours: number[] = [];
        const todaySelected = isSameDate(getFieldValue('date'), now);
        for (let i = 0; i < 24; i++) {
            if (todaySelected ? hoursRange.includes(i) && (i >= now.hour()) : hoursRange.includes(i)) {
                continue;
            }
            disabledHours.push(i);
        }

        if (picker === 'timeFrom') {
            disabledHours.push(22);
        }

        if (picker === 'timeTo') {
            const from = getFieldValue('timeFrom').hours();
            for (let i = from - 1; i >= hoursRange[0]; i--) {
                disabledHours.push(i);
            }
        }

        return disabledHours;
    };

    const getDisabledMinutes = (hour: any, picker?: string) => {
        const disabledMinutes: number[] = [];
        if (picker === 'timeFrom' && hour === 21) {
            disabledMinutes.push(45);
        }

        if (hour >= 22) {
            disabledMinutes.push(45);
        }
        return disabledMinutes;
    };

    return (
        <Card
            size="small"
            title={I18n.t('form.children.deliveryDate.value')}
            bordered={false}>
            <Row gutter={6}>
                <Col span={4}><span className="required-point">*</span>
                    <Translate value={`form.children.deliveryDate.children.date.value`} />:
                </Col>
                <Col span={18}>
                    <Form.Item style={formItemStyle}>
                        {getFieldDecorator('date', {
                            initialValue: (orderInfo && orderInfo.date) || moment(),
                            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                        })(
                            <DatePicker
                                onChange={handleDateChange}
                                disabledDate={getDisabledDate}
                                style={{ width: '100%' }}
                                format={DATE_FORMAT}>
                            </DatePicker>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={6}>
                <Col span={4}><span className="required-point">*</span>
                    <Translate value={`form.children.deliveryDate.children.time.value`} />:
                </Col>
                <Col span={8}>
                    <Form.Item style={formItemStyle}>
                        {getFieldDecorator('timeFrom', {
                            initialValue: (orderInfo && orderInfo.timeFrom) || getDateTime(),
                            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                        })(
                            <TimePicker
                                hideDisabledOptions
                                disabledMinutes={(h: number) => getDisabledMinutes(h, 'timeFrom')}
                                disabledHours={() => getDisabledHours('timeFrom')}
                                {...TimePickerOptions}
                                onChange={handleFromSelect} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: 'center', marginTop: '4px' }}>-</Col>
                <Col span={8}>
                    <Form.Item style={formItemStyle}>
                        {getFieldDecorator('timeTo', {
                            initialValue: (orderInfo && orderInfo.timeTo) || getDateTime(1),
                            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                        })(
                            <TimePicker
                                hideDisabledOptions
                                disabledHours={() => getDisabledHours('timeTo')}
                                disabledMinutes={getDisabledMinutes}
                                {...TimePickerOptions} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

const ClientInfoDelivery: React.FC<any> = (props: any) => {
    const { createOrder: context } = useContext(CreateOrderContext);
    const { orderInfo } = context;
    const {
        form,
        formItemStyle,
        deliveryTypes,
        deliveryPrices,
        paymentsTypes,
        sellPoints,
        user
    } = props;

    const defaultDeliveryType = user && user.rolesParams && user.rolesParams[sysParams.DEFAULT_DELIVERY_TYPE] ?
        user.rolesParams[sysParams.DEFAULT_DELIVERY_TYPE] : null;
    const defaultSellPoint = user && user.sellPoint && user.sellPoint.id;
    const { getFieldDecorator } = form;
    const isExistsProperty = (key: string) => {
        return orderInfo && orderInfo[key];
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Form.Item label={I18n.t('form.children.deliveryType.value')} style={formItemStyle}>
                        {getFieldDecorator('typeOfDelivery', {
                            initialValue: isExistsProperty('deliveryType') ?
                                orderInfo.deliveryType.code : (defaultDeliveryType || DeliveryTypes.courier),
                            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                        })(
                            <Radio.Group buttonStyle="solid">
                                {
                                    deliveryTypes && deliveryTypes.length > 0 &&
                                    deliveryTypes.map((item: DeliveryTypeModel) => {
                                        if (defaultDeliveryType && defaultDeliveryType !== item.code) {
                                            return null;
                                        }
                                        return (
                                            <Radio.Button value={item.code} key={item.id}>
                                                <Icon type={deliveryTypeIcons[item.code]} />
                                                <Translate value={`form.children.deliveryType.children.${item.code}.value`} />
                                            </Radio.Button>
                                        );
                                    })
                                }
                            </Radio.Group>,
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={I18n.t('form.children.paymentType.value')} style={formItemStyle}>
                        {getFieldDecorator('typeOfPayment', {
                            initialValue: isExistsProperty('paymentType') ? orderInfo.paymentType.code : 'cash',
                            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                        })(
                            <Radio.Group buttonStyle="solid">
                                {
                                    paymentsTypes && paymentsTypes.length > 0 &&
                                    paymentsTypes.map((item: PaymentTypeModel) => (
                                        <Radio.Button value={item.code} key={item.id}>
                                            <Icon type={paymentTypeIcons[item.code]} />
                                            <Translate value={`form.children.paymentType.children.${item.code}.value`} />
                                        </Radio.Button>
                                    ))
                                }
                            </Radio.Group>,
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={6}>
                <Col span={12}>
                    <DateTimePicker form={form} formItemStyle={formItemStyle} />
                </Col>
                <Col span={12}>
                    <Card size="small" title={I18n.t('form.children.additionally.value')} bordered={false}>
                        {form.getFieldsValue().typeOfDelivery === DeliveryTypes.courier ?
                            <Row gutter={6}>
                                <Col span={11}><span className="required-point"
                                    style={{ opacity: 0 }}>*</span>
                                    <Translate value={`form.children.deliveryPrice.value`} />:
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={formItemStyle}>
                                        {getFieldDecorator('deliveryPrice', {
                                            initialValue: isExistsProperty('deliveryPrice') ?
                                                orderInfo.deliveryPrice.id : null,
                                            rules: [{ required: false, message: I18n.t('form.children.requiredField.value') }],
                                        })(
                                            <Select>
                                                {deliveryPrices.map((deliveryPrice: DeliveryPriceModel) =>
                                                    <Select.Option
                                                        key={deliveryPrice.id}
                                                        value={deliveryPrice.id}
                                                    >
                                                        {`${deliveryPrice.price} - ${deliveryPrice.description}`}
                                                    </Select.Option>,
                                                )}
                                            </Select>,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row> : null
                        }
                        <Row gutter={6}>
                            <Col span={11}><span className="required-point"
                                style={
                                    form.getFieldValue('typeOfDelivery') === DeliveryTypes.courier ? { opacity: 0 } : {}
                                }>*</span>
                                <Translate value={`form.children.salePoint.value`} />:
                            </Col>
                            <Col span={12}>
                                <Form.Item style={formItemStyle}>
                                    {getFieldDecorator('sellPoint', {
                                        initialValue: defaultSellPoint || (isExistsProperty('sellPoint') ?
                                            orderInfo.sellPoint.id : null),
                                        rules: [{
                                            required: form.getFieldValue('typeOfDelivery') === DeliveryTypes.self,
                                            message: I18n.t('form.children.requiredField.value')
                                        }],
                                    })(
                                        <Select disabled={!!defaultSellPoint}>
                                            {sellPoints.map((sellPoint: SellPointModel) =>
                                                <Select.Option
                                                    value={sellPoint.id}
                                                    key={sellPoint.id}>
                                                    {sellPoint.name}
                                                </Select.Option>,
                                            )}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps: any = (state: IStore) => ({
    user: selectors.getUser(state),
});
export default compose(
    connect<{}, {}, any>(
        mapStateToProps
    ),
)(ClientInfoDelivery);
