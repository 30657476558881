import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import OrdersPackageList from '../../OrdersPackageList/OrdersPackageList';
import DeliveryPackagesService from '../../../services/DeliveryPackagesService';
import { getErrorNotification } from '../../Notifications/Notifications';

const service = new DeliveryPackagesService();

const CourierOrdersForm = (props: any) => {
  const { packageId, visible, closeForm, courier, goToOrder } = props;
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (packageId) {
      getOrders(packageId);
    }
  }, [packageId]);

  const getOrders = async (id: number) => {
    try {
      setIsLoading(true);
      let orders = await service.getOrdersByPackageId(id);
      setOrders(orders);
    } catch (e) {
      getErrorNotification(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      title={`Список замовлень кур'єра #${courier.id}, ${courier.firstName} ${courier.lastName}`}
      visible={visible}
      onCancel={closeForm}
      onOk={closeForm}
      okText="ОК"
      cancelButtonProps={{
        style: {
          display: 'none',
        }
      }}
    >
      <Spin spinning={isLoading}>
        <OrdersPackageList  orders={orders} goToOrder={goToOrder}/>
      </Spin>
    </Modal>
  );
};

export default CourierOrdersForm;