import React, { useContext, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
} from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import { orderProductsColumns } from '../enums/OrderProductsColumns';
import {
  SET_ORDER_DATA,
  SET_ORDER_AND_TOTAL_SUM,
} from '../../../../constants/createOrder';
import { DeliveryTypes } from '../../../../constants/deliveryTypes';
import { getMoneyFormat } from '../../../../helpers/getMoneyFormat';
import { ProductModel } from '../../../../models/ProductModel';
import ProductsService from '../../../../services/ProductsService';
import SettingsService from '../../../../services/SettingsService';
import ProductCommentInline from '../../../ProductComment/ProductCommentInline';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../../../../redux/session/sessionSelectors';
import { IStore } from '../../../../redux/store';
import { I18n, Translate } from 'react-redux-i18n';

const defaultPriceSellPointName = 'default_price_sell_point';

interface OrderProductsModel {
  height: number;
  settings: any;
}

const productsService = new ProductsService();

const OrderProducts: React.FC<OrderProductsModel> = ({
  height,
  settings,
}: OrderProductsModel) => {
  const {
    createOrder: context,
    createOrderDispatch: contextDispatch
  } = useContext(CreateOrderContext);
  const { orderData, totalSum, orderInfo } = context;
  const currSellPoint = orderInfo && orderInfo.sellPoint && orderInfo.sellPoint.id;

  const settingsService = new SettingsService(settings);

  useEffect(() => {
    if (currSellPoint && orderData.length) {
      getFilteredOrderData(currSellPoint);
    }
  }, [currSellPoint]);

  const getCurrentPrice = (row: any) => {
    if (row) {
      const defaultSellPoint = settingsService.getSettingsValueByName(defaultPriceSellPointName);
      const {productOptions} = row;

      const currOption = productOptions.find((item: any) => {
        const sellPoint = item.sellPoint ? item.sellPoint : undefined;
        if (sellPoint && orderInfo) {
          return (orderInfo.sellPoint && sellPoint.id === orderInfo.sellPoint.id) ||
            sellPoint.id === defaultSellPoint;
        }
        return false;
      });
      if (currOption) {
        return Number(currOption.price);
      } else if (productOptions && productOptions.length > 0) {
        return productOptions[0].sellPoint && Number(productOptions[0].price);
      }
    }
    return 0;
    // TODO: remove when will check
    // const currOption = productOptions && productOptions.find((item: any) =>
    //   item.sellPoint && item.sellPoint.id === defaultSellPoint);

    // if (currOption) {
    //   return Number(currOption.price);
    // } else {
    //   if (productOptions && productOptions.length > 0) {
    //     return Number(productOptions[0].price);
    //   } else {
    //     return 0;
    //   }
    // }
  };

  const findTotal = (orderData: any) => {
    let total: number = 0;
    for (const product of orderData) {
      total += (getCurrentPrice(product) / product.minCount * product.count);
    }

    return total;
  };

  const getFilteredOrderData = (sellPointId: number) => {
    const isFilter = orderInfo && orderInfo.deliveryType && orderInfo.deliveryType.code === DeliveryTypes.self;
    const filter = {
      ...Object.assign(
        {},
        isFilter && sellPointId ? {'productOptions/sellPoint/id': { eq: sellPointId }} : {}
      ),
      'id': { in: orderData.map((item: any) => item.id) },
    };

    productsService.getFilteredProducts(filter)
      .then((result) => {
        const data = isFilter ? result : result.items.map((product: any) => {
          const {productOptions} = product;
          if (orderInfo && orderInfo.sellPoint) {
            if (productOptions && productOptions.length > 0) {
              const sellPoint = productOptions.find((item: any) => item.sellPoint.id === orderInfo.sellPoint.id);
              product.isInvalid = !sellPoint || !sellPoint.available;
            }
          }
          return product;
        });
        const newOrderData = data.map((product: ProductModel) => {
          const item = orderData.find((dataItem: any) => dataItem.id === product.id);
          if (item) {
            return { ...item, ...product };
          } else {
            return {
              count: product.minCount,
              ...product,
            };
          }
        });
        contextDispatch({
          type: SET_ORDER_AND_TOTAL_SUM,
          orderData: newOrderData,
          totalSum: findTotal(newOrderData),
        });
      });
  };

  const handleProductQuantity = (orderDataItem: any) => (value: number) => {
    const newOrderData = orderData.map((item: any) => {
      if (item.id === orderDataItem.id) {
        return {...item, count: value < orderDataItem.minCount ? orderDataItem.minCount : value};
      }
      return item;
    });
    contextDispatch({
      type: SET_ORDER_AND_TOTAL_SUM,
      orderData: newOrderData,
      totalSum: findTotal(newOrderData),
    });
  };

  const handleDelete = (index: number) => {
    const newOrderData = orderData.slice(0);
    newOrderData.splice(index, 1);
    contextDispatch({
      type: SET_ORDER_AND_TOTAL_SUM,
      orderData: newOrderData,
      totalSum: findTotal(newOrderData),
    });
  };

  const saveComment = (comment: string, id: number) => {
    const newOrderData = orderData.map((item: ProductModel) => {
      if (item.id === id) {
        return { ...item, comment: comment || null, };
      }
      return item;
    });
    contextDispatch({
      type: SET_ORDER_DATA,
      orderData: newOrderData,
    });
  };

  const noExpandIcon = () => null;

  const getProductComment = (product: ProductModel) => (
    <ProductCommentInline
      key={product.id} value={product.comment}
      saveComment={(comment: string) => saveComment(comment, product.id)}
    />
  );

  return (
    <div>
      <Table
        rowKey="id"
        className={'order-data-table'}
        rowClassName={(row) => row.isInvalid ? 'order-data-row-invalid' : 'order-data-row'}
        dataSource={orderData}
        bordered={false}
        columns={
          orderProductsColumns({
            handleProductQuantity,
            handleDelete,
            getCurrentPrice,
          })
        }
        expandedRowKeys={orderData.map((item: any) => item.id)}
        expandIcon={noExpandIcon}
        expandIconAsCell={false}
        expandedRowRender={getProductComment}
        size="small"
        pagination={false}
        scroll={{ y: height - 285 }}
        footer={() => {
          return (
            <div>
              <Row
                type="flex"
                justify="space-between"
              >
                <Col><Translate value={I18n.t('orders.children.orderDrawer.children.subtotal.value')} />:</Col>
                <Col>{getMoneyFormat(totalSum)}</Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
              >
                <Col><Translate value={I18n.t('orders.children.orderDrawer.children.delivery.value')} />:</Col>
                <Col>
                  {
                    getMoneyFormat(orderInfo && orderInfo.deliveryPrice ?
                      Number(orderInfo.deliveryPrice.price) : 0)
                  }
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
              >
                <Col><b><Translate value={I18n.t('orders.children.orderDrawer.children.total.value')} /></b>:</Col>
                <Col>
                  <b>
                  {getMoneyFormat(totalSum + (orderInfo && orderInfo.deliveryPrice ?
                    Number(orderInfo.deliveryPrice.price) : 0))}
                  </b>
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  settings: selectors.getSettings(state),
});
export default compose(
  connect<{}, {}, any>(mapStateToProps),
)(OrderProducts);