import { 
  getAddButtonDefaults, 
  getUpdateButtonDefaults, 
  getDeleteButtonDefaults,  
  getRefreshButtonDefaults, 
  ControlModel
} from '../../../Controls/Controls';
import { ProductModel } from '../../../../models/ProductModel';

export interface ProductControlsProps {
  current?: ProductModel | null;
  openForm: (id?: number) => void;
  deleteProduct: (id: number) => void;
  refresh: () => void;
}

export const productControls = ({
  openForm, deleteProduct, current, refresh
}: ProductControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      onClick: () => openForm(),
    },
    {
      ...getUpdateButtonDefaults(),
      disabled: !current,
      onClick: () => current ? openForm(current.id) : null,
    },
    {
      ...getDeleteButtonDefaults(),
      disabled: !current,
      onClick: () => current ? deleteProduct(current.id) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};