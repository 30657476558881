import { SellPointModel } from '../models/SellPointModel';
import { PaymentTypeModel } from '../models/PaymentTypeModel';
import { DeliveryPriceModel } from '../models/DeliveryPriceModel';
import { DeliveryTypeModel } from '../models/DeliveryTypeModel';
import * as types from '../constants/dictionary';

export interface DictionaryState {
  deliveryTypes: DeliveryTypeModel[];
  deliveryPrices: DeliveryPriceModel[];
  paymentTypes: PaymentTypeModel[];
  sellPoints: SellPointModel[];
  districts: any[];
}

export const initialState: DictionaryState = {
  deliveryTypes: [],
  deliveryPrices: [],
  paymentTypes: [],
  sellPoints: [],
  districts: [],
};

export const dictionaryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_DELIVERY_PRICES:
      return { ...state, deliveryPrices: action.deliveryPrices };
    case types.SET_DELIVERY_TYPES: 
      return { ...state, deliveryTypes: action.deliveryTypes };
    case types.SET_PAYMENT_TYPES: 
      return { ...state, paymentTypes: action.paymentTypes };
    case types.SET_SELLPOINTS: 
      return { ...state, sellPoints: action.sellPoints };
    case types.SET_DISTRICTS:
      return { ...state, sellPoints: action.districts };
    default: 
      return state;
  }
};
