import React, { FormEvent, useEffect, useState } from 'react';
import { Form, Input, Spin, Select, Modal } from 'antd';
import '../../../styles/modal-form.css';
import { RoleModel } from '../../../models/RoleModel';
import { UserModel } from '../../../models/UserModel';
import { FormComponentProps } from 'antd/lib/form';
import UsersService from '../../../services/UsersService';
import { getErrorNotification } from '../../Notifications/Notifications';
import { SellPointModel } from '../../../models/SellPointModel';
import { MaskedInput } from 'antd-mask-input';
import DictionaryService from '../../../services/DictionaryService';
import { selectFilterOption } from '../../../helpers/selectFilterOption';
import { formLayoutDefaults, getDefaultModalProps } from '../../../constants/ModalFormConstants';
import { I18n } from 'react-redux-i18n';

const usersService = new UsersService();
const dictionaryService = new DictionaryService();
interface Props extends FormComponentProps {
  submitUser: (values: UserModel) => Promise<void>;
  closeForm: () => void;
  getUser?: (userId: number) => Promise<UserModel>;
  userId?: number;
}

const UserForm = (props: Props) => {
  const { getFieldDecorator, validateFields, isFieldTouched } = props.form;
  const { closeForm, submitUser, userId } = props;

  const [user, setUser] = useState({} as UserModel);
  const [roles, setRoles] = useState([] as RoleModel[]);
  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    getSellPoints();
  }, []);

  useEffect(() => {
    if (userId) {
      setFetchingData(true);
      usersService.getUser(userId)
        .then((user: UserModel) => {
          setUser(user);
        })
        .catch((e) => {
          console.error('ERROR: ', e);
          getErrorNotification(e);
        })
        .finally(() => {
          setFetchingData(false);
        });
    }

    usersService.getRoles()
      .then((value) => {
        setRoles(value);
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });

    dictionaryService.getSellPoints()
      .then((value) => {
        setSellPoints(value);
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  }, [userId]);

  const getSellPoints = () => {
    dictionaryService.getSellPoints()
      .then((res: any) => {
        setSellPoints(res);
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    validateFields((err: Error, values: any) => {
      if (!err) {
        const userDTO = {} as any;
        if (userId) {
          values.id = user.id;
        }
        for (let prop in values) {
          if (values.hasOwnProperty(prop)) {
            userDTO[prop] = values[prop] === '' ? null : values[prop];
          }
        }
        setFetchingData(true);
        submitUser({...values, phone: values.phone.replace(/\D/g, '')})
          .finally(() => setFetchingData(false));
      }
    });
  };

  const phonePattern = user.id && !isFieldTouched('phone') ? /^\d{12}$/ : /^\+38\(0\d{2}\)\s\d{3}(\s\d{2}){2}$/;

  return (
    <Modal
      {...getDefaultModalProps(I18n)}
      title={userId ? I18n.t('users.children.editUser.value') : I18n.t('users.children.createUser.value')}
      onCancel={closeForm}
      onOk={handleSubmit}
    >
      <Spin spinning={fetchingData}>
        <Form onSubmit={handleSubmit} {...formLayoutDefaults} className="modal-form">
          <Form.Item label={I18n.t('users.children.login.value')}>
            {getFieldDecorator('login', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: user.login,
            })(
              <Input disabled={!!user.id} />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('users.children.name.value')}>
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: user.firstName,
            })(
              <Input />,
            )}
          </Form.Item>
          <Form.Item label={I18n.t('users.children.surname.value')}>
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: user.lastName,
            })(
              <Input />,
            )}
          </Form.Item>
          <Form.Item label={I18n.t('users.children.salePoint.value')}>
            {getFieldDecorator('sellPoint', {
              initialValue: user.sellPoint ? user.sellPoint.id : null,
            })(
              <Select allowClear={true}>
                {sellPoints.map((sellPoint: SellPointModel) =>
                  <Select.Option
                    value={sellPoint.id}
                    key={sellPoint.id}>
                    {sellPoint.name}
                  </Select.Option>,
                )}
              </Select>,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('users.children.phone.value')}>
            {getFieldDecorator('phone', {
              initialValue: user && user.phone,
              rules: [
                { required: true, message: I18n.t('form.children.requiredField.value') },
                { pattern: phonePattern, message: 'Неправильно введений номер!' },
              ],
            })(
              <MaskedInput mask="+38(011) 111 11 11" />,
            )}
          </Form.Item>
          <Form.Item  label="Email">
            {getFieldDecorator('email', {
              rules: [{ type: 'email', message: 'Некоректний email!' }],
              initialValue: user.email,
            })(
              <Input />,
            )}
          </Form.Item>
          <Form.Item label={I18n.t('users.children.userRoles.value')} >
            {getFieldDecorator('roles', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: (user.roles ? user.roles.map((role: RoleModel) => role.name) : []),
            })(
              <Select mode="multiple" filterOption={selectFilterOption}>
                {
                  roles.map((role: RoleModel) => {
                    return (
                      <Select.Option key={role.name}>{role.description}</Select.Option>
                    );
                  })
                }
              </Select>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Form.create<Props>({ name: 'UserForm' })(UserForm);