import React from 'react';
import css from './ImageCell.module.css';
import { getImageURL } from '../../helpers/getImageURL';

interface IProps {
  image: {
    uuid: string;
  };
  id: number;
  handleClick: (id: number) => void;
}

const ImageCell = (props: IProps) => {
  const { image, id } = props;

  const handleClick = () => {
    props.handleClick(id);
  };

  return (
    <div className={css.container} onClick={handleClick} id={`image-cell-${id}`}>
      <img src={getImageURL(image.uuid)} className={css.image} alt={getImageURL(image.uuid)} />
    </div>
  );
};

export default ImageCell;