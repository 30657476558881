import {combineReducers} from 'redux';
import {ClientModel} from '../../models/ClientModel';
import {TypeModal} from '../../constants/typeModal';
import {
  ADD_ADDRESS,
  ADD_CONTACT,
  DELETE_ADDRESS,
  DELETE_CONTACT,
  INIT_CLIENT,
  SELECT_EDIT_ADDRESS,
  SELECT_EDIT_CONTACT,
  SET_CLIENT,
  SET_CLIENT_ERROR,
  SET_CLIENT_LOADING,
  SET_TYPE_MODAL,
  UPDATE_ADDRESS,
  UPDATE_CONTACT
} from './clientActions';

export interface IStateClient {
  client: {
    data: ClientModel;
    isLoading: boolean;
    error: any;
  };
  typeModal: {
    type: TypeModal;
    id: null;
  };
  editContact: any;
  editAddress: any;
}

const init: IStateClient = {
  client: {
    data: {
      id: null as any,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      isPhoneCustom: false,
      certificates: [] as any[],
      contacts: [] as any[],
      creditCards: [] as any[],
      addresses: [] as any[],
    },
    isLoading: false,
    error: null
  },
  typeModal: {
    type: TypeModal.None,
    id: null,
  },
  editAddress: null,
  editContact: null,
};

const clientReducer = (state = init.client, {type, payload}: any) => {
  switch (type) {
    case SET_CLIENT:
      return {...state, data: payload.client};
    case SET_CLIENT_LOADING:
      return {...state, isLoading: payload.isLoading};
    case SET_CLIENT_ERROR:
      return {...state, error: payload.error};
    case ADD_ADDRESS: {
      const addresses = [...state.data.addresses];
      addresses.push(payload.address);
      const data = {...state.data, addresses};
      return {...state, data};
    }
    case UPDATE_ADDRESS: {
      const addresses = [...state.data.addresses];
      addresses.forEach((a: any, index: any) => {
        if (index === payload.id) {
          addresses[index] = {...payload.address};
          return;
        }
      });
      const data = {...state.data, addresses};
      return {...state, data};
    }
    case DELETE_ADDRESS: {
      const addresses = state.data.addresses.filter((a: any, index: any) => index !== payload.id);
      const data = {...state.data, addresses};
      return {...state, data};
    }
    case ADD_CONTACT: {
      const contacts = [...state.data.contacts];
      contacts.push(payload.contact);
      const data = {...state.data, contacts};
      return {...state, data};
    }
    case UPDATE_CONTACT: {
      const contacts = [...state.data.contacts];
      contacts.forEach((a: any, index) => {
        if (index === payload.id) {
          contacts[index] = {...payload.contact};
          return;
        }
      });
      const data = {...state.data, contacts};
      return {...state, data};
    }
    case DELETE_CONTACT: {
      const contacts = state.data.contacts.filter((a: any, index) => index !== payload.id);
      const data = {...state.data, contacts};
      return {...state, data};
    }
    case INIT_CLIENT:
      return init.client;
    default:
      return state;
  }
};

const typeReducer = (state = init.typeModal, {type, payload}: any) => {
  switch (type) {
    case SET_TYPE_MODAL:
      return { type: payload.type, id: payload.id };
    default:
      return state;
  }
};

const editAddressReducer = (state = init.editAddress, {type, payload}: any) => {
  switch (type) {
    case SELECT_EDIT_ADDRESS:
      return payload.id;
    case UPDATE_ADDRESS:
      return null;
    default:
      return state;
  }
};

const editContactReducer = (state = init.editContact, {type, payload}: any) => {
  switch (type) {
    case SELECT_EDIT_CONTACT:
      return payload.id;
    case UPDATE_CONTACT:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  client: clientReducer,
  typeModal: typeReducer,
  editAddress: editAddressReducer,
  editContact: editContactReducer,
});
