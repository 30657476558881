import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import genODataQuery from '../helpers/generateODataQuery';
import isExistObject from '../helpers/checkIsExistObject';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

const orderTypes: any = {
  ascend: 'asc',
  descend: 'desc',
};

interface CreateDeliveryPackageModel {
  startDate?: Date;
  endDate?: Date;
  sellPoint: number;
  orders: number[];
}

export interface IGetODataOptionModel {
  filter?: any;
  sort?: any;
  paging?: any;
}

class DeliveryPackagesService {
  private httpService: HttpService;
  private ODataService: ODataService;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
  }

  public setRefreshPage(deliveryDispatch: any, type: string) {
    deliveryDispatch({
        type,
        isRefresh: true,
    });
    setTimeout(() => {
        deliveryDispatch({
            type,
            isRefresh: false,
        });
    }, 300);
  }

  public getOrders() {
    const URL = `${BASE_URL}orders?$filter=deliveryPackage eq null and deliveryType/code eq 'courier'`;
    return this.httpService.get(URL);
  }

  public getCouriers(filter: any, sorter: any, pagination: any) {
    const URL = this.ODataService.composeQuery(`${BASE_URL}couriers`, filter, sorter, pagination);
    return this.httpService.get(URL);
  }

  public getFilteredCouriers(couriersQuery: any) {
    const query = generateODataQuery(couriersQuery);
    return this.httpService.get(`${BASE_URL}couriers${query}`);
  }

  public getPackages(options?: IGetODataOptionModel) {
    let count = true;
    let filter = null;
    let top = null;
    let skip = null;
    let orderBy = null;
    if (options) {
      const {paging, sort, } = options;
      if (isExistObject(paging)) {
        const {current, pageSize} = paging;
        top = pageSize;
        skip = (current - 1) * pageSize;
      }
      if (isExistObject(sort)) {
        const column = sort.columnKey.replace('.', '/');
        orderBy = sort.order ? [`${column} ${orderTypes[sort.order]}`] : null;
      }
    }
    const query = genODataQuery({filter, count, top, skip, orderBy});
    return this.httpService.get(`${BASE_URL}couriers/packages${query}`);
  }

  public getPackagesByCourierId(id: string) {
    return this.httpService.get(`${BASE_URL}couriers/packages/${id}`);
  }

  public getOrdersByPackageId(id: number) {
    return this.httpService.get(`${BASE_URL}couriers/packages/${id}/orders`);
  }

  public createDeliveryPackage(courier: string, data: CreateDeliveryPackageModel) {
    const body = {
      startDate: data.startDate || null,
      endDate: data.endDate || null,
      sellPoint: { id: data.sellPoint },
      orders: data.orders && data.orders.length ? data.orders.map((item: any) => ({ id: item})) : null,
    };
    return this.httpService.post(`${BASE_URL}couriers/packages/${courier}`, JSON.stringify(body));
  }

  public addOrdersToDeliveryPackage(courierId: number, orders: number[]) {
    const body = {
      orders: orders.map((item: number) => ({ id: item })),
    };
    return this.httpService.put(`${BASE_URL}couriers/${courierId}/orders`, JSON.stringify(body));
  }

  public setActiveDeliveryPackage(packageId: number) {
    return this.httpService.put(`${BASE_URL}couriers/packages/${packageId}/status/active`, null);
  }

  public deleteDeliveryPackage(packageId: number) {
    return this.httpService.delete(`${BASE_URL}couriers/packages/${packageId}`);
  }

  public removeOrderFromPackage(orderId: number) {
    return this.httpService.put(`${BASE_URL}couriers/packages/remove_order/${orderId}`, null);
  }

  public setPackageAvalible(packageId: number) {
    return this.httpService.put(`${BASE_URL}couriers/packages/${packageId}/available`, null);
  }
}

export default DeliveryPackagesService;