/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Row, Card } from 'antd';
import ClientsService from '../../../services/ClientsService';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useDispatch } from 'react-redux';
import { initClient } from '../../../redux/client/clientActions';
import { clientsColumns } from './enums/ClientsColumns';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { clientsFilters, ClientsInitialState } from './enums/ClientsFilters';
import { RESET_FILTERS, SET_FILTERS, pageStateReducer, SET_SORTER, SET_PAGINATION } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { getRowClassName } from '../../../helpers/getRowClassName';
import TableTitle from '../../TableTitle/TableTitle';
import { clientControls } from './enums/ClientControls';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setClients as setClientsPage } from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';

const service = new ClientsService();

const Clients = (props: any) => {
  const [clients, setClients] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const dispatchClients = useDispatch();

  const { height: wHeight, width: wWidth } = useWindowDimensions();
  const { selected, handleRow: onRow, handleSelectionAfterUpdate} = useTableSelection();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);

  const { pages: { clients: clientsPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, clientsPage);
  const stateRef = useRef(state);

  useEffect(() => {
    if (state.needUpdate) {
      getClients(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setClientsPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setClientsPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: ClientsInitialState.filters,
    });
  };

  // ----------------- TABLE HANDLING ----------------- //

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ----------------- USERS HANDLING ----------------- //

  function getClients(filters = state.filters, sorter = state.sorter, pagination = state.pagination) {
    setFetchingData(true);
    service.getClients(getQueryObjFromState(filters, sorter, pagination))
      .then((value) => {
        setClients(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }

  const deleteClient = (clientId: number) => {
    setFetchingData(true);
    service.deleteClient(clientId)
      .then(() => {
        getClients();
        getSuccessNotification('Клієнта видалено');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
        setFetchingData(false);
      });
  };

  // ----------------- MODALS HANDLING ----------------- //

  const createNewClient = () => {
    dispatchClients(initClient());
  };

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
              <FiltersPanel
                filters={clientsFilters({ inits: { ...getFilterInitsFromPageState(clientsPage.filters) }})}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle controls={
            clientControls({
              deleteClient, 
              createNewClient, 
              current: selected, 
              currentURL: props.match.url, 
              refresh: getClients 
            })
          }/>
        )}
        className="main-table"
        onChange={handleTableChange}
        onRow={onRow}
        bordered
        dataSource={clients}
        columns={clientsColumns({ sorter: state.sorter })}
        rowClassName={getRowClassName(selected)}
        size="middle"
        rowKey="id"
        pagination={state.pagination}
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        tableLayout={'fixed'}
        scroll={{ y: wHeight - 100 - topPanelHeight - 46 - 32 - 42, x: wWidth - 300 }}
      />
    </div>
  );
};

export default Clients;
