import React from 'react';
import { Icon } from 'antd';

interface PointIconProps {
  destination?: boolean;
}

const PointIcon = ({ destination }: PointIconProps) => (
  <Icon type="environment" theme="twoTone" twoToneColor={destination ? '#52c41a' : ''}/>
);

export default PointIcon;