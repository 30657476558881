import { RouterListModel } from '../../../models/RouterListModel';
import Users from '../../pages/Users/Users';
import Roles from '../../pages/Roles/Roles';
import Orders from '../../pages/Orders/Orders';
import OrderForm from '../../pages/Orders/OrderForm';
import Categories from '../../pages/Categories/Categories';
import DeliveryPackages from '../../pages/DeliveryPackages/DeliveryPackages';
import Clients from '../../pages/Clients/Clients';
import ClientForm from '../../pages/Clients/ClientForm';
import Couriers from '../../pages/Couriers/Couriers';
import Products from '../../pages/Products/Products';

const RouteList: RouterListModel[] = [
    {
        path: '/users',
        code: 'users',
        component: Users,
        icon: 'user'
    },
    {
        path: '/roles',
        code: 'roles',
        component: Roles,
        icon: 'usergroup-add'
    },
    {
        path: '/orders',
        code: 'orders',
        component: Orders,
        icon: 'unordered-list',
        routes: [
            {
                path: '/orders/new',
                code: 'ordersCreate',
                component: OrderForm
            },
            {
                path: '/orders/:id',
                code: 'ordersUpdate',
                component: OrderForm
            },
        ]
    },
    {
        path: '/products',
        code: 'products',
        component: Products,
        icon: 'products'
    },
    {
        path: '/categories',
        code: 'categories',
        component: Categories,
        icon: 'database'
    },
    {
        path: '/clients',
        code: 'clients',
        component: Clients,
        icon: 'smile',
        routes: [
            {
                path: '/clients/new',
                code: 'clientsCreate',
                component: ClientForm
            },
            {
                path: '/clients/:id',
                code: 'clientsUpdate',
                component: ClientForm
            },
        ]
    },
    {
        path: '/deliveries',
        code: 'deliveries',
        component: DeliveryPackages,
        icon: 'car'
    },
    {
        path: '/couriers',
        code: 'couriers',
        component: Couriers,
        icon: 'courier'
    },
];

export default RouteList;