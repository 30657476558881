import React from 'react';
import {
  CONTROLS,
  getAddButtonDefaults,
  getUpdateButtonDefaults,
  getRefreshButtonDefaults,
  getApproveButtonDefaults,
  ControlModel } from '../../../Controls/Controls';
import { Icon } from 'antd';
import { OrderModel } from '../../../../models/OrderModel';
import { I18n } from 'react-redux-i18n';

export interface OrderControlsProps {
  current?: OrderModel | null;
  currentURL: string;
  deleteOrder: (id: number) => void;
  approveOrder: (id: number) => void;
  openCreateOrder: () => void;
  refresh: () => void;
}

export const orderControls = ({
  currentURL, openCreateOrder, deleteOrder, approveOrder, current, refresh
}: OrderControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      onClick: () => openCreateOrder(),
    },
    {
      ...getApproveButtonDefaults(),
      type: CONTROLS.BUTTON,
      disabled:
        (current && current.orderStatus && current.orderStatus.code !== 'new') ||
        !current,
      onClick: () => current ? approveOrder(current.id) : null,
    },
    {
      ...getUpdateButtonDefaults(),
      type: current ? CONTROLS.LINK : CONTROLS.BUTTON,
      disabled: !current,
      url: current ? `${currentURL}/${current.id}` : '',
    },
    {
      type: CONTROLS.BUTTON,
      buttonType: 'link',
      icon: <Icon type={'stop'} />,
      disabled: !current || !!current.deletedDate,
      tooltip: {
        overlay: I18n.t('controls.children.deactivate.value'),
        arrowPointAtCenter: true,
        placement: 'topRight',
      },
      onClick: () => current ? deleteOrder(current.id) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};