import React from 'react';
import StatusSelect from '../../../StatusSelect/StatusSelect';
import CourierMoneyCell from '../components/CourierMoneyCell';
import { FormTypes } from './FormTypes';
import { CourierModel } from '../../../../models/CourierModel';
import { I18n } from 'react-redux-i18n';

interface CouriersColumnsProps {
  updateCourierWorkStatus: (courierId: number, isWork: boolean) => (status: number | boolean) => void;
  updateCourierMoney: (courierId: number) => (sum: number | undefined) => Promise<any> | undefined;
  openForm: (id?: number, type?: FormTypes, courier?: CourierModel) => void;
  sort: {
    field: string,
    order: 'ascend' | 'descend',
  };
}

export const couriersColumns = ({
  updateCourierWorkStatus,
  openForm,
  updateCourierMoney,
  sort,
}: CouriersColumnsProps) => {
  return [
    {
      title: I18n.t('couriers.children.phone.value'),
      dataIndex: 'phone',
      width: 200,
      render: (text: any, record: any) => record.phone || null,
    },
    {
      title: I18n.t('couriers.children.firstName.value'),
      dataIndex: 'firstName',
      width: 250,
      sorter: true,
      sortOrder: sort.field === 'firstName' ? sort.order : undefined,
    },
    {
      title: I18n.t('couriers.children.surname.value'),
      dataIndex: 'lastName',
      width: 250,
      sorter: true,
      sortOrder: sort.field === 'lastName' ? sort.order : undefined,
    },
    {
      title: I18n.t('couriers.children.courierStatus.value'),
      dataIndex: 'isActive',
      sortIndex: 'isActive',
      sorter: true,
      sortOrder: sort.field === 'isActive' ? sort.order : undefined,
      width: 120,
      render: (text: string, record: any) => {
        return (
          <StatusSelect
            statusValue={Number(record.isWork)}
            keyField="id"
            showField="status"
            statuses={[
              { 
                id: 0, 
                status: I18n.t('couriers.children.courierStatus.children.atWork.value'),
              }, 
              { 
                id: 1, 
                status: I18n.t('couriers.children.courierStatus.children.absent.value'),
              }
            ]}
            setStatus={updateCourierWorkStatus(record.id, record.isWork)}
            statusColors={{
              '#FF2410': [0],
            }}
          />
        );
      }
    },
    {
      title: I18n.t('couriers.children.cash.value'),
      dataIndex: 'cash',
      width: 120,
      sorter: true,
      sortOrder: sort.field === 'cash' ? sort.order : undefined,
      render: (money: string, record: any) => (
        <CourierMoneyCell
          money={Number(money)}
          showCashHistory={() => openForm(record.id, FormTypes.CASH_HISTORY)}
          updateCourierMoney={updateCourierMoney(record.id)}
        />
      ),
    },
    {
      title: I18n.t('couriers.children.salePoint.value'),
      dataIndex: 'sellPoint.name',
      sortIndex: 'sellPoint/name',
      width: 200,
      sorter: true,
      sortOrder: sort.field === 'cash' ? sort.order : undefined,
    },
  ];
};