import React, { FormEvent, ChangeEvent } from 'react';
import { Form, Input, Row, Select, Button, Col, Icon, DatePicker, Checkbox, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import css from './FiltersPanel.module.scss';
import { FilterTypes } from '../../constants/filterTypes';
import { selectFilterOption } from '../../helpers/selectFilterOption';

import moment from 'moment';

import { Translate } from 'react-redux-i18n';

export interface FilterModel {
  type: FilterTypes;
  filterIndex: string;
  initialValue?: any;
  values?: any[];
  keyField?: string;
  showField?: string;
  label?: string;
  separator?: string;
  component?: React.Component;
  [prop: string]: any;
}

export interface FiltersPanelProps extends FormComponentProps {
  filters: FilterModel[];
  onFiltersConfirm?: (filterValues: any) => void;
  onFiltersReset?: () => void;
}

const FiltersPanel = (props: FiltersPanelProps) => {
  const { filters, onFiltersConfirm, onFiltersReset, form } = props;
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = form;

  const validateAndConfirm = () => {
    validateFields((err: Error, values: any) => {
      if (!err) {
        if (onFiltersConfirm) {
          onFiltersConfirm(values);
        }
      }
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateAndConfirm();
  };

  const handleReset = (e: FormEvent) => {
    e.preventDefault();
    resetFields();
    if (onFiltersReset) {
      onFiltersReset();
    }
  };

  const onSearchChange = (filterIndex: string) => (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.type === 'click' && e.target.value === '') {
      setFieldsValue({ [filterIndex]: '' }, () => validateAndConfirm());
    }
  };

  const onSelectChange = (filterIndex: string) => (value: any) => {
    setFieldsValue({ [filterIndex]: value }, () => validateAndConfirm());
  };

  const onDateRangeChange = (filterIndex: string) => (dates: any[]) => {
    if (!dates.length) {
      setFieldsValue({ [filterIndex]: dates }, () => validateAndConfirm());
    }
  };

  const filterFields = filters.map((filter) => {
    const { filterIndex, filterLangKey, initialValue, label, keyField, showField, component, ...restFilterProps } = filter;
    const values = Array.isArray(filter.values) ? filter.values : [];
    const sField = filter.showField || 'name';
    const kField = filter.keyField || 'id';

    switch (filter.type) {
      case FilterTypes.SEARCH:
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue: initialValue || '',
            })(
              <Input.Search
                onSearch={validateAndConfirm}
                onChange={onSearchChange(filterIndex)}
                allowClear
                {...restFilterProps}
                className={`${css.input} ${filter.className || ''}`}
              />,
            )}
          </Form.Item>
        );
        case FilterTypes.INPUT:
          return (
            <Form.Item key={filterIndex} label={label || ''}>
              {getFieldDecorator(filterIndex, {
                initialValue: initialValue || '',
              })(
                <Input
                  onChange={onSearchChange(filterIndex)}
                  allowClear
                  {...restFilterProps}
                  className={`${css.input} ${filter.className || ''}`}
                />,
              )}
            </Form.Item>
          );
      case FilterTypes.SELECT:
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue,
            })(
              <Select
                onChange={onSelectChange(filterIndex)}
                allowClear
                showArrow
                suffixIcon={<Icon type="tag" />}
                {...restFilterProps}
                className={`${css.selectMultiple} ${filter.className || ''}`}
                filterOption={selectFilterOption}
              >
                {values.map((item) => (
                  <Select.Option key={item[kField]} value={item[kField]}>
                    {/*{item[sField]}*/}
                    {filterLangKey ? <Translate value={`${filterLangKey}.${item.code}.value`} /> : item[sField]}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        );
      case FilterTypes.SELECT_MULTIPLE:
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue: initialValue || [],
            })(
              <Select
                onChange={onSelectChange(filterIndex)}
                maxTagCount={1}
                allowClear
                showArrow
                suffixIcon={<Icon type="tags" />}
                {...restFilterProps}
                mode="multiple"
                className={`${css.selectMultiple} ${filter.className || ''}`}
                filterOption={selectFilterOption}
              >
                {values.map((item) => (
                  <Select.Option key={item[kField]} value={item[kField]}>
                    {filterLangKey ? <Translate value={`${filterLangKey}.${item.code}.value`} /> : item[sField]}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        );
      case FilterTypes.CHECKBOX:
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue: initialValue || [],
            })(
              <Checkbox.Group
                onChange={onSelectChange(filterIndex)}
                {...restFilterProps}
                className={`${css.selectMultiple} ${filter.className || ''}`}
              >
                {values.map((item) => (
                  <Checkbox key={item[kField]} value={item[kField]}>
                    {item[sField]}
                  </Checkbox>
                ))}
              </Checkbox.Group>,
            )}
          </Form.Item>
        );
      case FilterTypes.RADIO:
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue: initialValue || [],
            })(
              <Radio.Group
                // onChange={onSelectChange(filterIndex)}
                {...restFilterProps}
                className={`${css.selectMultiple} ${filter.className || ''}`}
              >
                {values.map((item) => (
                  <Radio key={item[kField]} value={item[kField]}>
                    {item[sField]}
                  </Radio>
                ))}
              </Radio.Group>,
            )}
          </Form.Item>
        );
      case FilterTypes.DATE_RANGE:
        const val = getFieldValue(filterIndex);
        const separator = val && val.length ? '-' : '';
        return (
          <Form.Item key={filterIndex} label={label || ''}>
            {getFieldDecorator(filterIndex, {
              initialValue: initialValue || [],
            })(
              <DatePicker.RangePicker
                onChange={onDateRangeChange(filterIndex)}
                onOk={validateAndConfirm}
                separator={separator}
                showTime={{
                  defaultValue: [moment().hour(0).minute(0).second(0), moment().hour(23).minute(59).second(59)],
                }}
                {...restFilterProps}
              />
            )}
          </Form.Item>
        );
      default:
        return null;
    }
  });

  return (
    <Form layout="inline" onSubmit={handleSubmit} onReset={handleReset} className={css.filtersForm}>
      <Row type="flex" justify="space-between" align="middle">
        <Col lg={19} xl={21}>
          <Row type="flex" justify="start" align="middle" className={css.filedsContainer}>
            {filterFields}
          </Row>
        </Col>
        <Col lg={5} xl={3} className={css.buttonsContainer}>
          <Button type="primary" htmlType="submit" className={css.button}>
            <Translate value={'form.children.search.value'} />
          </Button>
          <Button type="default" htmlType="reset" className={css.button}>
            <Translate value={'form.children.clear.value'} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<FiltersPanelProps>({ name: 'filters_panel' })(FiltersPanel);
