import { PageStateModel } from './../reducers/pageStateReducer';

interface FilterInitsObj {
  [x: string]: any;
}

export const getFilterInitsFromPageState = (pageState: PageStateModel['filters']) => {
  const obj = {} as FilterInitsObj;

  for (let prop in pageState) {
    if (pageState.hasOwnProperty(prop)) {
      obj[prop] = pageState[prop].value;
    }
  }

  return obj;
};