import React, { useEffect } from 'react';
import LoginForm from './LoginForm';
import { compose } from 'redux';
import { IStore } from '../../../redux/store';
import * as selectors from '../../../redux/session/sessionSelectors';
import { connect } from 'react-redux';

const LoginFormPage = (props: any) => {
  const { isChangePassword, history, authenticated } = props;

  useEffect(() => {
    if (isChangePassword) {
      history.push('/chg-pass');
    }

    if (authenticated) {
      history.push('/');
    }
  }, [isChangePassword, authenticated, history]);

  return (
    <>
        {
          // @ts-ignore
          <LoginForm /> 
        }
    </>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  isChangePassword: selectors.getChangePassword(state),
  authenticated: selectors.getIsAuthenticated(state),
});
export default compose(
  connect<{}, {}, any>(mapStateToProps),
)(LoginFormPage);
