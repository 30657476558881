import { I18n } from 'react-redux-i18n';
import { PageStateModel } from './../../../../reducers/pageStateReducer';
import { FilterTypes } from './../../../../constants/filterTypes';

interface RolesFiltersArgs {
  inits?: {
    [name: string]: any;
  };
}

export const rolesFilters = ({ inits }: RolesFiltersArgs) => ([
  {
    filterIndex: 'name',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.name.value'),
    initialValue: (inits && inits.name) || '',
  },
  {
    filterIndex: 'description',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.description.value'),
    initialValue: (inits && inits.description) || '',
  },
]);

export const RolesInitialState: PageStateModel = {
  filters: {
    name: {
      type: FilterTypes.SEARCH,
      value: ''
    },
    description: {
      type: FilterTypes.SEARCH,
      value: ''
    },
  },
  sorter: {},
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};