// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_header__3p96H {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 70px;\n  z-index: 9;\n  box-sizing: border-box;\n  padding-left: 24px;\n  padding-right: 16px;\n  border-bottom: 2px solid #c9c9c9;\n  background-color: transparent;\n  /* -webkit-box-shadow: 0 2px 5px -2px rgba(0,0,0,0.45);\n  -moz-box-shadow: 0 2px 5px -2px rgba(0,0,0,0.45);\n  box-shadow: 0 2px 5px -2px rgba(0,0,0,0.45); */\n}\n\n.Header_logo__1jNFT {\n  width: 160px;\n}\n\n.Header_userOptions__17jQT {\n  position: relative;\n}\n\n.Header_userIcon__3yHuQ {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.Header_dropdownOptions__1wMgG {\n  border: 1px solid #2E2C76;\n}", ""]);
// Exports
exports.locals = {
	"header": "Header_header__3p96H",
	"logo": "Header_logo__1jNFT",
	"userOptions": "Header_userOptions__17jQT",
	"userIcon": "Header_userIcon__3yHuQ",
	"dropdownOptions": "Header_dropdownOptions__1wMgG"
};
module.exports = exports;
