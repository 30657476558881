import React, { FormEvent, useState, useEffect } from 'react';
import {
  Icon,
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Card,
} from 'antd';
import './LoginForm.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { login } from '../../../redux/session/sessionOperations';
import { loginError } from '../../../redux/session/actions';
import { IStore } from '../../../redux/store';
import * as selectors from '../../../redux/session/sessionSelectors';

import logo from '../../../assets/images/logo-dark.svg';
import { getErrorNotification } from '../../Notifications/Notifications';
import { I18n } from 'react-redux-i18n';

interface IState {
  isShowNotif: boolean;
  username?: string;
  password?: string;
}

const LoginForm = (props: any) => {
  const { error, loginError, login } = props;
  const { getFieldDecorator } = props.form;

  const [username] = useState(localStorage.getItem('EGS-defaultUsername'));
  const [password] = useState(localStorage.getItem('EGS-defaultPassword'));

  useEffect(() => {
    if (error) {
      getErrorNotification(error);
    }
  }, [error, loginError]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        login(values);
      }
    });
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: '100vh', background: '#f0f2f5' }}
    >
      <Col>
        <Card style={{ width: 330 }} title={<img src={logo} alt="Logo" style={{width: '50%'}} />}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('username', {
                initialValue: username || '',
                rules: [
                  { required: true, message: I18n.t('form.children.requiredField.value') },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                initialValue: password || '',
                rules: [
                  { required: true, message: I18n.t('form.children.requiredField.value') },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="password"
                  placeholder="Password"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)}
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                id="submit-login-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const mapDispatchToProps: any = {
  login,
  loginError,
};
const mapStateToProps: any = (state: IStore) => ({
  error: selectors.getError(state),
});

export default compose(
  Form.create({ name: 'login-form' }),
  connect<{}, {}, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LoginForm);
