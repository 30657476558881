import React, {createContext, useReducer} from 'react';
import {initialState, deliveryReducer } from '../reducers/deliveryReducer';

export const DeliveryContext = createContext(({} as any));

const DeliveryContextProvider: React.FC = (props: any) => {
    const [delivery, deliveryDispatch] = useReducer(deliveryReducer, initialState);

    return (
        <DeliveryContext.Provider value={{ delivery, deliveryDispatch }}>
            {props.children}
        </DeliveryContext.Provider>
    );
};

export default DeliveryContextProvider;