import React from 'react';
import { Input, Icon } from 'antd';
import TextFilter from '../Filters/TextFilter';

export const getColumnSearchProps = (
  dataIndex: string, handleSearch: any, handleReset: any, title: string, type?: string) => {
  const  searchInput: React.RefObject<Input> = React.createRef();
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
      return (
        <TextFilter 
          title={title}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          dataIndex={dataIndex}
          handleSearch={handleSearch}
          handleReset={handleReset}
          inputRef={searchInput}
          type={type}
        />
      );
    },
    filterIcon: (filtered: boolean) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.select();
          }
        });
      }
    },
  };
};