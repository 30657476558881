import { I18n } from "react-redux-i18n";

export const getDefaultModalProps = (i18n: I18n) => {
  let saveTxt = i18n.t('form.children.btns.children.save.value');
  saveTxt = (saveTxt && saveTxt !== 'value') ? saveTxt : 'Save';
  let cancelTxt = i18n.t('form.children.btns.children.cancel.value');
  cancelTxt = (cancelTxt && cancelTxt !== 'value') ? cancelTxt : 'Cancel';
  return {
    maskClosable: false,
    visible: true,
    destroyOnClose: true,
    okText: saveTxt,
    cancelText: cancelTxt ,
    closable: false,
    centered: true,
    bodyStyle: {
      maxHeight: '75vh',
      overflowY: 'auto' as 'auto',
    },
  }
}

export const defaultModalProps = {
  maskClosable: false,
  visible: true,
  destroyOnClose: true,
  okText: 'Зберегти',
  cancelText: 'Відмінити',
  closable: false,
  centered: true,
  bodyStyle: {
    maxHeight: '75vh',
    overflowY: 'auto' as 'auto',
  },
};

export const formLayoutDefaults = {
  wrapperCol: { 
    span: 18 
  },
  labelCol: { 
    span: 6
  },
};