import React from 'react';
import {DragSource} from 'react-dnd';
import {Card} from 'antd';

const CardGrid: any = Card.Grid;

const dragSource = DragSource;

const fieldSource = {
  beginDrag(props: any) {
    let dragFields;
    if (props.selectedFields.find((field: any) => field === props.item)) {
      dragFields = props.selectedFields;
    } else {
      dragFields = [...props.selectedFields, props.item];
    }
    return { fields: dragFields, count: dragFields.length };
  },
  endDrag(props: any, monitor: any) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDropped(item.fields, dropResult);
    }
  },
};

const collect = (connect: any, monitor: any) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const MultipleDragElement = (props: any) => {
  return props.connectDragSource(
    <div style={{ ...props.style, cursor: 'move', width: '100%' }}>
      <CardGrid 
        style={{
            width: '100%',
            background: props.isActive ? '#e4ffe1' : '',
        }}
        onClick={() => props.onClick(props.item)}>
          {props.children}
      </CardGrid>
    </div>);
};

export default dragSource('box', fieldSource, collect)(MultipleDragElement);