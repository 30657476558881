import React, {createContext, useReducer} from 'react';
import {initialState, reducer} from '../reducers/createOrderReducer';

export const CreateOrderContext = createContext(({} as any));

const CreateOrderContextProvider: React.FC = (props: any) => {
    const [createOrder, createOrderDispatch] = useReducer(reducer, initialState);

    return (
        <CreateOrderContext.Provider value={{ createOrder, createOrderDispatch }}>
            {props.children}
        </CreateOrderContext.Provider>
    );
};

export default CreateOrderContextProvider;