import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Row, Card, } from 'antd';
import CategoriesPageService from '../../../services/CategoriesPageService';
import { CategoryModel } from '../../../models/CategoryModel';
import ModalCarousel from '../../ModalCarousel/ModalCarousel';
import { getExternalId } from '../../../helpers/getExternalId';
import CategoryForm from './CategoryForm';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import { cateoriesColumns } from './enums/CategoriesColumns';
import { pageStateReducer, SET_FILTERS, RESET_FILTERS, SET_SORTER, SET_PAGINATION } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { categoriesFilters, categoriesInitialState as initialState } from './enums/CategoriesFilters';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import TableTitle from '../../TableTitle/TableTitle';
import { categoryControls } from './enums/CategoryControls';
import { getRowClassName } from '../../../helpers/getRowClassName';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setCategories as setCategoriesPage } from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';
import { setClientLoading } from '../../../redux/client/clientActions';

const service = new CategoriesPageService();

const Categories = () => {
  const [categories, setCategories] = useState([] as CategoryModel[]);
  const [formVisible, setFormVisible]: any = useState({
    visible: false,
    id: null,
  });
  const [carousel, setCarousel]: any = useState({
    visible: false,
    row: null,
  });
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate } = useTableSelection();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);
  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const { pages: { categories: categoriesPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, categoriesPage);
  const stateRef = useRef(state);

  useEffect(() => {
    if (state.needUpdate) {
      getCategories(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setCategoriesPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setCategoriesPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ------------- PROUDCTS HANDLING ------------- //

  const getCategories = (filters = state.filters, sorter = state.sorter, pagination = state.pagination) => {
    setFetchingData(true);
    service.getCategories(getQueryObjFromState(filters, sorter, pagination))
      .then((value) => {
        setCategories(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: { ...state.pagination, total: value.count },
          });
        }
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const updateCategory = (category: CategoryModel, images: FormData) => {
    const requests = [
      service.updateCategory(category.id, JSON.stringify(category)),
    ];

    if (images.getAll('file').length > 0) {
      requests.push(service.uploadCategoryImages(category.id, images));
    }

    return Promise.all(requests)
      .then((results) => {
        getCategories();
        getSuccessNotification('Категорія оновлена');
        closeForm();
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const createCategory = (category: CategoryModel, images: any) => {
    return service.getExternalIds()
      .then((value: any) => {
        category.externalId = getExternalId(value);
      })
      .then(() => service.createCategory(JSON.stringify(category)))
      .then((value) => {
        if (images.getAll('file').length > 0) {
          return service.uploadCategoryImages(value.id, images);
        }
      })
      .then(() => {
        getCategories();
        getSuccessNotification('Створено нову категорію');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  // ----------------- MODALS HANDLING ----------------- //

  const openForm = (id?: number) => {
    setFormVisible({
      visible: true,
      id: id ? id : null,
    });
  };

  const closeForm = () => {
    setFormVisible({
      visible: false,
      id: null,
    });
  };

  function openCarousel(row: number) {
    setCarousel({
      visible: true,
      row,
    });
  }

  function closeCarousel() {
    setCarousel({
      visible: false,
      row: null,
    });
  }

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
              <FiltersPanel
                filters={categoriesFilters({ inits: getFilterInitsFromPageState(categoriesPage.filters)})}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle controls={categoryControls({ openForm, current: selected, refresh: getCategories})} />
        )}
        className="main-table"
        onChange={handleTableChange}
        onRow={onRow}
        pagination={state.pagination}
        bordered
        dataSource={categories}
        columns={cateoriesColumns({ openCarousel, sort: state.sorter})}
        rowKey="id"
        rowClassName={getRowClassName(selected)}
        size="middle"
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 46 - 32 - 42, x: wWidth - 300 }}
      />
      {formVisible.visible &&
        <CategoryForm
          closeForm={closeForm}
          submitCategory={formVisible.id ? updateCategory : createCategory}
          categoryId={formVisible.id}
        />}
      {carousel.visible &&
        <ModalCarousel
          images={categories[carousel.row].categoryImages ?
            [categories[carousel.row].categoryImages] as Array<{ uuid: string }> : []
          }
          handleCarousel={closeCarousel}
        />
      }
    </div>
  );
};

export default Categories;