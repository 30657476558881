import React from 'react';
import {Button, Icon, InputNumber} from 'antd';
import './QuantityInput.css';
import { checkDigit } from '../../helpers/checkDigit';
import { Translate } from 'react-redux-i18n';

const QuantityInput = (props: any) => {
  const {value, handleQuantity, id, unit, minCount} = props;

  const handleChange = (value: number = 0) => {
    handleQuantity(value);
  };

  const increase = () => {
    handleQuantity(value + minCount);
  };

  const decrease = () => {
    handleQuantity(value - minCount);
  };

  // Use to work correct inside table when onRow is set
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  
  return (
    <div className="quantity-input">
      <span onDoubleClick={stopPropagation}>
        <Button
          id={`decrease-btn-${id}`}
          className="quantity-button"
          size="small"
          onClick={decrease}
          disabled={value <= minCount}
        >
          <Icon type="minus"/>
        </Button>
        <InputNumber
          size="small"
          min={1}
          max={9999999}
          value={value}
          step={minCount}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (!checkDigit(e.key)) {
              e.preventDefault();
            }
          }}
        />
        <Button className="quantity-button" size="small" onClick={increase} id={`increase-btn-${id}`}>
          <Icon type="plus"/>
        </Button>
      </span>
      <span className="quantity-type">
        <Translate value={`form.children.units.children.${unit.code}.value`}/>
      </span>
    </div>
  );
};

export default QuantityInput;
