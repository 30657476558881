import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { checkDigit } from '../../../../helpers/checkDigit';

interface MoneyInputProps {
  value?: string;
  maxValue?: number;
  minValue?: number;
  onChange?: (value: string) => void;
  [prop: string]: any;
}

const MoneyInput = (props: MoneyInputProps) => {
  const { maxValue, minValue, onChange, value, ...restProps } = props;
  const [moneyValue, setMoneyValue] = useState(props.value === undefined ? '' : props.value);

  useEffect(() => {
    setMoneyValue(props.value === undefined ? '' : props.value);
  }, [props.value]);

  const handleMoneyValue = (value: string) => {
    if (onChange) {
      onChange(value);
    } else {
      setMoneyValue(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (maxValue && (Number(value) > maxValue)) {
      return;
    } else if (minValue && (Number(value) < minValue)) {
      return;
    } 
    handleMoneyValue(value);
  };

  const handleMinus = () => {
    switch (moneyValue) {
      case '':
      case '0':
      case '-':
        handleMoneyValue('-');
        break;
      default:
        handleMoneyValue(`${-Number(moneyValue)}`);
    }
  };

  return (
    <Input
        value={moneyValue}
        onKeyDown={(e: any) => {
          if (!checkDigit(e.key)) {
            e.preventDefault();
          }
          if (e.key === '-') {
            handleMinus();
          }
        }}
        onChange={handleChange}
        {...restProps}
      />
  );
};

export default MoneyInput;