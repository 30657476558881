import React from 'react';
import { Form, Row, Col, Switch, Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { checkDigit } from '../helpers/checkDigit';
import { FormComponentProps } from 'antd/lib/form';

import { Translate, I18n } from 'react-redux-i18n';

interface  ValueModel {
  isPhoneCustom: boolean;
  phone: string;
}

export const initialValue: ValueModel = {
  isPhoneCustom: false,
  phone: '',
};

export const phoneValidator = (rule: any, value: ValueModel, callback: any) => {
  try {
    if (!value.phone) {
      throw new Error(I18n.t('form.children.requiredField.value'));
    }
    callback();
  } catch (err) {
    callback(err);
  }
};

const handleKeyDown = (e: React.KeyboardEvent) => {
  if (!e.ctrlKey && !checkDigit(e.key, true, [' ', '+'])) {
    e.preventDefault();
  }
};

export const PhoneInput = (props: any) => {
  const { value, onChange } = props;

  const triggerChange = (val: { isPhoneCustom?: boolean, phone?: string}) => {
    onChange({...value, ...val});
  };

  if (!value) {
    triggerChange({ phone: '', isPhoneCustom: false });
    return null;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerChange({phone: e.target.value});
  };

  const handleSwitch = (checked: boolean) => {
    triggerChange({ isPhoneCustom: checked, phone: value.phone.replace(/\D/g, '') });
  };

  const mainPhone = value.isPhoneCustom ?
    <Input
      value={value.phone || ''}
      onKeyDown={handleKeyDown}
      onChange={handleInputChange}
    /> :
    <MaskedInput
      value={value.phone || ''}
      mask="+38(011) 111 11 11" onChange={handleInputChange}
    />;

  return (
    <Row>
      <Row type="flex" justify="start" style={{ flexGrow: 1}}>
        <Col style={{
          color: '#c7c8d2', fontSize: '0.93em', fontStyle: 'italic',
          display: 'flex', alignItems: 'center', marginBottom: '-10px'
        }}>
          <span>
            <Translate value={`form.children.foreignPhone.value`} />:
          </span>
          <Switch
            size="small" style={{ margin: '0 0 0 8px ' }}
            onChange={handleSwitch}
            checked={!!value.isPhoneCustom} />
        </Col>
      </Row>
      {mainPhone}
    </Row>
  );
};

interface FormItemPhoneProps extends FormComponentProps {
  inits?: {
    isPhoneCustom: boolean;
    phone: string,
  };
  ownTextLabel?: boolean;
  phoneFieldName: string;
  isCustomFieldName: string;
  inputProps?: any;
  formItemProps?: any;
  switchProps?: any;
}

export const FormItemPhone = (props: FormItemPhoneProps) => {
  const { inits, form, phoneFieldName, isCustomFieldName,
    inputProps, formItemProps, switchProps, ownTextLabel = true } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const mainPhone = getFieldValue(isCustomFieldName) ?
    <Input onKeyDown={handleKeyDown} {...inputProps}/> :
    <MaskedInput  mask="+38(011) 111 11 11" {...inputProps} />;

  return (
    <Form.Item
      style={{ marginBottom: '0px', paddingBottom: '0px', flexGrow: 1}}
      {...formItemProps}
      className={`complexLabel${formItemProps && formItemProps.className ? ` ${formItemProps.className}` : ''}`}
      label={
        <Row type="flex" justify="start" style={{flexGrow: 1}}>
          {ownTextLabel && <Col style={{marginRight: '16px'}}><Translate value={`form.children.phone.value`} /></Col>}
          <Col style={{
            color: '#c7c8d2', fontSize: '0.93em', fontStyle: 'italic',
            display: 'flex', alignItems: 'center',
          }}>
            <span>
              <Translate value={`form.children.foreignPhone.value`} />:
            </span>
            {getFieldDecorator(isCustomFieldName, {
              valuePropName: 'checked',
              initialValue: inits ? inits.isPhoneCustom : false,
            })(
              <Switch size="small" style={{ margin: '0 0 0 8px' }} {...switchProps}/>
            )}
          </Col>
        </Row>
      }
    >
      {getFieldDecorator(phoneFieldName, {
        initialValue: inits ? inits.phone : '',
        rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
      })(
        mainPhone
      )}
    </Form.Item>
  );
};
