/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Card, Row, } from 'antd';
import { RoleModel } from '../../../models/RoleModel';
import RolesPageService from '../../../services/RolesService';
import RoleForm from './RoleForm';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import { pageStateReducer, SET_FILTERS, RESET_FILTERS, SET_SORTER, SET_PAGINATION } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { rolesColumns } from './enum/RolesCoumns';
import { RolesInitialState as initialState, rolesFilters } from './enum/RolesFilters';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { useTableSelection } from '../../../hooks/useTableSelection';
import TableTitle from '../../TableTitle/TableTitle';
import { rolesControls } from './enum/RolesControles';
import { getRowClassName } from '../../../helpers/getRowClassName';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setRoles as setRolesPage } from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';

const service = new RolesPageService();

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [formVisible, setFormVisible]: any = useState({
    visible: false,
    name: null,
  });
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate } = useTableSelection('name');
  const { height: wHeight, width: wWidth } = useWindowDimensions();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);
  
  const { pages: { roles: rolesPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, rolesPage);
  const stateRef = useRef(state);

  useEffect(() => {
    if (state.needUpdate) {
      getRoles(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setRolesPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setRolesPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({ 
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };
  // ----------------- TABLE HANDLING ----------------- //

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ------------- ROLES HANDLING ------------- //

  const getRoles = (filters = state.filters, sorter = state.sorter, pagination = state.pagination) => {
    setFetchingData(true);
    service.getRoles(getQueryObjFromState(filters, sorter, pagination))
      .then((value: any) => {
        setRoles(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => { 
        console.error('ERROR: ', e);
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  function updateRole(role: RoleModel) {
    return service.updateRole(role.name, JSON.stringify(role))
      .then(() => {
        getRoles();
        getSuccessNotification('Роль оновлена');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  }

  const createRole = (role: RoleModel) => {
    return service.createRole(JSON.stringify(role))
      .then(() => {
        getRoles();
        getSuccessNotification('Нова роль створена');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const deleteRole = (roleName: string) => {
    setFetchingData(true);
    service.deleteRole(roleName)
      .then(() => {
        getRoles();
        getSuccessNotification('Роль видалена');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
        setFetchingData(false);
      });
  };

  // ------------- MODALS HANDLING ------------- //

  const openForm = (name?: string) => {
    setFormVisible({
      visible: true,
      name: name ? name : null,
    });
  };

  const closeForm = () => {
    setFormVisible({
      visible: false,
      name: null,
    });
  };

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
              <FiltersPanel
                filters={rolesFilters({ inits: { ...getFilterInitsFromPageState(rolesPage.filters) }})}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle 
            controls={rolesControls({openForm, deleteRole, current: selected && selected.name, refresh: getRoles})}
          />
        )}
        className="main-table"
        onChange={handleTableChange}
        onRow={onRow}
        pagination={state.pagination}
        bordered
        dataSource={roles}
        columns={rolesColumns({sort: state.sorter})}
        rowClassName={getRowClassName(selected, 'name')}
        size="middle"
        rowKey="name"
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 46 - 32 - 42, x: wWidth - 300 }}
      />
      {formVisible.visible && 
        <RoleForm
          closeForm={closeForm}
          submitRole={formVisible.name ? updateRole : createRole}
          roles={roles}
          roleName={formVisible.name}
        />}
    </div>
  );
};

export default Roles;