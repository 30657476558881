import React from 'react';
import { Icon } from 'antd';

const ExpandIcon = (props: any) => {
  return (
    <Icon
      type={props.expanded ? 'up' : 'down'}
      onClick={(e) => props.onExpand(props.record, e)}
    />
  );
};

export default ExpandIcon;