import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Row, Card, } from 'antd';
import ProductsPageService from '../../../services/ProductsService';
import { ProductModel } from '../../../models/ProductModel';
import ModalCarousel from '../../ModalCarousel/ModalCarousel';
import { getExternalId } from '../../../helpers/getExternalId';
import ProductForm from './ProductForm';
import ExpandIcon from '../../icons/ExpandIcon';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { SET_SORTER, SET_PAGINATION, SET_FILTERS, RESET_FILTERS, pageStateReducer } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { additionalColumns, productsColumns } from './enums/ProductsColumns';
import { productsFilters, productsInitialState as initialState } from './enums/ProductsFilters';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { CategoryModel } from '../../../models/CategoryModel';
import CategoriesPageService from '../../../services/CategoriesPageService';
import { UnitModel } from '../../../models/UnitModel';
import DictionaryService from '../../../services/DictionaryService';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { getRowClassName } from '../../../helpers/getRowClassName';
import TableTitle from '../../TableTitle/TableTitle';
import { productControls } from './enums/ProductControls';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setProducts as setProductsPage} from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';

const service = new ProductsPageService();
const categoryService = new CategoriesPageService();
const dictionaryService = new DictionaryService();

const Products = (props: any) => {
  const [products, setProducts] = useState([] as ProductModel[]);
  const [formVisible, setFormVisible]: any = useState({
    visible: false,
    id: null,
  });
  const [carousel, setCarousel]: any = useState({
    visible: false,
    row: null,
  });
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate } = useTableSelection();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);
  const {height: wHeight, width: wWidth} = useWindowDimensions();

  const [categories, setCategories] = useState([] as CategoryModel[]);
  const [units, setUnits] = useState([] as UnitModel[]);
  useEffect(() => {
    categoryService.getActiveCategories()
      .then((categories) => setCategories(categories))
      .catch((e) => getErrorNotification(e));

    dictionaryService.getUnits()
      .then((units) => setUnits(units))
      .catch((e) => getErrorNotification(e));
  }, []);

  const { pages: { products: productsPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, productsPage);
  const stateRef = useRef(state);

  useEffect(() => {
    if (state.needUpdate) {
      getProducts(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setProductsPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setProductsPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ------------- PROUDCTS HANDLING ------------- //

  function getProducts(filters = state.filters, sorter = state.sorter, pagination = state.pagination) {
    setFetchingData(true);
    service.getProducts(getQueryObjFromState(filters, sorter, pagination))
      .then((value) => {
        setProducts(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }

  const updateProduct = (product: ProductModel, images: FormData, imagesToDelete: string[]) => {
    const requests = [
      service.updateProduct(product.id, JSON.stringify(product)),
    ];

    if (images.getAll('files').length > 0) {
      requests.push(service.uploadProductImages(product.id, images));
    }

    if (imagesToDelete.length > 0) {
      requests.push(service.deleteProductImages(JSON.stringify(imagesToDelete)));
    }

    return Promise.all(requests)
      .then((results) => {
        getProducts();
        getSuccessNotification('Продукт оновлено');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  const createProduct = (product: ProductModel, images: any) => {
    service.getExternalIds()
      .then((value: any) => {
        product.externalId = getExternalId(value);
      })
      .then(() => service.createProduct(JSON.stringify(product)))
      .then((value) => {
        if (images.getAll('files').length > 0) {
          return service.uploadProductImages(value.id, images);
        }
        closeForm();
      })
      .then((response) => {
        getProducts();
        getSuccessNotification('Новий продукт створено');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  };

  function deleteProduct(id: number) {
    setFetchingData(true);
    service.deleteProduct(id)
      .then(() => {
        getProducts();
        getSuccessNotification('Продукт видалено');
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
        setFetchingData(false);
      });
  }

  // ----------------- MODALS HANDLING ----------------- //

  const openForm = (id?: number) => {
    setFormVisible({
      visible: true,
      id: id || null,
    });
  };

  const closeForm = () => {
    setFormVisible({
      visible: false,
      id: null,
    });
  };

  function openCarousel(row: number) {
    setCarousel({
      visible: true,
      row,
    });
  }

  function closeCarousel() {
    setCarousel({
      visible: false,
      row: null,
    });
  }

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
              <FiltersPanel
                filters={productsFilters({categories, units, inits: {
                  ...getFilterInitsFromPageState(productsPage.filters),
                }})}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle controls={productControls({openForm, deleteProduct, current: selected, refresh: getProducts})}/>
        )}
        className="main-table"
        onChange={handleTableChange}
        onRow={onRow}
        tableLayout="fixed"
        pagination={state.pagination}
        bordered
        dataSource={products}
        columns={productsColumns({ openCarousel, sort: state.sorter })}
        rowKey="id"
        rowClassName={getRowClassName(selected)}
        size="middle"
        expandIcon={ExpandIcon}
        expandedRowRender={additionalColumns}
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 67 - 32 - 42,  x: wWidth - 300 }}
      />
      {formVisible.visible &&
        <ProductForm
          closeForm={closeForm}
          submitProduct={formVisible.id ? updateProduct : createProduct}
          productId={formVisible.id}
        />}
      {carousel.visible ?
        <ModalCarousel
          images={products[carousel.row].productImages}
          handleCarousel={closeCarousel}
        /> : null
      }
    </div>
  );
};

export default Products;
