import React from 'react';
import {
    Button,
    Icon,
} from 'antd';
import QuantityInput from '../../../QuantityInput/QuantityInput';
import { I18n } from 'react-redux-i18n';

const orderProductsColumns = ({
    handleProductQuantity,
    handleDelete,
    getCurrentPrice
}: any) => ([
    {
        title: I18n.t('orders.children.orderDrawer.children.title.value'), // Назва продукту
        dataIndex: 'title',
        width: '250px',
    },
    {
        title: I18n.t('orders.children.orderDrawer.children.amount.value'), // кількість
        dataIndex: 'count',
        width: '152px',
        className: 'quantity-column',
        render: (text: string, record: any, index: any) => {
            return (
                <QuantityInput
                    id={record.id}
                    value={record.count}
                    unit={record.unit}
                    minCount={record.minCount}
                    handleQuantity={handleProductQuantity(record)}
                />
            );
        },
    },
    {
        title: I18n.t('orders.children.orderDrawer.children.price.value'), // Ціна
        dataIndex: 'productOptions[0].price',
        width: '50px',
        className: 'centered',
        render: (text: string, record: any, index: any) => getCurrentPrice(record)
    },
    {
        title: I18n.t('orders.children.orderDrawer.children.cost.value'), // Вартість
        dataIndex: 'cost',
        width: '70px',
        className: 'centered',
        render: (text: string, record: any, index: any) => {
            return ((getCurrentPrice(record) / record.minCount * record.count) / (record.unit.id === 3 ?
                100 : 1)).toFixed(2);
        },
    },
    {
        title: I18n.t('orders.children.orderDrawer.children.actions.value'), // Дії
        dataIndex: 'actions',
        className: 'column-right',
        width: '66px',
        render: (text: string, record: any, index: any) => {
            return (
                <span>
                <Button
                    onClick={() => handleDelete(index)}
                    size="small"
                >
                    <Icon type="close-circle" />
                </Button>
                </span>
            );
        },
    },
]);

export {orderProductsColumns};
