import React from 'react';
import { connect } from 'react-redux';
import * as sessionSelectors from '../../redux/session/sessionSelectors';
import { Redirect } from 'react-router';

const mapStateToProps: any = (state: any) => ({
  authenticated: sessionSelectors.getIsAuthenticated(state),
});

export const withAuthRedirect = (Component: any) => {
  const RedirectComponent = (props: any) => {
    if (!props.authenticated) {
      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  return connect<{}, {}, any>(mapStateToProps)(RedirectComponent);
};
