import React, { createContext, useReducer } from 'react';
import { pagesReducer, initialState, LOCAL_STORAGE_PAGES } from '../reducers/pagesReducer';
import { connect } from 'react-redux';
import { getUser } from '../redux/session/sessionSelectors';
import { IStore } from '../redux/store';

export const PagesContext = createContext(({} as any));

const PagesContextProvider: React.FC = (props: any) => {
  let localStorageState: any;
  if (props.user) {
    localStorageState = localStorage.getItem(`${LOCAL_STORAGE_PAGES}/${props.user.id}`);
  }
  localStorageState = localStorageState && JSON.parse(localStorageState);
  const initState = localStorageState  || initialState;
  const [pages, pagesDispatch] = useReducer(pagesReducer, {...initState, userId: props.user ? props.user.id : null});

  return (
    <PagesContext.Provider value={{ pages, pagesDispatch }}>
      {props.children}
    </PagesContext.Provider>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
});

export default connect<{}, {}, any>(mapStateToProps)(PagesContextProvider);