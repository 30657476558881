import {
  loginError,
  loginRequest,
  loginSuccess,
  refreshUserError,
  refreshUserRequest,
  refreshUserSuccess,
  refreshSettingsError,
  refreshSettingsRequest,
  refreshSettingsSuccess,
  changePasswordRequest,
  changePasswordError,
  changePasswordSuccess,
  languagesRequest,
  languagesSuccess,
  languagesError,
  languageRequest,
  languageSuccess,
  languageError,
  setAppLanguageCode,
} from './actions';
import HttpService from '../../services/common/httpService';
import { getToken } from './sessionSelectors';

import { loadTranslations, setLocale } from 'react-redux-i18n';

interface ICredentials {
  username: string;
  password: string;
  remember?: boolean;
}

const baseTokenUrl = process.env.REACT_APP_AUTH_REQUEST;
const baseUrl = process.env.REACT_APP_BASE_REQUEST;

const httpService = new HttpService();

export const changePassword = (username: string, pass: string) => (
  dispatch: any,
) => {
  const body = {
    username,
    newPassword: pass,
    confirmNewPassword: pass,
  };

  httpService.post(`${baseUrl}auth/changePassword`, JSON.stringify(body))
    .then(() => {
      dispatch(changePasswordSuccess());
    })
    .catch((error) => {
      dispatch(changePasswordError(error));
    });
};

export const login = ({username, password, remember}: ICredentials) => (dispatch: any) => {
  dispatch(loginRequest());
  return httpService.post(`${baseTokenUrl}`, JSON.stringify({username, password}))
    .then((response) => {
      const { user, message, ...token } = response;
      if (message && message === 'CHANGE_PASSWORD') {
        dispatch(changePasswordRequest(username));
      } else {
        dispatch(loginSuccess({ user, token }));
        dispatch(getLanguages());
      }
    })
    .catch((error) => {
      const status = error.status;
      if (status === 400 || status === 423) {
        dispatch(loginError(error.message));
      } else {
        dispatch(loginError('Неправильне ім\'я користувача або пароль'));
      }
    });
};

export const refreshUser = () => (dispatch: any, getState: any) => {
  const token = getToken(getState());

  if (!token) {
    return;
  }

  dispatch(refreshUserRequest());

  httpService.get(`${baseUrl}auth/user/profile`)
    .then((respnose: any) => {
      dispatch(refreshUserSuccess(respnose));
    })
    .catch((error) => {
      dispatch(refreshUserError(error));
    });
};

export const getSessionSettings = () => (dispatch: any, getState: any) => {
  const token = getToken(getState());
  if (!token) {
    return;
  }

  dispatch(refreshSettingsRequest());

  httpService.get(`${baseUrl}setups/settings`)
    .then((respnose: any) => {
      dispatch(refreshSettingsSuccess(respnose));
    })
    .catch((error) => {
      dispatch(refreshSettingsError(error));
    });
};

export const getLanguages = () => (dispatch: any, getState: any) => {
  const token = getToken(getState());
  if (!token) {
    return;
  }
  dispatch(languagesRequest());
  httpService.get(`${baseUrl}i18n`)
      .then((response: any) => {
        dispatch(languagesSuccess(response));
      })
      .catch((error) => {
        dispatch(languagesError(error));
      });
};

export const getLanguage = (code: any | string = process.env.REACT_APP_DEFAULT_LANGUAGE) => (dispatch: any, getState: any) => {
  const token = getToken(getState());
  if (!token) {
    return;
  }
  dispatch(languageRequest());
  httpService.get(`${baseUrl}i18n/${code}`)
      .then((response: any) => {
        dispatch(languageSuccess(response));
        // update app language
        const translationsObject = {
          [code]: {
            ...response,
          }
        };
        try {
          dispatch(loadTranslations(translationsObject));
          dispatch(setLocale(code));
          dispatch(setAppLanguageCode(code));
        } catch (e) {
          console.log('getLanguage error: loadTranslations and setLocale are failed', e);
        }

      })
      .catch((error) => {
        dispatch(languageError(error));
      });
};
