/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import {
  List,
  Row,
  Col,
  Icon,
  Avatar,
  Modal,
  Button,
  Tooltip,
} from 'antd';
import MultipleDropElement from './MultipleDropElement';
import { getName, hashCode } from '../../../../helpers/tools';

import {
  SET_IS_SHOW_COURIER_CONFIRM,
  SET_IS_HIDE_COURIER_CONFIRM,
  SET_IS_SHOW_DRAWER,
  REFRESH,
  SET_IS_LOADING,
  SET_IS_HIDE_DRAWER,
} from '../../../../constants/delivery';
import DeliveryPackagesService from '../../../../services/DeliveryPackagesService';
import { getErrorNotification, getSuccessNotification } from '../../../Notifications/Notifications';
import CourierDeliveries from './CourierDeliveries';
import { getAddress } from '../../../../helpers/getAddress';
import { OrderModel } from '../../../../models/OrderModel';
import { DeliveryContext } from '../../../../contexts/DeliveryContextProvider';
import DraggableCourierOrder from './DraggableCourierOrder';
import { Translate, I18n } from 'react-redux-i18n';

const deliveryPackagesService = new DeliveryPackagesService();

interface CouriersListProps {
  dataSource: any;
  name: string;
  onSubmitOrders: any;
  onDropped: any;
}

const CouriersList: React.FC<CouriersListProps> = ({
  dataSource,
  name,
  onSubmitOrders,
  onDropped,
}) => {

  const { delivery, deliveryDispatch } = useContext(DeliveryContext);
  const {
    markedOrders,
    isShowCourierConfirm,
    data,
  } = delivery;
  
  const onDeletePackageOrder = (orderId: number) => {
    deliveryDispatch({
      type: SET_IS_LOADING,
      isLoading: true,
    });
    deliveryPackagesService.removeOrderFromPackage(orderId)
      .then((res: any) => {
        deliveryPackagesService.setRefreshPage(deliveryDispatch, REFRESH);
        getSuccessNotification(
          res.message || I18n.t('deliveries.children.couriersList.children.deleteOrderFromPackageSuccess.value')
        );
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const deletePackage = async (packageId: number) => {
    deliveryDispatch({
      type: SET_IS_LOADING,
      isLoading: true,
    });
    await deliveryPackagesService.deleteDeliveryPackage(packageId);
    deliveryDispatch({
      type: SET_IS_HIDE_DRAWER,
    });
    deliveryPackagesService.setRefreshPage(deliveryDispatch, REFRESH);
  };

  const setPackageAvalible = async (packageId: number) => {
    deliveryDispatch({
      type: SET_IS_LOADING,
      isLoading: true,
    });
    try {
      await deliveryPackagesService.setPackageAvalible(packageId);
      getSuccessNotification(
        I18n.t('deliveries.children.activatePackageSuccess.value')
      );
    } catch (e) {
      getErrorNotification(e);
    } finally {
      deliveryDispatch({
        type: SET_IS_HIDE_DRAWER,
      });
      deliveryPackagesService.setRefreshPage(deliveryDispatch, REFRESH);
    }
  };

  const onClickCourier = (courier: any) => {
    if (markedOrders && markedOrders.length > 0) {
      deliveryDispatch({
        type: SET_IS_SHOW_COURIER_CONFIRM,
        data: {
          name,
          currentCourier: courier.id,
          fields: markedOrders,
          drop: {
            data: courier,
            name,
          },
        },
      });
    } else {
      if (courier.id) {
        openCourierDeliveries(courier);
      }
    }
  };

  const setHideConfirm = () => {
    deliveryDispatch({
      type: SET_IS_HIDE_COURIER_CONFIRM,
    });
  };

  const openCourierDeliveries = (courier: any) => {
    if (courier) {
      deliveryDispatch({
        type: SET_IS_SHOW_DRAWER,
        drawer: {
          title: <Row type="flex" justify="space-between">
            <Col>
              <Translate value="deliveries.children.couriersList.children.courierOrdersList.value" /> №
              {courier.id}, {courier.firstName} {courier.lastName}
            </Col>
            <Col style={{paddingRight: '30px'}}>
              <Tooltip 
                title={I18n.t('deliveries.children.couriersList.children.clearOrdersList.value')}>
                <Button className="deliveries-drawer-btn" 
                  type="danger" onClick={() => deletePackage(courier.deliveryPackage.id)}>
                  <Icon type="delete" />
                </Button>
              </Tooltip>
              <Tooltip 
                title={I18n.t('deliveries.children.couriersList.children.activatePackage.value')}>
                <Button className="deliveries-drawer-btn" 
                  type="primary" onClick={() => setPackageAvalible(courier.deliveryPackage.id)}>
                  <Icon type="car" />
                </Button>
              </Tooltip>
            </Col>
          </Row>,
          content:
            <CourierDeliveries  
              packageId={courier.deliveryPackage && courier.deliveryPackage.id} 
              onDeleteOrder={onDeletePackageOrder}
              onDeletePackage={deletePackage}
            />,
        },
      });
    }
  };

  const showConfirm = () => {
    const { fields, drop } = data;
    const courier = drop.data;
    const orders = fields.map((item: OrderModel) => (
    <Row key={item.id}>
      #{item.id}{`\t${getAddress(item.address)}`}
    </Row>
    ));
    Modal.confirm({
      title: I18n.t('deliveries.children.addOrderToDeliveryConfirm.value'),
      content: orders,
      onOk() {
        drop.isCreate = !courier.deliveryPackage;
        onSubmitOrders(drop, fields);
        setHideConfirm();
      },
      onCancel() {
        setHideConfirm();
      },
      okText: I18n.t('system.children.modals.children.yes.value'),
      cancelText: I18n.t('system.children.modals.children.no.value'),
      autoFocusButton: null,
      maskClosable: true,
    });
  };

  useEffect(() => {
    if (isShowCourierConfirm) {
      showConfirm();
    }
  }, [isShowCourierConfirm]);

  return (
    <List
      itemLayout="horizontal"
      split
      grid={{
        gutter: 16,
      }}
      dataSource={dataSource}
      renderItem={(item: any) => (
        <MultipleDropElement
          key={item.id}
          item={item}
          name={name}
          onClick={onClickCourier}>
          <Row type="flex" justify="start" align="middle" style={{ marginBottom: '5px' }}>
            <Avatar
              style={{
                backgroundColor: `#${hashCode(`${item.firstName} ${item.lastName}`)}`,
                verticalAlign: 'middle',
              }}
              size="large">
              {getName(`${item.firstName} ${item.lastName}`)}
            </Avatar>
            <span style={{
              display: 'flex',
              flexDirection: 'column',
              fontWeight: 'bold',
              fontSize: '18px',
              paddingLeft: '20px',
            }}>
              <span>{`${item.firstName} ${item.lastName}`}</span>
              { item.sellPoint && item.sellPoint && 
                <span style={{
                  fontWeight: 'normal',
                  fontSize: '11px',
                }}>
                  <Icon type="environment" theme="twoTone" /> 
                  {'\t' + item.sellPoint.name}
                </span>
              }
            </span>
          </Row>
          <Row>
            <Col span={24}>
              {item.orders && item.orders.length > 0 ? 
                item.orders.map((order: any) => {
                    order.courierId = item.id;
                    order.sellPoint = {
                      id: item.sellPoint ? item.sellPoint.id : null,
                    };
                    return (
                      <DraggableCourierOrder 
                        key={order.id} 
                        order={order}
                        onDelete={onDeletePackageOrder} 
                        onDropped={onDropped}
                      />
                    );
                }) :
                <Row style={{ color: '#d1d1d1' }}>
                  <Translate value="deliveries.children.couriersList.children.courierPlaceholder.value" />
                </Row>
              }
            </Col>
          </Row>
        </MultipleDropElement>
      )}
    />
  );
};

export default CouriersList;
