import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class ClientsService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private clientsURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.clientsURL = BASE_URL + 'clients';
  }

  public getClients(clientsQuery: any) {
    const query = generateODataQuery(clientsQuery);
    return this.httpService.get(`${this.clientsURL}${query}`);
  }

  public getClient = (id: number) => {
    const URL = `${this.clientsURL}/${id}`;

    return this.httpService.get(URL);
  }

  public getClientByPhone(phone: string) {
    const phoneDTO = this.getCorrectPhoneNumber(phone);
    const URL = `${this.clientsURL}/phone/${phoneDTO}`;
    return this.httpService.get(URL);
  }

  public createClient = (body: any) => {
    const URL = this.clientsURL;

    return this.httpService.post(URL, body);
  }

  public updateClient = (id: number, body: any = null) => {
    const URL = `${this.clientsURL}/${id}`;

    return this.httpService.put(URL, body);
  }

  public deleteClient = (id: number) => {
    const URL = `${this.clientsURL}/${id}`;

    return this.httpService.delete(URL);
  }

  public resetPassword = (id: number) => {
    const URL = `${this.clientsURL}/resetPassword/${id}`;

    return this.httpService.put(URL, null);
  }

  public activateClient = (id: number) => {
    const URL = `${this.clientsURL}/activate/${id}`;

    return this.httpService.put(URL, null);
  }

  public getRoles = () => {
    const URL = BASE_URL + 'roles';

    return this.httpService.get(URL);
  }

  public createClientWithAddress(body: any) {
    return this.httpService.post(`${this.clientsURL}/orderAddress`, JSON.stringify(body));
  }

  public getDTOClientInfo(values: any, address: any) {
    const contacts: any[] = [];
    if (values.contacts) {
      for (let prop in values.contacts) {
        if (values.contacts.hasOwnProperty(prop)) {
          const id = parseInt(prop, 10);
          contacts.push({
            id: isNaN(id) ? null : id,
            firstName: values.contacts[prop].firstName || null,
            phone: this.getCorrectPhoneNumber(values.contacts[prop].phone.phone),
            isPhoneCustom: values.contacts[prop].phone.isPhoneCustom,
          });
        }
      }
    }

    const body = {
        firstName: values.firstName || null,
        phone: this.getCorrectPhoneNumber(values.phone),
        isPhoneCustom: values.isPhoneCustom,
        contacts: contacts.length ? contacts : null,
        address,
    };
    return body;
  }

  public getCorrectPhoneNumber(phone: string) {
    return phone.replace(/\D/g, '');
  }

  public createAddress(address: string, clientId: number) {
    const URL = `${this.clientsURL}/addresses/${clientId}`;
    return  this.httpService.post(URL, address);
  }

  public updateAddress(id: number, address: string) {
    const URL = `${this.clientsURL}/addresses/${id}`;
    return  this.httpService.put(URL, address);
  }

  public deleteAddress(id: number) {
    const URL = `${this.clientsURL}/addresses/${id}`;
    return this.httpService.delete(URL);
  }
}

export default ClientsService;