// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImageCell_image__1MwWI {\n  max-height: 50px;\n}\n\n.ImageCell_container__-BDvL {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.ImageCell_container__-BDvL:hover {\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"image": "ImageCell_image__1MwWI",
	"container": "ImageCell_container__-BDvL"
};
module.exports = exports;
