import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Drawer,
    Modal,
} from 'antd';
import { MainContext } from '../../contexts/MainContextProvider';
import CreateOrderContextProvider, { CreateOrderContext } from '../../contexts/CreateOrderContextProvider';
import {
    SET_IS_HIDE_CREATE_ORDER_DRAWER,
} from '../../constants/main';

import DrawerLayout from '../pages/Orders/components/OrderLayout';
import { withCreateOrderContext } from '../../contexts/withCreatOrderContext';
import { REFRESH } from '../../constants/createOrder';
import { I18n } from 'react-redux-i18n';

const { confirm } = Modal;

const CreateOrderDrawer: React.FC<any> = (props: any) => {
    const { main: mainContext, mainDispatch: dispatchMainContext } = useContext(MainContext);
    const {createOrder: context, createOrderDispatch: dispatchContext} = useContext(CreateOrderContext);
    const { isShowCreateOrder } = mainContext;

    const closeDrawer = () => {
        dispatchContext({
            type: REFRESH,
        });
        dispatchMainContext({
            type: SET_IS_HIDE_CREATE_ORDER_DRAWER,
        });
    };

    const showCloseConfirm = () => {
        confirm({
            title: I18n.t('orders.children.orderDrawer.children.closeDrawerConfirm.value'),
            content: I18n.t('orders.children.orderDrawer.children.closeDrawerConfirmMsg.value'),
            okText: I18n.t('system.children.modals.children.yes.value'),
            cancelText: I18n.t('system.children.modals.children.no.value'),
            onOk() {
                closeDrawer();
            }
        });
    };

    return (
            <Drawer
                title={I18n.t('orders.children.orderDrawer.children.newOrder.value')}
                placement="bottom"
                maskClosable={false}
                height="100%"
                onClose={showCloseConfirm}
                visible={isShowCreateOrder}
                bodyStyle={{
                    overflow: 'auto'
                }}
            >
                {
                    isShowCreateOrder && <DrawerLayout 
                        closeDrawer={closeDrawer} showCloseConfirm={showCloseConfirm}/>
                }
            </Drawer>
    );
};

export default withCreateOrderContext(CreateOrderDrawer);
