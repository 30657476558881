// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdditionalOrderInfo_container__1jObA {\n  width: 800px;\n}\n\n.AdditionalOrderInfo_headRow__3HJ55 {\n  margin-bottom: 4px;\n  font-weight: bold;\n}\n\n.AdditionalOrderInfo_productComment__2vVZ_ {\n  padding-left: 20px;\n  font-style: italic;\n  font-size: 90%;\n}\n\n.AdditionalOrderInfo_commentRow__2IfUb {\n  margin-top: 8px;\n  font-style: italic;\n}\n\n.AdditionalOrderInfo_commentRow__2IfUb span, .AdditionalOrderInfo_totalRow__357bj {\n  font-weight: bold;\n  font-style: normal;\n}\n\n.AdditionalOrderInfo_columnCentered__2-Ao8 {\n  text-align: center;\n}\n\n.AdditionalOrderInfo_columnRight__1oKA9 {\n  text-align: right;\n}", ""]);
// Exports
exports.locals = {
	"container": "AdditionalOrderInfo_container__1jObA",
	"headRow": "AdditionalOrderInfo_headRow__3HJ55",
	"productComment": "AdditionalOrderInfo_productComment__2vVZ_",
	"commentRow": "AdditionalOrderInfo_commentRow__2IfUb",
	"totalRow": "AdditionalOrderInfo_totalRow__357bj",
	"columnCentered": "AdditionalOrderInfo_columnCentered__2-Ao8",
	"columnRight": "AdditionalOrderInfo_columnRight__1oKA9"
};
module.exports = exports;
