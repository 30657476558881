import { IODataModel } from './generateODataQuery';
import { FilterTypes } from '../constants/filterTypes';

export const getQueryObjFromState = (filters: any, sorter: any, pagination: any, count = true): IODataModel => {
  const filter = [] as any[];
  for (const prop in filters) {
    if (filters.hasOwnProperty(prop) && filters[prop].type &&
      (filters[prop].value || filters[prop].value === false)) {
      switch (filters[prop].type) {
        case FilterTypes.SEARCH:
          filter.push({ [`tolower(${prop})`]: { 
            contains: encodeURIComponent(filters[prop].value.toLowerCase().replace(/ /g, '%')),
          }});
          break;
        case FilterTypes.INPUT:
        case FilterTypes.RADIO:
        case FilterTypes.SELECT:
          filter.push({ [`${prop}`]: encodeURIComponent(filters[prop].value) });
          break;
        case FilterTypes.CHECKBOX:
        case FilterTypes.SELECT_MULTIPLE:
          if (filters[prop].value.length) {
            filter.push({ or: filters[prop].value.map((item: any) => ({ [`${prop}`]: encodeURIComponent(item) })) });
          }
          break;
        case FilterTypes.DATE_RANGE:
          const [from, to] = filters[prop].value;
          if (from && to) {
            filter.push({
              and: [
                {
                  [`${prop}`]: {
                    gt: JSON.parse(JSON.stringify(from)),
                  }
                },
                {
                  [`${prop}`]: {
                    lt: JSON.parse(JSON.stringify(to)),
                  }
                }
              ]
            });
          }
          break;
        default:
          break;
      }
    }
  }

  let orderBy: string | Array<string | null> | null = null;
  if (Array.isArray(sorter)) {
    orderBy = sorter.map((sorterItem: any) => {
      if (sorterItem && sorterItem.order) {
        return `${(sorterItem.column && sorterItem.column.sortIndex) 
          || sorterItem.field} ${sorterItem.order === 'ascend' ? 'asc' : 'desc'}`;
      } else if (typeof sorterItem === 'string') {
        return sorterItem;
      } else {
        return null;
      }
    });
    orderBy = orderBy.filter((item) => !!item);
  } else {
    orderBy = sorter && sorter.order ?
      `${(sorter.column && sorter.column.sortIndex) || sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}` 
      : null;
  }

  const skip = pagination.pageSize * (pagination.current - 1);
  const top = pagination.pageSize;

  return { filter, orderBy, skip, top, count };
};