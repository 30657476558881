import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';
import CourierIcon from './CourierIcon';
import ClearIcon from './ClearIcon';
import ProductsIcon from './ProductsIcon';

const CustomIcon = (props: IconProps) => {
  switch (props.type) {
    case 'courier':
      return <CourierIcon />;
    case 'clear':
      return <ClearIcon />;
    case 'products':
      return <ProductsIcon />;
    default:
      return <Icon {...props} />;
  }
};

export default CustomIcon;