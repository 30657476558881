import { FilterTypes } from '../../../../constants/filterTypes';
import { RoleModel } from '../../../../models/RoleModel';
import { PageStateModel } from '../../../../reducers/pageStateReducer';
import { SellPointModel } from '../../../../models/SellPointModel';
import { handleDigitInputOnKeyDown } from '../../../../helpers/checkDigit';
import { I18n } from 'react-redux-i18n';

interface UserFiltersProps {
  roles: RoleModel[];
  sellPoints: SellPointModel[];
  inits?: {
    [prop: string]: any;
  };
}

export const userFilters = ({roles, sellPoints, inits}: UserFiltersProps) => ([
  {
    filterIndex: 'roles/name',
    type: FilterTypes.SELECT_MULTIPLE,
    values: roles,
    placeholder: I18n.t('form.children.roles.value'),
    showField: 'description',
    keyField: 'name',
    initialValue: (inits && inits['roles/name']) || [],
  },
  {
    filterIndex: 'phone',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.phone.value'),
    onKeyDown: handleDigitInputOnKeyDown,
    className: 'phone-filter-input',
    initialValue: (inits && inits.phone) || '',
  },
  {
    filterIndex: 'login',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.login.value'),
    initialValue: (inits && inits.login) || '',
  },
  {
    filterIndex: 'lastName',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.lastName.value'),
    initialValue: (inits && inits.lastName) || '',
  },
  {
    filterIndex: 'sellPoint/id',
    type: FilterTypes.SELECT_MULTIPLE,
    values: sellPoints,
    placeholder: I18n.t('form.children.salePoint.value'),
    showField: 'name',
    keyField: 'id',
    initialValue: (inits && inits['sellPoint/id']) || [],
  },
]);

export const initialState: PageStateModel = {
  filters: {
    phone: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    login: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    lastName: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    'sellPoint/id': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
    'roles/name': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
  },
  sorter: {},
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};