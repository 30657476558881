import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Row, Col, Card } from 'antd';
import UsersService from '../../../services/UsersService';
import UserForm from './UserForm';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { UserModel } from '../../../models/UserModel';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import userColumns from './enums/UserColumns';
import { RoleModel } from '../../../models/RoleModel';
import RolesService from '../../../services/RolesService';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import { pageStateReducer, SET_FILTERS, RESET_FILTERS, SET_SORTER, SET_PAGINATION } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import DictionaryService from '../../../services/DictionaryService';
import { SellPointModel } from '../../../models/SellPointModel';
import { userFilters, initialState } from './enums/UserFilters';
import TableTitle from '../../TableTitle/TableTitle';
import { getRowClassName } from '../../../helpers/getRowClassName';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { userControls } from './enums/UserControls';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setUsers as setUsersPage } from '../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';
import { connect } from 'react-redux';
import { IStore } from '../../../redux/store';
import { getUser } from '../../../redux/session/sessionSelectors';

const usersService = new UsersService();
const rolesService = new RolesService();
const dictionaryService = new DictionaryService();

const Users = (props: any) => {
  const defaultSellPointId = props.user.sellPoint ? props.user.sellPoint.id : null;

  const [users, setUsers] = useState([]);
  const [formVisible, setFormVisible]: any = useState({
    visible: false,
    id: null,
  });
  const [roles, setRoles] = useState([] as RoleModel[]);
  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate } = useTableSelection();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);

  const { height: wHeight, width: wWidth } = useWindowDimensions();

  useEffect(() => {
    getRoles();
    getSellPoints();
  }, []);

  const { pages: { users: usersPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, usersPage);
  const stateRef = useRef(state);
  
  useEffect(() => {
    if (state.needUpdate) {
      getUsers(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setUsersPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setUsersPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };

  // ----------------- TABLE HANDLING ----------------- //

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ----------------- USERS HANDLING ----------------- //

  function getUsers(filters = state.filters, sorter = state.sorter, pagination = state.pagination) {
    setFetchingData(true);
    usersService.getUsers(getQueryObjFromState(filters, ['lockedDate desc', sorter], pagination))
      .then((value) => {
        setUsers(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }

  const getRoles = async () => {
    try {
      const roles = await rolesService.getRoles();
      setRoles(roles);
    } catch (e) {
      console.error(e);
      getErrorNotification(e);
    }
  };

  const getSellPoints = async () => {
    try {
      const sellPoints = await dictionaryService.getSellPoints();
      setSellPoints(sellPoints);
    } catch (e) {
      console.error(e);
    }
  };

  function createUser(user: UserModel) {
    return usersService.createUser(JSON.stringify(user))
      .then((value) => {
        getUsers();
        getSuccessNotification('Новий користувач створений');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  }

  function updateUser(user: UserModel) {
    return usersService.updateUser(user.id, JSON.stringify(user))
      .then((value) => {
        getUsers();
        getSuccessNotification('Користувача оновлено');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  }

  function toggleUserActivation(id: number, locked: string | null) {
    const updateUserActivation = locked === null ?
      usersService.deactivateUser(id) : usersService.activateUser(id);
      
    setFetchingData(true);
    updateUserActivation.then((value) => {
      getUsers();
      getSuccessNotification(`Користувача ${locked ? '' : 'де'}активовано`);
    })
    .catch((e) => {
      console.error('ERROR: ', e);
      getErrorNotification(e);
      setFetchingData(false);
    });
  }

  // ----------------- MODALS HANDLING ----------------- //

  const openForm = (id?: number) => {
    setFormVisible({
      visible: true,
      id: id ? id : null,
    });
  };

  const closeForm = () => {
    setFormVisible({
      visible: false,
      id: null,
    });
  };

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
            <Col md={24} xl={24} >
              <FiltersPanel
                filters={userFilters({
                  roles, sellPoints,
                  inits: {
                    ...getFilterInitsFromPageState(usersPage.filters),
                    'sellPoint/id': defaultSellPointId ? [defaultSellPointId] : usersPage.filters['sellPoint/id'].value,
                  }, 
                })}
                onFiltersConfirm={setFilters}
                onFiltersReset={resetFilters}
              />
            </Col>
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle 
            controls={userControls({openForm, toggleUserActivation, currentUser: selected, refresh: getUsers})}
          />
        )}
        onChange={handleTableChange}
        onRow={onRow}
        bordered
        dataSource={users}
        columns={userColumns({sorter: state.sorter, defaultSellPointId})}
        className="main-table"
        rowClassName={getRowClassName(selected)}
        size="middle"
        rowKey="id"
        pagination={state.pagination}
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 88 - 32 - 42, x: wWidth - 200 }}
      />
      {formVisible.visible &&
        <UserForm
          closeForm={closeForm}
          submitUser={formVisible.id ? updateUser : createUser}
          userId={formVisible.id}
        />}
    </div>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
});

export default connect<{}, {}, any>(mapStateToProps)(Users);
