import React, { useEffect, useContext, useState } from 'react';
import DrawerLayout from './components/OrderLayout';
import { withCreateOrderContext } from '../../../contexts/withCreatOrderContext';
import OrdersService from '../../../services/OrdersService';
import { CreateOrderContext } from '../../../contexts/CreateOrderContextProvider';
import { SET_FULL_ORDER } from '../../../constants/createOrder';
import moment from 'moment';
import { getErrorNotification } from '../../Notifications/Notifications';
import { Spin } from 'antd';

const ordersService = new OrdersService();

const OrderForm = (props: any) => {
  const orderId = props.match.params.id;
  const { createOrderDispatch: dispatch } = useContext(CreateOrderContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ordersService.getOrder(orderId)
      .then((order) => {
        const { client, address, minExecuteDate, maxExecuteDate, productsPrice, orderAddress,
          deliveryType, payments, deliveryPrice, sellPoint, purchases, comment, deliveryPackage, createdUser } = order;
        dispatch({
          type: SET_FULL_ORDER,
          id: order.id,
          statusCode: order.orderStatus.code,
          clientInfo: { ...client, address: {...address}},
          orderInfo: {
            date: minExecuteDate && moment(minExecuteDate),
            timeFrom: minExecuteDate && moment(minExecuteDate),
            timeTo: maxExecuteDate && moment(maxExecuteDate),
            deliveryType,
            paymentType: payments.paymentType,
            deliveryPrice,
            sellPoint,
            comment,
            deliveryPackage,
            createdUser,
            orderAddress,
          },
          orderData: purchases.map((item: any) => {
            return { 
              ...item.product, 
              count: item.count * item.product.minCount, 
              comment: item.comment || null, 
              productOptions: [{price: item.price}]
            };
          }),
          totalSum: Number(productsPrice) + (deliveryPrice ? Number(deliveryPrice.price) : 0),
        });
        setIsLoading(false);
      })
      .catch((e) => {
        getErrorNotification(e);
        closeForm();
      });
  }, []);

  const closeForm = () => {
    props.history.goBack();
  };

  return (
    <Spin spinning={isLoading}>
      <DrawerLayout orderId={orderId} closeForm={closeForm}/>
    </Spin>
  );
};

export default withCreateOrderContext(OrderForm);
