import { FilterTypes } from '../constants/filterTypes';

export const SET_FILTER = 'pageStateReducer/SET_FILTER';
export const SET_FILTERS = 'pageStateReducer/SET_FILTERS';
export const SET_SORTER = 'pageStateReducer/SET_SORTER';
export const SET_PAGINATION = 'pageStateReducer/SET_PAGINATION';
export const RESET_FILTERS = 'pageStateReducer/RESET_FILTERS';

export interface PageActionModel {
  type: string;
  filterIndex?: string;
  value?: any;
  filters?: any;
  sorter?: any;
  pagination?: any;
  needUpdate?: boolean;
  withFilters?: boolean;
}

export interface PageStateModel {
  filters: {
    [filterName: string]: {
      type: FilterTypes;
      value: any;
      [prop: string]: any;
    };
  };
  sorter?: {
    order?: 'ascend' | 'descend';
    field?: string;
    column?: {
      filterIndex?: string;
      [columnProp: string]: any;
    }
  };
  pagination?: {
    current: number;
    pageSize: number;
    total: number;
    [paginationProp: string]: any;
  };
  needUpdate?: boolean;
  isWithFilters?: boolean;
}

export const setFilter = (filterIndex: string, value: any) => ({
  type: SET_FILTER,
  filterIndex,
  value,
});

export const pageStateReducer = (state: PageStateModel, action: PageActionModel) => {
  switch (action.type) {
    case SET_FILTER:
      if (action.filterIndex) {
        return {
          ...state, 
          filters: {
            ...state.filters, 
            [action.filterIndex]: {
              ...state.filters[action.filterIndex],
              value: action.value,
            },
          },
          isWithFilters: true,
          needUpdate: true
        };
      }
      return state;
    case SET_FILTERS: {
      const newFilters = {} as any;
      for (let field in state.filters) {
        if (state.filters.hasOwnProperty(field)) {
          newFilters[field] = {...state.filters[field], value: action.filters[field]};
        }
      }
      return {
        ...state, 
        filters: newFilters, 
        isWithFilters: true,
        needUpdate: true
      };
    } 
    case SET_SORTER: {
      return {...state, sorter: action.sorter, needUpdate: true};
    }
    case SET_PAGINATION: {
      if (state.pagination && state.pagination.current === action.pagination.current && 
        state.pagination.pageSize === action.pagination.pageSize) {
        return {...state, pagination: action.pagination, needUpdtae: false};
      }
      return {...state, pagination: action.pagination, needUpdate: true};
    }
    case RESET_FILTERS: {
      return {...state, filters: action.filters, isWithFilters: false, needUpdate: !!state.isWithFilters };
    }
    default:
      return state;
  }
};