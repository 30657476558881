import { I18n } from 'react-redux-i18n';
import { handleDigitInputOnKeyDown } from './../../../../helpers/checkDigit';
import { PageStateModel } from './../../../../reducers/pageStateReducer';
import { FilterTypes } from './../../../../constants/filterTypes';

interface ClientsFiltersArgs {
  inits?: {
    [x: string]: any;
  };
}

export const clientsFilters = ({inits}: ClientsFiltersArgs) => ([
  {
    filterIndex: 'phone',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.phone.value'),
    className: 'phone-filter-input',
    onKeyDown: handleDigitInputOnKeyDown,
    initialValue: (inits && inits.phone) || '',
  },
  {
    filterIndex: 'firstName',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.firstName.value'),
    initialValue: (inits && inits.firstName) || '',
  },
  {
    filterIndex: 'lastName',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.lastName.value'),
    initialValue: (inits && inits.lastName) || '',
  },
]);

export const ClientsInitialState: PageStateModel = {
  filters: {
    phone: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    firstName: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    lastName: {
      type: FilterTypes.SEARCH,
      value: '',
    },
  },
  sorter: {},
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};