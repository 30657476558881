import React, { useContext } from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import css from './UserMenu.module.css';
import { logOutSuccess } from '../../redux/session/actions';
import { refreshPages } from '../../reducers/pagesReducer';
import { PagesContext } from '../../contexts/PageStateContextProvider';
import { Translate } from 'react-redux-i18n';

const UserMenu = (props: any) => {
  const { logOutSuccess } = props;
  const { pagesDispatch } = useContext(PagesContext);

  const handleLogout = () => {
    pagesDispatch(refreshPages());
    logOutSuccess();
  };

  return (
    <Menu
      mode="inline"
      style={{ width: 256 }}
    >
      <Menu.Item key="logout">
        <button id={`logout-btn`} className={css.logout} onClick={handleLogout}>
          <Translate value="system.children.logout.value" />
        </button>
      </Menu.Item>

    </Menu>
  );
};

const mapDispatchToProps: any = {
  logOutSuccess,
};

export default connect<{}, {}, any>(null, mapDispatchToProps)(UserMenu);
