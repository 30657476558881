import React, { useState } from 'react';
import { Popover, Icon } from 'antd';
import { getMoneyFormat } from '../../../../helpers/getMoneyFormat';
import css from './CourierMoneyCell.module.css';
import MoneyPopover from './MoneyPopover';

interface CourierMoneyCellProps {
  money: number;
  showCashHistory: () => void;
  updateCourierMoney: (sum: number | undefined) => void;
}

const CourierMoneyCell = ({ money, showCashHistory, updateCourierMoney }: CourierMoneyCellProps) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleTextClick = () => {
    showCashHistory();
  };

  const handleAddButton = (value: number | undefined) => {
    setIsPopupVisible(false);
    updateCourierMoney(value);
  }; 

  const handlePopupVisibility = (visible: boolean) => {
    setIsPopupVisible(visible);
  };

  return (
    <div className={css.cellContainer}>
      <span 
        className={css.moneyText}
        onClick={handleTextClick}
      >
        {getMoneyFormat(money)}
      </span>
      <Popover
        destroyTooltipOnHide
        arrowPointAtCenter
        placement="topRight"
        trigger="click"
        visible={isPopupVisible}
        onVisibleChange={handlePopupVisibility}
        content={<MoneyPopover onAddButton={handleAddButton} onCancelButton={() => setIsPopupVisible(false)}/>}
      >
        <Icon
          className={css.addIcon}
          type="plus-circle"
        />
      </Popover>
    </div>
  );
};

export default CourierMoneyCell;