import React, { useState } from 'react';
import css from './CourierMoneyCell.module.css';
import { Button, Icon, } from 'antd';
import MoneyInput from './MoneyInput';

interface MoneyPopoverProps {
  onAddButton: (value: number | undefined) => void;
  onCancelButton: () => void;
}

const MoneyPopover = ({onAddButton, onCancelButton}: MoneyPopoverProps) => {
  const [value, setValue] = useState('');

  const handleAddButton = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onAddButton(Number(value));
  };

  const handleCancel = (e: React.MouseEvent) => {
    onCancelButton();
  }

  const handleChage = (value: string) => {
    setValue(value);
  };

  return (
    <div className={css.popoverContainer}>
      <MoneyInput 
        value={value} autoFocus className={css.input} 
        maxValue={999999} minValue={-999999} onChange={handleChage}
      />
      <div className={css.buttonsContainer}>
        <Button size="small" type="primary" className={css.button} onClick={handleAddButton}>
          <Icon type="check" />
        </Button>
        <Button size="small" className={css.button} onClick={handleCancel}>
          <Icon type="close" />
        </Button>
      </div>
    </div>
  );
};

export default MoneyPopover;