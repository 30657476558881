import React, { FormEvent, useState, useEffect } from 'react';
import { Form, Input, Checkbox, Upload, Icon, Spin, Modal } from 'antd';
import '../../../styles/modal-form.css';
import { CategoryModel } from '../../../models/CategoryModel';
import CategoriesPageService from '../../../services/CategoriesPageService';
import { getErrorNotification } from '../../Notifications/Notifications';
import { getImageURL } from '../../../helpers/getImageURL';
import { formLayoutDefaults, getDefaultModalProps } from '../../../constants/ModalFormConstants';
import { FormComponentProps } from 'antd/lib/form';
import { I18n, Translate } from 'react-redux-i18n';

interface CategoryFormProps extends FormComponentProps {
  categoryId?: number;
  submitCategory: (category: CategoryModel, images: FormData) => Promise<void>;
  closeForm: () => void;
}

const service = new CategoriesPageService();

const CategoryForm = (props: CategoryFormProps) => {
  const { getFieldDecorator } = props.form;
  const { categoryId, submitCategory, closeForm } = props;

  const [category, setCategory] = useState({} as CategoryModel);
  const [imageURL, setImageURL]: any = useState(null);
  const [fileList, setFileList] = useState([] as any[]);

  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    getFormInfo();
  }, [categoryId]);

  async function getFormInfo() {
    if (categoryId) {
      try {
        setFetchingData(true);
        const category = await service.getCategory(categoryId);

        getCategoryImage(category.categoryImages);
        setCategory(category);
      } catch (e) {
        console.error('ERROR ', e);
        getErrorNotification(e);
      } finally {
        setFetchingData(false);
      }
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const finalCategory = {
          ...values,
          description: values.description || null,
        } as CategoryModel;
        if (categoryId) {
          finalCategory.id = categoryId;
        }

        const imagesData = new FormData();
        for (const file of fileList) {
          if (file.originFileObj) {
            imagesData.append('file', file.originFileObj);
          }
        }

        setFetchingData(true);
        submitCategory(finalCategory, imagesData)
          .finally(() => setFetchingData(false));
      }
    });
  };

  const getCategoryImage = (img: { uuid: string, id: number } | null) => {
    if (img) {
      setFileList([{
        name: img.id,
        uid: img.uuid,
        url: imageURL,
      }]);
      setImageURL(getImageURL(img.uuid));
    }
  };

  const handleImage = (info: any) => {
    const imageURL = URL.createObjectURL(info.file);
    setFileList(info.fileList.slice(info.fileList.length - 1));
    setImageURL(imageURL);
  };

  const uploadText = (
    <div>
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text"><Translate value="form.children.dndFiles.value"/></p>
    </div>
  );

  return (
    <Modal
      {...getDefaultModalProps(I18n)}
      title={categoryId ? I18n.t('categories.children.editCategory.value') : I18n.t('categories.children.createCategory.value')}
      onCancel={closeForm}
      onOk={handleSubmit}
      width={500}
    >
      <Spin spinning={fetchingData}>
        <Form onSubmit={handleSubmit} {...formLayoutDefaults} className="modal-form">
          <Form.Item  label={I18n.t('categories.children.title.value')}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: category.name,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('categories.children.description.value')}>
            {getFieldDecorator('description', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: category.description,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('categories.children.image.value')}>
            {getFieldDecorator('images', {
            })(
              <Upload.Dragger
                name="image"
                accept="image/*"
                beforeUpload={(file) => false}
                onChange={handleImage}
                showUploadList={false}
                fileList={fileList}
              >
                {
                  imageURL ?
                    <img src={imageURL} className="upload-image" alt={imageURL} /> : uploadText
                }

              </Upload.Dragger>,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('categories.children.isAvalible.value')}>
            {getFieldDecorator('isActive', {
              valuePropName: 'checked',
              initialValue: category.isActive,
            })(
              <Checkbox />,
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Form.create<any>({ name: 'CategoryForm' })(CategoryForm);
