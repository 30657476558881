import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class OrdersService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private ordersURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.ordersURL = BASE_URL + 'orders';
  }

  public getOrders(filters?: any, sorter?: any, pagination?: any) {
    const URL = this.ODataService.composeQuery(this.ordersURL, filters, sorter, pagination);
    return this.httpService.get(URL);
  }

  public getFilteredOrders(ordersQuery: any = null) {
    const query = generateODataQuery(ordersQuery);
    return this.httpService.get(`${this.ordersURL}${query}`);
  }

  public getOrder = (id: number) => {
    const URL = `${this.ordersURL}/${id}`;
    return this.httpService.get(URL);
  }

  public createOrder = (body: any) => {
    const URL = this.ordersURL;
    return this.httpService.post(URL, body);
  }

  public duplicateOrder = (id: number) => {
    const URL = `${this.ordersURL}/duplicate/${id}`;
    return this.httpService.post(URL, null);
  }

  public updateOrder = (id: number, body: any = null) => {
    const URL = `${this.ordersURL}/${id}`;
    return this.httpService.put(URL, body);
  }

  public updateOrderStatus = (id: number, status: string) => {
    const URL = `${this.ordersURL}/set_status/${id}`;
    return this.httpService.put(URL, status);
  }

  public confirmOrder = (id: number, isConfirmed: boolean = false, timeToPrepare: string | null = null) => {
    const URL = `${this.ordersURL}/confirm/${id}`;
    const body = {
      isConfirmed,
      timeToPrepare
    };
    return this.httpService.put(URL, JSON.stringify(body));
  }

  public deactivateOrder = (id: number) => {
    const URL = `${this.ordersURL}/delete/${id}`;
    return this.httpService.put(URL, null);
  }

  public deleteOrder = (id: number) => {
    const URL = `${this.ordersURL}/${id}`;
    return this.httpService.delete(URL);
  }

  public getOrderStatuses = () => {
    const URL = BASE_URL + 'order_statuses?$orderby=ord asc';
    return this.httpService.get(URL);
  }

  public getPDFBill = (id: number) => {
    const URL = `${this.ordersURL}/receipt/${id}`;  
    return this.httpService.getFile(URL);
  }

  public getCategories = () => {
    const URL = BASE_URL + 'categories?$select=id&$select=name&$filter=isActive eq true';
    return this.httpService.get(URL);
  }

  public getProducts = (sellPointFilter: string) => {
    const URL = BASE_URL + `products${sellPointFilter}`;

    return this.httpService.get(URL);
  }

  public getDeliveryTypes = () => {
    const URL = BASE_URL + 'delivery_types?$orderby=id asc';

    return this.httpService.get(URL);
  }

  public getDeliveryPrices = () => {
    const URL = BASE_URL + 'delivery_prices';

    return this.httpService.get(URL);
  }

  public getPaymentTypes = () => {
    const URL = BASE_URL + 'payment_types?$orderby=id asc';

    return this.httpService.get(URL);
  }
}

export default OrdersService;