import React, { useState, useContext, useEffect } from 'react';
import { Menu, Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import css from './NavMenu.module.css';
import {MainContext} from '../../../../contexts/MainContextProvider';
import {SET_IS_SHOW_CREATE_ORDER_DRAWER} from '../../../../constants/main';
import CustomIcon from '../../../icons/CustomIcon';
import * as selectors from '../../../../redux/session/sessionSelectors';
import { IStore } from '../../../../redux/store';
import RouteList from '../../../Routes/enum/RouterList';
import { RouterListModel } from '../../../../models/RouterListModel';
import * as sysParams from '../../../../constants/SystemParamsVariables';

import { Translate } from 'react-redux-i18n';

const { Sider } = Layout;

const NavMenu = (props: any) => {
  const { location, user } = props;
  const [collapsed, setCollapsed] = useState(false);
  const {mainDispatch: dispatchMainContext} = useContext(MainContext);

  const functions = user ? user.functions : [];
  const parameters = user ? user.rolesParams : null;

  const getRouting = (functions: RouterListModel[] = []) => {
    const routerList = RouteList;
    const result: any[] = [];
    functions.forEach((item: RouterListModel) => {
      const route = routerList.find((routerItem: any) => routerItem.code === item.path);
      if (route) {
        const pushItem = {...route, name: item.name, childrens: item.child};
        result.push(pushItem);
      }
    });
    return result;
  };

  const routings = getRouting(functions);

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const openCreateOrder = () => {
    dispatchMainContext({
      type: SET_IS_SHOW_CREATE_ORDER_DRAWER,
    });
  };

  const parseMenuItem = (route: any) => {
    if (route.childrens && route.childrens.length > 0) {
      const childRoutings: any[] = getRouting(route.childrens);
      return (
        <Menu.SubMenu
          key={route.code}
          title={
            <div>
              <CustomIcon type={route.icon} />
              <span>{route.name}</span>
            </div>
        }>
          {
            childRoutings.map((item: any) => (
              <Menu.Item key={item.code}>
                <Link to={`${item.path}`} >
                  <CustomIcon type={item.icon} />
                  <span>{item.name}</span>
                </Link>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={route.code}>
          <Link to={`${route.path}`} >
            <CustomIcon type={route.icon} />
            <span>
              <Translate value={`${route.code}.value`}/>
            </span>
          </Link>
        </Menu.Item>
      );
    }
  };
  return (
    <Sider
      theme="light"
      className={css.sider}
      collapsed={collapsed}
      width={210}
    >
      <div>
        <Button
          className={css.collapseButton}
          type="link"
          size="small"
          onClick={toggleMenu}>
          <CustomIcon type={collapsed ? 'right' : 'left'} />
        </Button>
      </div>
      <div className={css.container}>
        <Menu
          mode="inline"
          theme="light"
          style={{ height: 100 + '%', width: '100%' }}
          selectedKeys={[location.pathname.split('/')[1], location.pathname.substr(1)]}
        >
          {
            routings.map((route: RouterListModel) => parseMenuItem(route))
          }
        </Menu>
      </div>
      {
        parameters && parameters[sysParams.IS_ACCEPT_CALLS] === 'true' &&
        <div className={css.openCreateOrderButton}>
          <Button
            type="link"
            size="small"
            style={{width: '100%'}}
            onClick={openCreateOrder}
            id="open-create-order-btn">
            <CustomIcon type="phone" theme="twoTone" twoToneColor="#52c41a" style={{ fontSize: '36px' }} />
          </Button>
        </div>
      }
    </Sider>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: selectors.getUser(state),
});
export default compose(
  connect<{}, {}, any>(
    mapStateToProps
  ),
)(NavMenu);
