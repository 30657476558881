// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductComment_container__1xa6W {\n  cursor: pointer;\n}\n\n.ProductComment_comment__mJ5Ti {\n  font-size: 0.9em;\n  font-style: italic;\n}\n\n.ProductComment_editContainer__QIo3_ {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n}\n\ninput.ProductComment_input__3hDfv {\n  width: calc(100% - 54px);\n  border: none;\n}\n\ninput.ProductComment_input__3hDfv:focus {\n  box-shadow: none;\n}\n\n.ProductComment_saveButton__3YxGM {\n  margin-left: auto;\n}\n\n.ProductComment_placeholder__b30QD {\n  color: #c7c8d2;\n}", ""]);
// Exports
exports.locals = {
	"container": "ProductComment_container__1xa6W",
	"comment": "ProductComment_comment__mJ5Ti",
	"editContainer": "ProductComment_editContainer__QIo3_",
	"input": "ProductComment_input__3hDfv",
	"saveButton": "ProductComment_saveButton__3YxGM",
	"placeholder": "ProductComment_placeholder__b30QD"
};
module.exports = exports;
