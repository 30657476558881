/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  Tabs,
  Input,
  Card,
  Row,
  Col,
  Tooltip, List, Spin, ConfigProvider,
} from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import RadioGroupViewOrders from '../../../RadioGroupViewOrders/RadioGroupViewOrders';
import CategoriesService from '../../../../services/CategoriesPageService';
import { getErrorNotification } from '../../../Notifications/Notifications';
import ProductsService from '../../../../services/ProductsService';
import SettingsService from '../../../../services/SettingsService';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { SET_ORDER_AND_TOTAL_SUM } from '../../../../constants/createOrder';
import { DeliveryTypes } from '../../../../constants/deliveryTypes';
import InfiniteScroll from 'react-infinite-scroller';
import ukUA from 'antd/es/locale/uk_UA';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from '../../../../redux/session/sessionSelectors';
import { IStore } from '../../../../redux/store';
import { I18n, Translate } from 'react-redux-i18n';

const CardGrid: any = Card.Grid;
const categoriesService = new CategoriesService();
const productsService = new ProductsService();

const top = 20;
const defaultPriceSellPointName = 'default_price_sell_point';

const Products: React.FC<any> = (props: any) => {
  const [skip, setSkip] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(null) as any;
  const { width } = useWindowDimensions();
  const [typeOfViewList, setTypeOfViewList] = useState('grid');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [currCategoryKey, setCurrCategoryKey] = useState(-1);
  const [searchValue, setSearchValue] = useState('');
  const {
    createOrder: context,
    createOrderDispatch: contextDispatch
  } = useContext(CreateOrderContext);

  const orderInfo = context ? context.orderInfo : undefined;
  const currSellPoint = orderInfo && orderInfo.sellPoint && orderInfo.sellPoint.id;

  const settingsService = new SettingsService(props.settings);

  useEffect(() => {
    searchProducts();
  }, [currSellPoint, searchValue]);

  const isSmallScreen = width <= 1200;

  const getCategories = () => {
    categoriesService.getActiveCategories()
      .then((res: any) => {
        const categories = res || [];
        categories.unshift({
          id: -1,
          name: I18n.t('orders.children.orderDrawer.children.all.value'),
        });
        setCategories(categories);
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const checkProductOptions = (item: any) => {
    const {productOptions} = item;
    if (orderInfo && orderInfo.sellPoint) {
      if (productOptions && productOptions.length > 0) {
        const sellPoint = productOptions.find((item: any) => item.sellPoint.id === orderInfo.sellPoint.id);
        if (!sellPoint || !sellPoint.available) {
          item.isInvalid = true;
        }
      }
    }
    return item;
  };

  const getProducts = (filter: any = null) => {
    return productsService.getFilteredProductsPage(top, 1, filter)
      .then((res: any) => {
        setProducts(res.items.map((item: any) => checkProductOptions(item)));
        setSkip(2);
        setHasMore(true);
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const loadMore = () => {
    setIsLoading(true);
    productsService.getFilteredProductsPage(top, skip, filter)
      .then((res: any) => {
        if (res.items.length < top) {
          setHasMore(false);
        }
        setProducts((prod) => prod.concat(res.items.map((item: any) => checkProductOptions(item))));
        setSkip((skip) => {
          return skip + 1;
        });
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFilterObject = (value: any, key: any, point: any) => {
    const filter = [];    
    if (value) {
      const val = { 'tolower(title)': { contains: encodeURIComponent(value.toLowerCase()) } };
      filter.push(val);
    }
    if (key && Number(key) > 0) {
      const category: any = { 'category/id': { eq: key } };
      filter.push(category);
    }
    if (point) {
      const isFilter = orderInfo && orderInfo.deliveryType && orderInfo.deliveryType.code === DeliveryTypes.self;
      if (isFilter) {
        const sellPoint = { 'productOptions/sellPoint/id': { eq: point } };
        filter.push(sellPoint);
      }
    }

    setFilter(filter);
    setProducts([]);
    setHasMore(true);
    return filter;
  };

  const searchProducts = (value?: string) => {
    const val = value === undefined ? searchValue : value;
    let filter: any = getFilterObject(val, currCategoryKey, currSellPoint);
    getProducts(filter);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const changeViewList = (event: any) => {
    setTypeOfViewList(event.target.value);
  };

  const handleTabChange = (key: string, e: MouseEvent) => {
    setCurrCategoryKey(Number(key));
    let filter: any = getFilterObject(searchValue, key, currSellPoint);
    getProducts(filter);
  };

  const getCurrentPrice = (options: any) => {
    if (options) {
      const defaultPriceSellPoint = settingsService.getSettingsValueByName(defaultPriceSellPointName);
      const productOptions = options.find((item: any) => {
        const sellPoint = item.sellPoint ? item.sellPoint : undefined;
        if (sellPoint && orderInfo) {
          return (orderInfo.sellPoint && sellPoint.id === orderInfo.sellPoint.id) ||
            sellPoint.id === defaultPriceSellPoint;
        }
        return false;
      });
      if (productOptions) {
        return Number(productOptions.price);
      } else if (options && options.length > 0) {
        return options[0].sellPoint && Number(options[0].price);
      }
    }
    return 0;
  };

  const findTotal = (orderData: any) => {
    let total: number = 0;
    for (const product of orderData) {
      total += getCurrentPrice(product.productOptions) / product.minCount * product.count;
    }

    return total;
  };

  const recordInOrder = (record: any, order: any) => {
    for (let i = 0; i < order.length; i++) {
      if (order[i].title === record.title) {
        return i;
      }
    }
    return -1;
  };

  const onClickProduct = (item: any) => {
    const { orderData } = context;
    const newOrderData: any[] = orderData.slice(0);
    const index = recordInOrder(item, newOrderData);

    if (index > -1) {
      newOrderData[index].count += item.minCount;
    } else {
      newOrderData.push({...item, count: item.minCount });
    }
    contextDispatch({
      type: SET_ORDER_AND_TOTAL_SUM,
      orderData: newOrderData,
      totalSum: findTotal(newOrderData),
    });
  };

  const getProductPrice = (options: any) => {
    const price = getCurrentPrice(options);
    return price || price === 0 ? `${price} ${I18n.t('currency.children.uah.value')}` : '';
  };

  useEffect(() => {
    getCategories();
    getProducts();
  }, []);

  return (
    <div style={{
      maxHeight: props.height,
      overflow: 'hidden',
    }}>
      <div style={{ paddingBottom: '10px', }}>
        <Row gutter={10}>
          <Col span={20}>
            <Input.Search
              placeholder={I18n.t('orders.children.orderDrawer.children.productSearchPlaceholder.value')}
              onSearch={searchProducts}
              onChange={handleChange}
              style={{ width: '100%' }}
              value={searchValue}
              allowClear
            />
          </Col>
          <Col span={4}>
            <Row type="flex" justify="end">
              <RadioGroupViewOrders type={typeOfViewList} onChange={changeViewList} />
            </Row>
          </Col>
        </Row>
      </div>
      <Card 
        style={{
          maxHeight: props.height - 56,
          overflow: 'hidden',
        }}
        bodyStyle={{
          padding: '12px',
        }}
      >
        <Row>
          <Col 
            span={isSmallScreen ? 24 : 18} 
            order={0} 
            style={{ 
              height: props.height - 80, 
              overflow: 'auto', 
              position: 'relative',
              border: '1px solid #e8e8e8'
            }}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={loadMore}
              hasMore={!isLoading && hasMore}
              useWindow={false}
            >
              <ConfigProvider locale={ukUA}>
              <List
                className="products-list"
                dataSource={products}
                renderItem={(item: any) => (
                  <Tooltip key={item.id} title={typeOfViewList === 'grid' ? item.ingredients : null}>
                    <CardGrid

                      key={item.id}
                      style={{
                        cursor: 'pointer',
                        width: typeOfViewList === 'grid' ? '33.3%' : '96%',
                        padding: '10px',
                        height: typeOfViewList === 'grid' ? '120px' : 'auto',
                        fontSize: '90%',
                        ...Object.assign(
                          {},
                          item.isInvalid ? { backgroundColor: 'rgba(230,73,74, 0.3)'} : {}
                        ),
                      }}
                      onClick={() => onClickProduct(item)}>
                      <div style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}>
                        <span style={{
                          maxHeight: '80%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                          {item.title}
                          <br />
                          {
                            typeOfViewList !== 'grid' && item.ingredients &&
                            <div>
                              <span style={{fontWeight: 'bold'}}>
                                <Translate value={`orders.children.orderDrawer.children.ingredients.value`} />: 
                              </span>{item.ingredients}
                            </div>
                          }
                        </span>
                        <span style={{
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}>
                          {getProductPrice(item.productOptions)}
                        </span>
                      </div>
                    </CardGrid>
                  </Tooltip>
                )}
              >
              </List>
              </ConfigProvider>
            </InfiniteScroll>
          </Col>
          {isLoading && (
              <div className="demo-loading-container" style={{zIndex: 1000, position: 'absolute', bottom: 0}}>
                <Spin />
              </div>
            )}
          <Col 
            style={{ maxHeight: props.height - 80 }}
            span={isSmallScreen ? 24 : 6} order={1}>
            <Tabs
              className="product-categories-tabs"
              style={{ height: isSmallScreen ? 'auto' : props.height - 80 }}
              tabPosition={isSmallScreen ? 'top' : 'right'}
              onTabClick={handleTabChange}
              activeKey={currCategoryKey.toString()}>
              {
                categories && categories.length > 0 &&
                categories.map((item: any) => (
                  <Tabs.TabPane
                    tab={item.name}
                    key={item.id}>
                  </Tabs.TabPane>
                ))
              }
            </Tabs>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  settings: selectors.getSettings(state),
});
export default compose(
  connect<{}, {}, any>(mapStateToProps),
)(Products);
