import React, { CSSProperties, useContext, FocusEvent, useState, useEffect } from 'react';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Row, Col, Input, Button, Icon, Tooltip, } from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import { FormItemPhone, phoneValidator, PhoneInput } from '../../../Phone';

import { I18n, Translate } from 'react-redux-i18n';

interface ContactModel {
  id?: number;
  key?: string;
  firstName: string;
  phone: string;
  isPhoneCustom: boolean;
}

interface ClientInfoPhoneProps extends FormComponentProps {
  formItemStyle?: CSSProperties;
  getClientByPhone: (phone: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const ClientInfoPhone = (props: ClientInfoPhoneProps) => {
  const { formItemStyle, form, getClientByPhone, setIsLoading } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const phoneItemStyle = { marginBottom: 0, paddingBottom: 0};

  const { createOrder: context } = useContext(CreateOrderContext);
  const { clientInfo } = context;

  const [contacts, setContacts] = useState([] as ContactModel[]);
  useEffect(() => {
    if (clientInfo && clientInfo.contacts) {
      setContacts((contacts) => clientInfo.contacts.concat(contacts.filter((c) => !!c.key)));
    }
  }, [clientInfo]);
  const [contactKeyGen, setContactKeyGen] = useState(contacts.length);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    getClientByPhone(getFieldValue('phone'));
  };

  const handleSwitch = (checked: true) => {
    setFieldsValue({ 'phone': getFieldValue('phone').replace(/\D/g, '') });
  };

  const handleAddButton = () => {
    setContacts([...contacts, {
      key: `key${contactKeyGen}`,
      firstName: '',
      phone: '',
      isPhoneCustom: false,
    }]);
    setContactKeyGen(contactKeyGen + 1);
  };

  const handleDeleteButton = (key?: string | number) => {
    return () => {
      setContacts(contacts.filter((c) => !(c.key === key || c.id === key)));
      setFieldsValue({ [`contacts${key}`]: null});
    };
  };

  return (
    <Row>
      <Row gutter={6} type="flex">
        <Col span={11}>
          <Row type="flex" align="top">
            <FormItemPhone
              form={form} phoneFieldName="phone" isCustomFieldName="isPhoneCustom"
              inits={{
                isPhoneCustom: clientInfo ? clientInfo.isPhoneCustom : false,
                phone: clientInfo ? clientInfo.phone : '',
              }}
              inputProps={{
                onBlur: handleBlur,
              }}
              switchProps={{
                onChange: handleSwitch,
              }}
            />
          </Row>
        </Col>
        <Col span={13}>
          <Form.Item label={I18n.t('form.children.firstName.value')} style={phoneItemStyle}>
            {getFieldDecorator('firstName', {
              initialValue: clientInfo ? clientInfo.firstName : null,
            })(
              <Input />,
            )}
          </Form.Item>
        </Col>
      </Row>
      {contacts.map((contact) => {
        const key = contact.key || contact.id;
        return (
          <Row gutter={6} type="flex" align="top" key={key}>
            <Col span={11}>
              <Row type="flex" align="top">
                <Form.Item style={{...phoneItemStyle, width: '100%'}} className="client-info-contact-form-item">
                  {getFieldDecorator(`contacts.${key}.phone`, {
                    rules: [{ validator: phoneValidator, message: I18n.t('form.children.requiredField.value')}],
                    initialValue: {
                      phone: contact.phone,
                      isPhoneCustom: contact.isPhoneCustom,
                    }
                  })(
                    <PhoneInput />
                  )}
                </Form.Item>
              </Row>
            </Col>
            <Col span={12} style={{paddingTop: '30px'}}>
              <Form.Item style={phoneItemStyle} className="client-info-contact-form-item">
                {getFieldDecorator(`contacts.${key}.firstName`, {
                  initialValue: contact.firstName || '',
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col span={1} style={{ paddingTop: '32px' }}>
              <Tooltip title={I18n.t('form.children.btns.children.removeContact.value')}>
                <Button block type="link" style={{ padding: 0, }} onClick={handleDeleteButton(key)}>
                  <Icon type="minus" />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        );
      })}
      <Row type="flex" justify="end">
        <Button type="link" style={{}} onClick={handleAddButton} size="small">
          <Translate value={`form.children.btns.children.addPhone.value`} />
        </Button>
      </Row>
    </Row>
  );
};

export default ClientInfoPhone;
