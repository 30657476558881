import { notification } from 'antd';
import { DefaultMessages } from '../../constants/norification';
import { I18n } from 'react-redux-i18n';

export const getSuccessNotification = (desc: string = '') => {
  return getNotification('success', undefined, desc);
};

export const getErrorNotification = (e: any, message?: string) => {
  let description;

  if (e.status === 401) {
    return ;
  }
  if (typeof e === 'string') {
    description = e;
  } else if (typeof e === 'object' && e !== null) {
    description = e.message;
  }

  return getNotification('error', message, description);
};

export const getNotification = (type: keyof typeof notification, message?: string, description?: string) => {
  let msg = message;
  if (isValidMessageType(type)) {
    const msgTranslate = I18n.t(`system.children.notifications.children.${type}.value`)
    msg = msg || (msgTranslate && msgTranslate !== 'value' && msgTranslate) || DefaultMessages[type];
  }

  notification[type]({
    message: msg || '',
    description
  } as any);
};

function isValidMessageType(value: string | number | symbol): value is keyof typeof DefaultMessages {
  return value in DefaultMessages;
}
