export const REFRESH = 'REFRESH';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const SET_MARKED_ORDER = 'SET_MARKED_ORDER';
export const CLEAR_MARKED_ORDERS = 'CLEAR_MARKED_ORDERS';

export const SET_SELECTED_COURIER = 'SET_SELECTED_COURIER';
export const SET_IS_SHOW_COURIER_CONFIRM = 'SET_IS_SHOW_COURIER_CONFIRM';
export const SET_IS_HIDE_COURIER_CONFIRM = 'SET_IS_HIDE_COURIER_CONFIRM';
export const CLEAR_SELECTED_COURIER = 'CLEAR_SELECTED_COURIER';

export const SET_SELECTED_PACKAGE = 'SET_SELECTED_PACKAGE';
export const SET_IS_SHOW_PACKAGE_CONFIRM = 'SET_IS_SHOW_PACKAGE_CONFIRM';
export const SET_IS_HIDE_PACKAGE_CONFIRM = 'SET_IS_HIDE_PACKAGE_CONFIRM';
export const CLEAR_SELECTED_PACKAGE = 'CLEAR_SELECTED_PACKAGE';

export const SET_IS_SHOW_DRAWER = 'SET_IS_SHOW_DRAWER';
export const SET_IS_HIDE_DRAWER = 'SET_IS_HIDE_DRAWER';

export const SET_CURRENT_COURIER = 'SET_CURRENT_COURIER';
export const CLEAR_CURRENT_COURIER = 'RESET_CURRENT_COURIER';
