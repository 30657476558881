import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class RolesService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private rolesURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.rolesURL = BASE_URL + 'roles';
  }

  public getRoles(rolesQuery?: any) {
    const query = generateODataQuery(rolesQuery);
    return this.httpService.get(this.rolesURL + query);
  }

  public getRole = (name: string) => {
    const URL = `${this.rolesURL}/${name}`;
    return this.httpService.get(URL);
  }

  public createRole = (body: any) => {
    const URL = this.rolesURL;

    return this.httpService.post(URL, body);
  }

  public updateRole = (name: string, body: any = null) => {
    const URL = `${this.rolesURL}/${name}`;

    return this.httpService.put(URL, body);
  }

  public deleteRole = (name: string) => {
    const URL = `${this.rolesURL}/${name}`;

    return this.httpService.delete(URL);
  }

  public getParams = () => {
    const URL = `${this.rolesURL}/params`;
    return this.httpService.get(URL);
  }
}

export default RolesService;