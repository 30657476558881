export const getFullAddress = (address: any) => {
  if (address) {
      const street = address.street ? `${address.street}` : '';
      const build = address.buildNumber ? `, буд. ${address.buildNumber}` : '';
      const corps = address.corps ? ` корп. ${address.corps}` : '';
      const entrance = address.entrance ? `, п. ${address.entrance}` : '';
      const floor = address.floor ? `, пов. ${address.floor}` : '';
      const premise = address.flatNumber ? `, кв. ${address.flatNumber}` : '';
      const notes = address.notes ? ` (${address.notes})` : '';
      return `${street}${build}${corps}${entrance}${floor}${premise}${notes}`;
  }
  return '';
};

export const getAddress = (address: any) => {
  if (address) {
      const street = address.street ? `${address.street}` : '';
      const build = address.buildNumber ? `, буд. ${address.buildNumber}` : '';
      const corps = address.corps ? ` корп. ${address.corps}` : '';
      const entrance = address.entrance ? `, п. ${address.entrance}` : '';
      const floor = address.floor ? `, пов. ${address.floor}` : '';
      const premise = address.flatNumber ? `, кв. ${address.flatNumber}` : '';
      return `${street}${build}${corps}${entrance}${floor}${premise}`;
  }
  return '';
};