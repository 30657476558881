import React, {FormEvent, useEffect} from 'react';
import {Form, Input} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {addContact, updateContact, setTypeModal} from '../../../redux/client/clientActions';
import {getSelectEditContact} from '../../../redux/client/clientSelector';
import { formLayoutDefaults } from '../../../constants/ModalFormConstants';
import { TypeModal } from '../../../constants/typeModal';
import { PhoneInput } from '../../Phone';
import { I18n } from 'react-redux-i18n';

const ContactModal = ({form, contactIndex}: any) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const dispatch = useDispatch();
  const contact = useSelector(getSelectEditContact(contactIndex));

  useEffect(() => {
    if (contact) {
      setFieldsValue({
        ...contact,
        phone: {
          isPhoneCustom: contact.isPhoneCustom,
          phone: contact.phone,
        }
      });
    }
  }, [contact, setFieldsValue]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    validateFields((err: any, values: any) => {
      if (!err) {
        const contactObj = {
          firstName: values.firstName || null,
          lastName: values.lastName || null,
          phone: values.phone.phone.replace(/\D/g, '') || null,
          isPhoneCustom: values.phone.isPhoneCustom || false,
        };
        if (contactIndex !== null) {
          dispatch(updateContact(contactIndex, contactObj));
        } else {
          dispatch(addContact(contactObj));
        }
        dispatch(setTypeModal(TypeModal.None, null));
      }
    });
  };

  return (
      <Form onSubmit={handleSubmit} {...formLayoutDefaults} id="modal-form" className="modal-form">
        <Form.Item label={I18n.t('clients.children.clientsTable.children.firstName.value')}>
          {getFieldDecorator('firstName', {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={I18n.t('clients.children.clientsTable.children.surname.value')}>
          {getFieldDecorator('lastName', {
            initialValue: ''
          })(<Input />)}
        </Form.Item>
        <Form.Item label={I18n.t('clients.children.clientsTable.children.phone.value')}>
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
          })(
            <PhoneInput />
          )}
        </Form.Item>
      </Form>
    );
};

export default Form.create<any>({
  name: 'contact-modal-form',
})(ContactModal);
