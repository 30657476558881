import fetchIntercept from 'fetch-intercept';

export function callInterceptor() {
  fetchIntercept.register({
    request: function (url, config) {
      let id_token = localStorage.getItem('persist:session') ? JSON.parse(localStorage.getItem('persist:session')).token : null;
      if(id_token && id_token !== 'null') {
        id_token = JSON.parse(id_token);
        if (config.headers) {
          Object.assign(config.headers, config.headers, {
            'Authorization': 'Bearer ' + id_token.access_token,
          });
        } else {
          config.headers = {
            'Authorization': 'Bearer ' + id_token.access_token,
          };
        }
      }

      return [url, config];
    },

    //todo
    responseError: function (error) {
      if(error.message === 'Failed to fetch') {
        window.location = window.location.origin + '/login';
        return null;
      }
      return Promise.reject(error);
    },

    response: function (response) {
      if (response.status === 401 || (response.status === 500 && response.message === 'Token expired')) {
        localStorage.removeItem(`persist:session`);
        window.location = window.location.origin + '/login';
        throw new Error({
          status: response.status
        });
      } else {
        return response;
      }
    },
  });
}