import React, {FormEvent, useEffect} from 'react';
import {Button, Card, Col, Form, Input, Modal, Row, Spin, Table} from 'antd';
import {getErrorNotification, getSuccessNotification} from '../../Notifications/Notifications';
import ClientsService from '../../../services/ClientsService';
import {ClientModel} from '../../../models/ClientModel';
import {useDispatch, useSelector} from 'react-redux';
import css from './ClientForm.module.css';
import {getAddressColumns} from '../../../helpers/getAddressColumns';
import {TypeModal} from '../../../constants/typeModal';
import {fetchClient} from '../../../redux/client/clientOperations';
import {getClient, getLoadingClient, getTypeModal, getIndexEditContact, getIndexEditAddress} from '../../../redux/client/clientSelector';
import { deleteAddress, deleteContact, selectEditAddress, selectEditContact, setTypeModal } from '../../../redux/client/clientActions';
import ContactModal from './ContactModal';
import AddressContentModal from './AddressContentModal';
import Controls from '../../Controls/Controls';
import { clientContactsControls, clientAddressControls } from './enums/ClientControls';
import { contactsColumns } from './enums/ClientsColumns';
import { PhoneInput, phoneValidator } from '../../Phone';
import { I18n, Translate } from 'react-redux-i18n';

const service = new ClientsService();

const ClientForm = (props: any) => {
  const dispatch = useDispatch();
  const clientId = props.match.params.id;
  const { getFieldDecorator, validateFields } = props.form;

  let client = useSelector(getClient);
  const isLoading = useSelector(getLoadingClient);
  const typeModal = useSelector(getTypeModal);
  const selectedContactIndex = useSelector(getIndexEditContact);
  const selectedAddressIndex = useSelector(getIndexEditAddress);
  const handleCancelModal = () => {
      dispatch(setTypeModal(TypeModal.None, null));
  };

  const handleRow = (selected: number | null, selectRow: (index: number | null) => void) => 
  (record: any, index: number) => ({
    onClick: () => {
      if (selected === index) {
        dispatch(selectRow(null));

      } else {
        dispatch(selectRow(index));
      } 
    }
  });

  useEffect(() => {
    if (clientId) {
      dispatch(fetchClient(clientId));
    }
  }, [clientId, dispatch]);

  const getModalTitle = () => {
    if (typeModal.id) {
      if(typeModal.type === TypeModal.Contacts) {
        return I18n.t('clients.children.clientsTable.children.editContact.value');
      }
      return I18n.t('clients.children.clientsTable.children.editAddress.value');
    } else {
      if(typeModal.type === TypeModal.Contacts) {
        return I18n.t('clients.children.clientsTable.children.addContact.value');
      }
      return I18n.t('clients.children.clientsTable.children.addAddress.value');
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    validateFields((err: any, values: ClientModel) => {

      if (!err) {
        handleClient(values);
      }
    });
  };

  const handleClient = async (values: any) => {
    const successMsg = clientId ? 'Клієнт оновлений' : 'Створено нового клієнта';

    try {
      const data = {
        ...values,
        lastName: values.lastName || null,
        email: values.email || null,
        phone: values.phone.phone.replace(/\D/g, ''),
        isPhoneCustom: values.phone.isPhoneCustom,
        contacts: client.contacts,
        addresses: client.addresses,
      };
      if (clientId) {
        await service.updateClient(clientId, JSON.stringify(data));
      } else {
        await service.createClient(JSON.stringify(data));
      }
      getSuccessNotification(successMsg);
      props.history.push('/clients');
    } catch (e) {
      getErrorNotification(e);
    }
  };
  return (
    <Form  onSubmit={handleSubmit}>
      <Modal
        destroyOnClose
        maskClosable={false}
        visible={TypeModal.None !== typeModal.type}
        title={getModalTitle()}
        onCancel={handleCancelModal}
        footer={[
          <Button key="back" onClick={handleCancelModal}>
            <Translate value="form.children.btns.children.cancel.value"/>
          </Button>,
          <Button key="submit" type="primary" htmlType="submit" form="modal-form">
            <Translate value="form.children.btns.children.save.value"/>
          </Button>,
        ]}
      >
        {typeModal.type === TypeModal.Contacts && <ContactModal contactIndex={typeModal.id}/>}
        {typeModal.type === TypeModal.Address && <AddressContentModal addressIndex={typeModal.id}/>}
      </Modal>
      <Spin spinning={isLoading}>
        <Row >
          <Col span={10}>
            <Card 
              title={I18n.t('clients.children.clientsTable.children.info.value')} bordered={false}
              extra={
                <Row>
                  <Button style={{ marginRight: '8px'}} onClick={() => props.history.goBack()}>
                    <Translate value="form.children.btns.children.cancel.value"/>
                  </Button>
                  <Button type="primary" htmlType="submit" id="form-submit-btn">
                    <Translate value="form.children.btns.children.save.value"/>
                  </Button>
                </Row>
              }
            >
              <Form.Item label={I18n.t('clients.children.clientsTable.children.firstName.value')} {...layout} className={css.formItem} >
                {getFieldDecorator('firstName', {
                  rules: [{required: true, message: I18n.t('form.children.requiredField.value')}],
                  initialValue: client.firstName,
                })(<Input/>)}
              </Form.Item>
              <Form.Item label={I18n.t('clients.children.clientsTable.children.surname.value')} {...layout} className={css.formItem}>
                {getFieldDecorator('lastName', {
                  initialValue: client.lastName,
                })(<Input />)}
              </Form.Item>
              <Form.Item label={I18n.t('clients.children.clientsTable.children.phone.value')} {...layout} className={css.formItem}>
                {getFieldDecorator('phone', {
                  rules: [
                    { required: true, message: I18n.t('form.children.requiredField.value') },
                    { validator: phoneValidator, message: I18n.t('form.children.requiredField.value') }
                  ],
                  initialValue: {
                    isPhoneCustom: client.isPhoneCustom,
                    phone: client.phone
                  },
                })(
                    <PhoneInput />
                )}
              </Form.Item>
              <Form.Item label={I18n.t('clients.children.clientsTable.children.email.value')} {...layout} className={css.formItem}>
                {getFieldDecorator('email', {
                  rules: [{ type: 'email', message: 'Некоректна адреса електронної пошти!' }],
                  initialValue: client.email,
                })(<Input />)}
              </Form.Item>
            </Card>
          </Col>
          <Col span={14}>
            <Card 
              title={I18n.t('clients.children.clientsTable.children.contacts.value')}
              bordered={false}
              extra={
                <Row>
                  <Controls 
                    controls={clientContactsControls({
                      openContactModal: (index: number | null) => {
                        dispatch(setTypeModal(TypeModal.Contacts, index));
                      },
                      deleteContact: () => dispatch(deleteContact(selectedContactIndex)),
                      current: selectedContactIndex,
                    })} 
                  />
                </Row>
              }
            >
              <Table
                onRow={handleRow(selectedContactIndex, selectEditContact)}
                dataSource={client.contacts}
                columns={contactsColumns()}
                pagination={false}
                rowClassName={(record, index) => index === selectedContactIndex ? 'selected-row' : ''}
              />
            </Card>
          </Col>
        </Row>
        <Row className={css.row}>
          <Card title={I18n.t('clients.children.clientsTable.children.adresses.value')}
                bordered={false}
                extra={
                  <Row>
                    <Controls 
                      controls={clientAddressControls({
                        openAddressModal: (index: number | null) => {
                          dispatch(setTypeModal(TypeModal.Address, index));
                        },
                        deleteAddress: () => dispatch(deleteAddress(selectedAddressIndex)),
                        current: selectedAddressIndex,
                      })} 
                    />
                </Row>
                }
          >
            <Table
              onRow={handleRow(selectedAddressIndex, selectEditAddress)}
              dataSource={client.addresses}
              columns={getAddressColumns(I18n)}
              pagination={false}
              rowClassName={(record, index) => index === selectedAddressIndex ? 'selected-row' : ''}
            />
          </Card>
          <Card 
            title={I18n.t('clients.children.clientsTable.children.certificates.value')}
            bordered={false}>
          </Card>
        </Row>

      </Spin>
    </Form>
  );
};

export default Form.create<any>({ name: 'client-form' })(ClientForm);
