import {
    SET_IS_SHOW_CREATE_ORDER_DRAWER,
    SET_IS_HIDE_CREATE_ORDER_DRAWER,
} from '../constants/main';

export const initialState = {
    isShowCreateOrder: false,
    orderStatuses: [],
    deliveryStatuses: [],
    deliveryTypes: [],
    paymentTypes: [],
};

const mainReducer = (
    state: any,
    {
        type,
    }: any
) => {
    switch (type) {
        case SET_IS_SHOW_CREATE_ORDER_DRAWER: return {isShowCreateOrder: true};
        case SET_IS_HIDE_CREATE_ORDER_DRAWER: return {isShowCreateOrder: false};
        default: return initialState;
    }
};

export {mainReducer};