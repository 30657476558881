import {IStateClient} from './clientReducer';

const getState = (state: any) => state.clientPage as IStateClient;

export const getClient = (state: any) => getState(state).client.data;
export const getLoadingClient = (state: any) => getState(state).client.isLoading;

export const getTypeModal = (state: any) => getState(state).typeModal;

export const getIndexEditAddress = (state: any) => getState(state).editAddress;
export const getIndexEditContact = (state: any) => getState(state).editContact;

export const getSelectEditAddress = (i: number) => (state: any) => {
  const s = getState(state);
  if (i !== null && i !== undefined) {
    return s.client.data.addresses.find((a: any, index ) => index === i);
  }
  return null;
};

export const getSelectEditContact = (i: number) => (state: any) => {
  const s = getState(state);
  if (i !== null && i !== undefined) {
    return s.client.data.contacts.find((a: any, index ) => index === i);
  }
  return null;
};
