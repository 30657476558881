import React, {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addAddress, updateAddress, setTypeModal} from '../../../redux/client/clientActions';
import {AutoComplete, Col, Form, Input, Row} from 'antd';
import {getSelectEditAddress} from '../../../redux/client/clientSelector';
import DictionaryService from '../../../services/DictionaryService';
import {getErrorNotification} from '../../Notifications/Notifications';
import { TypeModal } from '../../../constants/typeModal';
import { I18n } from 'react-redux-i18n';

const dictionaryService = new DictionaryService();

const AddressContentModal = ({form, addressIndex}: any) => {
  const {getFieldDecorator, validateFields, setFieldsValue} = form;
  const dispatch = useDispatch();
  const address = useSelector(getSelectEditAddress(addressIndex));
  const [addressesDictionary, setAddressesDictionary] = useState([] as any[]);

  useEffect(() => {
    setFieldsValue(address);
  }, [address]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    validateFields((err: any, values: any) => {
      if (!err) {
        const findAddress = addressesDictionary.find((item: any) => item.id === Number(values.street));
        const addressObj = {
          ...values,
          street: findAddress ? findAddress.street : values.street,
          district: findAddress ? findAddress.district : null,

        };
        for (let prop in addressObj) {
          if (addressObj.hasOwnProperty(prop)) {
            addressObj[prop] = addressObj[prop] || null;
          }
        }

        if (addressIndex !== null) {
          dispatch(updateAddress(addressIndex, addressObj));
        } else {
          dispatch(addAddress(addressObj));
        }
        dispatch(setTypeModal(TypeModal.None, null));
      }
    });
  };

  const handleSelect = (value: any) => {
    const address = addressesDictionary.find((item: any) => item.id === Number(value));
    if (address) {
      setFieldsValue({district: address.district});
    }
  };

  const searchAddress = (text: string) => {
    if (!text) {
      setAddressesDictionary([]);
    } else {
      const filter = {'tolower(street)': {contains: encodeURIComponent(text.toLowerCase()) }};
      getAddresses(filter);
    }
  };

  const getAddresses = (filter?: any) => {
    dictionaryService.getAddresses(filter)
      .then((res: any) => {
        setAddressesDictionary(res);
      })
      .catch((e) => {
        getErrorNotification(e);
      });
  };

  const renderAddress = addressesDictionary.map((item: any) => (
    <AutoComplete.Option key={item.id} value={item.id.toString()}>
      {item.street}
    </AutoComplete.Option>
  ));

  return (
    <Form onSubmit={handleSubmit} id="modal-form">
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.street.value')}>
            {getFieldDecorator('street', {
              rules: [{required: true, message: I18n.t('form.children.requiredField.value') }],
            })(
              <AutoComplete
                dataSource={renderAddress}
                dropdownMatchSelectWidth={false}
                optionLabelProp="children"
                onSearch={searchAddress}
                onSelect={handleSelect}/>,
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.district.value')}>
            {getFieldDecorator('district', {
            })(<Input disabled/>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.building.value')}>
            {getFieldDecorator('buildNumber', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
            })(<Input/>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.corps.value')}>
            {getFieldDecorator('corps', {
            })(<Input/>)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.flat.value')}>
            {getFieldDecorator('flatNumber', {
            })(<Input/>)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.floor.value')}>
            {getFieldDecorator('floor', {
            })(<Input/>)}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={I18n.t('form.children.addressDetails.children.entrance.value')}>
            {getFieldDecorator('entrance', {
            })(<Input/>)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create<any>({
  name: 'address-modal-form'
})(AddressContentModal);
