import { IStore } from '../store';

export const getIsAuthenticated = (state: IStore) =>
  state.session.authenticated;

export const getToken = (state: IStore) => state.session.token;

export const getUser = (state: IStore) => state.session.user;

export const getSettings = (state: IStore) => state.session.settings;

// get available languages
export const getLanguages = (state: IStore) => state.session.languages;
// get translated object
export const getLanguage = (state: IStore) => state.session.language;
// get current language code
export const getLangCode = (state: IStore) => state.session.langCode;

export const getChangePassword = (state: IStore) =>
  state.session.changePassword;
export const getError = (state: IStore) => state.session.error;
