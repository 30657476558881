import React, {useState, useEffect} from 'react';
import UserMenuComponent from '../../../user-menu/user-menu';
import logo from '../../../../assets/images/logo-dark.svg';
import icon from '../../../../assets/images/icon-profile-5.jpg';
import {Layout, Dropdown, Select} from 'antd';
import css from './Header.module.css';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/store';
import { getUser, getLanguages } from '../../../../redux/session/sessionSelectors';
import { getLanguage } from '../../../../redux/session/sessionOperations';

import { useCookies } from 'react-cookie';
import { Translate } from 'react-redux-i18n';

const { Header } = Layout;
const { Option } = Select;

const HeaderContent = (props: any) => {
  const lang: any | string = process.env.REACT_APP_LANGUAGE_COOKIE;

  const { userFirstName, languages, getLanguage } = props;
  const [userOptionsVisible, setUserOptionsVisible] = useState(false);

  const [cookies, setCookie] = useCookies([lang]);
  const [langCode, setLangCode] = useState(cookies[lang] || process.env.REACT_APP_DEFAULT_LANGUAGE);

  function toggleUserOptions() {
    setUserOptionsVisible(!userOptionsVisible);
  }

  useEffect(() => {
    const storedLangCode = cookies[lang];
    if (langCode !== storedLangCode) {
      setCookie(lang, langCode, { path: '/' });
      setLangCode(langCode);
    }
    getLanguage(langCode);
  }, [langCode]);

  const handleLangChange = (code: any) => {
    setLangCode(code);
  };

  return (
    <Header className={css.header}>
      <img src={logo} alt="logo" className={css.logo} />
      <div className={css.userOptions}>
        {userFirstName &&
          <span style={{marginRight: 10, fontWeight: 'bold'}}>
            <Translate value={'system.children.welcome.value'} />{`, ${userFirstName}`}
          </span>
        }
        <Dropdown overlay={<UserMenuComponent />} trigger={['click']} overlayClassName={css.dropdownOptions}>
          <img src={icon} alt="icon" className={css.userIcon} onClick={toggleUserOptions} />
        </Dropdown>

        <Select value={langCode} style={{ width: 100, marginLeft: 10 }} onChange={handleLangChange}>
          {
            languages && languages.map((language: any, index: number) => (<Option value={language.code} key={index}>{language.name}</Option>))
          }
        </Select>

      </div>
    </Header>
  );
};

const mapDispatchToProps: any = {
  getLanguage,
};

const mapStateToProps: any = (state: IStore) => {
  const user = getUser(state);
  const languages = getLanguages(state);
  return { userFirstName: (user && user.firstName) || null, languages };
};

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(HeaderContent);
