import HttpService from './common/httpService';
import genODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class DictionaryService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getDictionary = (dictionaryName: string) => {
    return this.httpService.get(`${BASE_URL}${dictionaryName}`);
  }

  public getDeliveryStatuses = () => {
    return this.httpService.get(`${BASE_URL}delivery_statuses`);
  }

  public getDeliveryPrices = () => {
    return this.httpService.get(`${BASE_URL}delivery_prices`);
  }

  public getPaymentTypes = () => {
    return this.httpService.get(`${BASE_URL}payment_types`);
  }

  public getDeliveryTypes = () => {
    return this.httpService.get(`${BASE_URL}delivery_types`);
  }

  public getSellPoints = () => {
    const URL = BASE_URL + `sell_points?$filter=isActive eq true`;
    return this.httpService.get(URL);
  }

  public getAddresses = (filter?: any) => {
    const query = genODataQuery({filter});
    return this.httpService.get(`${BASE_URL}dictionary/addresses${query}`);
  }

  public getUnits = () => {
    const URL = BASE_URL + 'units';

    return this.httpService.get(URL);
  }
}

export default DictionaryService;