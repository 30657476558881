import { useState, useEffect } from 'react';

export const useTableSelection = (key: string = 'id', multiple: boolean = false) => {
  const [selected, setSelected] = useState(null as any);

  const [multipleMode, setMultipleMode] = useState(multiple);
  useEffect(() => {
    if (multipleMode) {
      setSelected([]);
    } else {
      setSelected(null);
    }
  }, [multipleMode]);

  const handleRow  = (record: any) => ({
    onClick: (e: React.MouseEvent) => {
      if (multipleMode) {
        if (selected.length && selected.includes(record[key])) {
          setSelected(selected.filter((select: string | number) => select !== record[key]));
        } else {
          setSelected([...selected, record[key]]);
        }
      } else {
        if (selected && selected[key] === record[key]) {
          setSelected(null);
        } else {
          setSelected(record);
        }
      }
    }
  });

  const handleSelectionAfterUpdate = (items: any[]) => {
    if (selected && !multipleMode) {
      const newSelected = items.find((item) => item[key] === selected[key]);
      if (newSelected) {
        setSelected(newSelected);
      } else {
        setSelected(null);
      }
    }
  };

  return { selected, handleRow, setSelected, handleSelectionAfterUpdate, setMultipleMode };
};