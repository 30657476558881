export enum OrderStatuses {
    new = 'new',
    confirm = 'confirm',
    inprogress = 'inprogress',
    ready = 'ready',
    courier = 'courier',
    ontheway = 'ontheway',
    done = 'done',
    reject = 'reject'
}
