import ReactDOM from 'react-dom';
import { useState } from 'react';

export const useFiltersPanelHeight = (initialHeight?: number) => {
  const [panelHeight, setPanelHeight] = useState(initialHeight || 0);

  const getPanelRef = (ref: React.Component<any, {}, any> | Element | null | undefined) => {
    const panel = ReactDOM.findDOMNode(ref) as Element;
    if (panel) {
      const styles = getComputedStyle(panel);
      const newHeight = styles.height ? parseInt(styles.height, 10) : null;
      if (newHeight && newHeight !== panelHeight) {
        setPanelHeight(newHeight);
      }
    }
  };

  return { panelHeight, getPanelRef };
};