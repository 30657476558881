import React from 'react';
import DictionaryContextProvider from './DictionaryContextProvider';

export const withDictionaryContext = (Component: any) => {
  return ((props: any) => {
    return (
      <DictionaryContextProvider>
        <Component {...props} />
      </DictionaryContextProvider>
    );
  });
};