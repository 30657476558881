import React from 'react';
import { compose } from 'redux';
import { Button, Form, Input, Card, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form/Form';
import { changePassword } from '../../../redux/session/sessionOperations';
import { connect } from 'react-redux';
import { IStore } from '../../../redux/store';
import * as selectors from '../../../redux/session/sessionSelectors';
import css from './ChangePasswordForm.module.css';
import { I18n } from 'react-redux-i18n';

interface IPropsChangeForm extends FormComponentProps {
  changePassword: (username: string, pass: string) => void;
  user: any;
}

class ChangePasswordForm extends React.Component<IPropsChangeForm, any> {
  public state = {
    confirmDirty: false,
  };
  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      form,
      changePassword,
    } = this.props;
    const username = this.props.user && this.props.user.username;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        changePassword(username, values.password);
      }
    });
  }

  public handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  public compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Паролі повинні співпадати!');
    } else {
      callback();
    }
  }

  public validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  public render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const username = this.props.user && this.props.user.username;

    return (
      <Row className={css.wrp}>
        <Card 
          title={<strong style={{ color: 'rgba(0,0,0,0.65)'}}>{username}</strong>}
          style={{ width: '330px' }}
        >
          <Form onSubmit={this.handleSubmit}>
            <Form.Item hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: I18n.t('form.children.requiredField.value'),
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password placeholder="Пароль" />)}
            </Form.Item>
            <Form.Item hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: I18n.t('form.children.requiredField.value'),
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={this.handleConfirmBlur} placeholder="Підтвердіть пароль"/>)}
            </Form.Item>
            <Form.Item>
              <Button className={css.submitBtn} type="primary" htmlType="submit">
                Змінити пароль
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  }
}

const mapDispatchToProps: any = {
  changePassword,
};
const mapStateToProps: any = (state: IStore) => ({
  user: selectors.getUser(state),
});
export default compose(
  Form.create({
    name: 'changePassword',
  }),
  connect<{}, {}, any>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ChangePasswordForm);
