// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientForm_container__7yLB4 {\n  height: 100%;\n}\n\n.ClientForm_row__3_53B {\n  margin-bottom: 10px;\n}\n\n.ClientForm_heading__3VAv5 {\n  margin-left: 1em;\n}\n\n.ClientForm_collapseHeader__ryRA1 {\n  display: inline-block;\n  margin-bottom: 0;\n}\n\n.ClientForm_createBtn__pafKF:hover {\n  color: inherit;\n  border-color: inherit;\n}\n\n.ClientForm_createBtn__pafKF:focus {\n  color: inherit;\n  border-color: inherit;\n}\n\n.ClientForm_editableRow__2fMZ6 .ClientForm_ant-form-item__3PXDb {\n  margin-bottom: 0;\n}\n\n.ClientForm_formItem__1pEVi {\n  margin-bottom: 8px\n}\n\n.ClientForm_blockActions__11-JW {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"container": "ClientForm_container__7yLB4",
	"row": "ClientForm_row__3_53B",
	"heading": "ClientForm_heading__3VAv5",
	"collapseHeader": "ClientForm_collapseHeader__ryRA1",
	"createBtn": "ClientForm_createBtn__pafKF",
	"editableRow": "ClientForm_editableRow__2fMZ6",
	"ant-form-item": "ClientForm_ant-form-item__3PXDb",
	"formItem": "ClientForm_formItem__1pEVi",
	"blockActions": "ClientForm_blockActions__11-JW"
};
module.exports = exports;
