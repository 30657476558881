// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CourierMoneyCell_cellContainer__3mIsQ {\n  position: relative;\n  text-align: center;\n}\n\n.CourierMoneyCell_popoverContainer__2lstS {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.CourierMoneyCell_moneyText__3408R:hover {\n  color: rgb(31, 189, 238);\n  cursor: pointer;\n}\n\n.CourierMoneyCell_input__3vbZw {\n  width: 100px;\n  margin-bottom: 4px;\n}\n\n.CourierMoneyCell_buttonsContainer__2OZL8 {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.CourierMoneyCell_button__1ySgr {\n  font-size: 12px;\n  margin-left: 4px;\n}\n\n.CourierMoneyCell_addIcon__3kxIe {\n  position: absolute;\n  right: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  cursor: pointer;\n}\n\n.CourierMoneyCell_addIcon__3kxIe:hover {\n  color: rgb(31, 189, 238);\n}", ""]);
// Exports
exports.locals = {
	"cellContainer": "CourierMoneyCell_cellContainer__3mIsQ",
	"popoverContainer": "CourierMoneyCell_popoverContainer__2lstS",
	"moneyText": "CourierMoneyCell_moneyText__3408R",
	"input": "CourierMoneyCell_input__3vbZw",
	"buttonsContainer": "CourierMoneyCell_buttonsContainer__2OZL8",
	"button": "CourierMoneyCell_button__1ySgr",
	"addIcon": "CourierMoneyCell_addIcon__3kxIe"
};
module.exports = exports;
