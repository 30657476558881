import React from 'react';
import simpleFish from '../../assets/images/simple-fish.png';
import { Empty, Button, Row } from 'antd';

const style = {
  opacity: '0.4',
};

const EmptyData = (props: any) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img
        src={simpleFish}
        style={{ ...style, width: 100 }}
        alt="Дані відсутні"
        title="Дані відсутні"
      />
      <h3 style={style}>Дані відсутні</h3>
    </div>
  );
};

export const NotFoundPage = ({ history }: any) => {
  const handleBackButton = () => {
    history.goBack();
  };

  return (
    <Row style={{ paddingTop: '10%' }}>
      <Empty
        imageStyle={style}
        description={
          <Row>
            <h1 style={{ ...style, fontSize: '28px' }}>404</h1>
            <h1 style={{ ...style, fontSize: '20px' }}>Сторінку не знайдено</h1>
          </Row>
        }
      >
        <Button type="primary" onClick={handleBackButton}>Повернутися</Button>
      </Empty>
    </Row>
  );
};

export default EmptyData;