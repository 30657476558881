
import { getAddButtonDefaults, getUpdateButtonDefaults, getRefreshButtonDefaults, ControlModel } from '../../../Controls/Controls';
import { CategoryModel } from '../../../../models/CategoryModel';

export interface CategoryControlsProps {
  current?: CategoryModel | null;
  openForm: (id?: number) => void;
  refresh: () => void;
}

export const categoryControls = ({openForm, current, refresh}: CategoryControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      onClick: () => openForm(),
    },
    {
      ...getUpdateButtonDefaults(),
      disabled: !current,
      onClick: () => current ? openForm(current.id) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};