interface Obj {
  [key: string]: any;
}

export function isEquivalent(a: Obj, b: Obj) {
  // Create arrays of property names
  const aProps = Object.keys(a);
  const bProps = Object.keys(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
      return false;
  }

  for (let i = 0; i < aProps.length; i++) {
      let propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
          return false;
      }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}