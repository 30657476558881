import React from 'react';
import CreateOrderContextProvider from './CreateOrderContextProvider';

export const withCreateOrderContext = (Component: any) => {
  return ((props: any) => {
    return (
      <CreateOrderContextProvider>
        <Component {...props} />
      </CreateOrderContextProvider>
    );
  });
};