import React, { useContext } from 'react';
import {
  Descriptions,
  Button,
  Icon,
  Row,
  Col,
} from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import { getFullAddress } from '../../../../helpers/getAddress';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/formats';
import { DictionaryContext } from '../../../../contexts/DictionaryContextProvider';
import { SET_ORDER_INFO } from '../../../../constants/createOrder';
import EditableSelect from '../../../EditableItems/EditableSelect';
import { DeliveryTypeModel } from '../../../../models/DeliveryTypeModel';
import { PaymentTypeModel } from '../../../../models/PaymentTypeModel';
import { DeliveryPriceModel } from '../../../../models/DeliveryPriceModel';
import { SellPointModel } from '../../../../models/SellPointModel';
import EditableTextArea from '../../../EditableItems/EditableTextArea';
import { connect } from 'react-redux';
import { getUser } from '../../../../redux/session/sessionSelectors';
import { IStore } from '../../../../redux/store';
import { DEFAULT_DELIVERY_TYPE } from '../../../../constants/SystemParamsVariables';
import { Translate, I18n } from 'react-redux-i18n';

const DescLabel = ({ text }: { text: string }) => <span style={{ fontWeight: 'bold' }}>{text}</span>;

const ClientInfoDescription: React.FC<any> = React.forwardRef((props: any, ref: any) => {
  const { defaultDeliveryType, defaultSellPoint } = props;
  const { createOrder, createOrderDispatch } = useContext(CreateOrderContext);
  const { dictionary } = useContext(DictionaryContext);
  const clientInfo = createOrder ? createOrder.clientInfo || {} : {};
  const orderInfo = createOrder ? createOrder.orderInfo || {} : {};
  const { timeFrom, timeTo, date, sellPoint, deliveryType, deliveryPrice, paymentType,
    comment, deliveryPackage, createdUser } = orderInfo;

  const courier = deliveryType && deliveryType.code === 'courier';

  const getClientAddress = () => {
    const address = clientInfo && clientInfo.address ? clientInfo.address : undefined;
    return getFullAddress(address) || orderInfo.orderAddress;
  };

  const getDeliveryDate = () => {
    if (timeFrom && timeTo) {
      return `${date.format(DATE_FORMAT)} ${timeFrom.format(TIME_FORMAT)} - ${timeTo.format(TIME_FORMAT)}`;
    }
    return '';
  };

  const setDeliveryType = (deliveryType: DeliveryTypeModel) => {
    const deliveryPrice = deliveryType.code === 'courier' ? orderInfo.deliveryPrice : null;
    createOrderDispatch({
      type: SET_ORDER_INFO,
      orderInfo: {
        ...orderInfo,
        deliveryType,
        deliveryPrice,
      }
    });
  };

  const setPaymentType = (paymentType: PaymentTypeModel) => {
    createOrderDispatch({
      type: SET_ORDER_INFO,
      orderInfo: {
        ...orderInfo,
        paymentType
      }
    });
  };

  const setDeliveryPrice = (deliveryPrice: DeliveryPriceModel) => {
    createOrderDispatch({
      type: SET_ORDER_INFO,
      orderInfo: {
        ...orderInfo,
        deliveryPrice
      }
    });
  };

  const setSellPoint = (sellPoint: SellPointModel) => {
    createOrderDispatch({
      type: SET_ORDER_INFO,
      orderInfo: {
        ...orderInfo,
        sellPoint
      }
    });
  };

  const setOrderComment = (comment: string) => {
    createOrderDispatch({
      type: SET_ORDER_INFO,
      orderInfo: {
        ...orderInfo,
        comment
      }
    });
  };

  return (
    <>
      <Descriptions
        className="client-info-descriptions"
        ref={ref}
        title={(
          <Row type="flex" justify="end" align="middle" style={{ flexWrap: 'nowrap' }}>
            <Col style={{ marginRight: 'auto' }}>
              <Translate value={`orders.children.orderDrawer.children.order.value`} /> {createOrder.id ? `#${createOrder.id}` : null}
            </Col>
            {createdUser && 
              <Col style={{ 
                margin: '0 10px 0 auto',
                color: '#c7c8d2',
                fontWeight: 'normal',
              }}>
                <Translate value={`orders.children.orderDrawer.children.createdBy.value`} /> {createdUser.lastName} {createdUser.firstName}
              </Col>
            }
            <Button
              type="primary"
              style={{ marginRight: '10px' }}
              onClick={props.showClientInfo}>
              <Icon type="edit" />
            </Button>
          </Row>)}
        size="small"
        column={12}>
        <Descriptions.Item 
          label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.phone.value')} />} 
          span={4}>
            {clientInfo.phone}
          </Descriptions.Item>
        <Descriptions.Item 
          label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.firstName.value')} />} 
          span={4}>
            {clientInfo.firstName}
        </Descriptions.Item>
        {
          date && timeFrom && timeTo &&
          <Descriptions.Item 
            label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.time.value')} />} span={4}>
              {getDeliveryDate()}
          </Descriptions.Item>
        }
        {courier &&
          <Descriptions.Item 
            label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.address.value')} />} span={12}>
            {courier ? getClientAddress() : ''}
          </Descriptions.Item>
        }
        <Descriptions.Item 
          label={<DescLabel text={I18n.t('orders.children.ordersTable.children.deliveryType.value')} />} span={6}>
          {
            defaultDeliveryType ? 
              <span>{deliveryType && deliveryType.type}</span> :
              <EditableSelect
                value={deliveryType && deliveryType.id}
                textValue={I18n.t(`form.children.deliveryType.children.${deliveryType && deliveryType.code}.value`)}
                options={dictionary.deliveryTypes}
                langKey="form.children.deliveryType.children"
                setValue={setDeliveryType}
              /> 
          }
        </Descriptions.Item>
        <Descriptions.Item 
          label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.paymentType.value')} />} span={6}>
          <EditableSelect
            value={paymentType && paymentType.id}
            textValue={I18n.t(`form.children.paymentType.children.${paymentType && paymentType.code}.value`)}
            options={dictionary.paymentTypes}
            langKey="form.children.paymentType.children"
            setValue={setPaymentType}
            style={{ minWidth: '150px' }}
          />
        </Descriptions.Item>
        {
          courier && 
          <Descriptions.Item 
            label={<DescLabel text={I18n.t('orders.children.orderDrawer.children.deliveryPrice.value')} />} span={6}>
            <EditableSelect
              value={deliveryPrice ? deliveryPrice.id : 1}
              textValue={deliveryPrice && `${deliveryPrice.price} - ${deliveryPrice.description}`}
              options={dictionary.deliveryPrices}
              setValue={setDeliveryPrice}
              keyField={['price', 'description']}
              style={{ minWidth: '150px' }}
            />
          </Descriptions.Item>
        }
        <Descriptions.Item 
          label={<DescLabel text={I18n.t('orders.children.ordersTable.children.salePoint.value')} />} span={6}>
          {
            defaultSellPoint ?
            <span>{sellPoint && sellPoint.name}</span> :
            <EditableSelect
              value={sellPoint && sellPoint.id}
              textValue={sellPoint && sellPoint.name}
              options={dictionary.sellPoints}
              setValue={setSellPoint}
              keyField="name"
            /> 
          }
        </Descriptions.Item>
        {deliveryPackage && deliveryPackage.courier &&
          <Descriptions.Item 
            label={<DescLabel text={I18n.t('orders.children.ordersTable.children.courier.value')} />} span={6}>
            {deliveryPackage.courier.firstName} {deliveryPackage.courier.lastName}
          </Descriptions.Item>
        }
      </Descriptions>
      <Row style={{
        paddingBottom: '6px'
      }}>
        <span style={{
          color: 'rgba(0,0,0,0.85)',
          fontWeight: 'bold',
        }}><Translate value={`orders.children.orderDrawer.children.comment.value`} />: </span>
        <span>
          <EditableTextArea
            maxLength={170}
            value={comment}
            setValue={setOrderComment}
          />
        </span>
      </Row>
    </>
  );
});

const mapStateToProps: any = (state: IStore) => {
  const user = getUser(state);
  return {
    defaultDeliveryType: user && user.rolesParams && user.rolesParams[DEFAULT_DELIVERY_TYPE],
    defaultSellPoint: user && user.sellPoint && user.sellPoint.id,
  };
};
export default connect<{}, {}, any>(mapStateToProps)(ClientInfoDescription);