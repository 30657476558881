// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Layout_mainLayout__2PbTx {\n  height: 100vh;\n  width: 100vw;\n  background: transparent;\n}\n\n.Layout_content__h8XbY {\n  padding: 20px 15px 10px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"mainLayout": "Layout_mainLayout__2PbTx",
	"content": "Layout_content__h8XbY"
};
module.exports = exports;
