import thunk from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import session, { IStateSession } from './session/sessionReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import clientReducer from './client/clientReducer';

import storage from 'redux-persist/lib/storage';

import { syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';

const sessionPresistConfig = {
  key: 'session',
  storage,
  whitelist: ['token', 'authenticated', 'user'],
};
const rootReducer = combineReducers({
  session: persistReducer(sessionPresistConfig, session),
  clientPage: clientReducer,
  i18n: i18nReducer
});

const middleware = [thunk];
const enhancer = applyMiddleware(...middleware);

export const store = createStore(rootReducer, composeWithDevTools(enhancer));
syncTranslationWithStore(store);
export const persistor = persistStore(store);

export interface IStore {
  session: IStateSession;
}
