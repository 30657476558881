// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserMenu_logout__2QVvk {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  text-align: left;\n  padding: 0;\n}\n\n.UserMenu_logout__2QVvk:focus {\n  outline: none;\n}", ""]);
// Exports
exports.locals = {
	"logout": "UserMenu_logout__2QVvk"
};
module.exports = exports;
