import { OrderStatuses } from './../../../../constants/orderStatuses';
import { handleDigitInputOnKeyDown } from './../../../../helpers/checkDigit';
import { PageStateModel } from '../../../../reducers/pageStateReducer';
import { PaymentTypeModel } from '../../../../models/PaymentTypeModel';
import { OrderStatusModel } from '../../../../models/OrderStatusModel';
import { SellPointModel } from '../../../../models/SellPointModel';
import { DeliveryTypeModel } from '../../../../models/DeliveryTypeModel';
import { FilterModel } from '../../../FiltersPanel/FiltersPanel';
import { FilterTypes } from '../../../../constants/filterTypes';

import { I18n } from 'react-redux-i18n';

interface OrdersFiltersArgs {
  deliveryTypes: DeliveryTypeModel[];
  sellPoints: SellPointModel[];
  orderStatuses: OrderStatusModel[];
  paymentTypes: PaymentTypeModel[];
  inits?: {
    [name: string]: any;
  };
  hasDefaultDeliveryType?: boolean;
  hasDefaultSellPoint?: boolean;
}

export const ordersFilters = ({
  deliveryTypes,
  paymentTypes,
  sellPoints,
  orderStatuses,
  inits,
  hasDefaultDeliveryType,
  hasDefaultSellPoint,
}: OrdersFiltersArgs): FilterModel[] => {
  return [
    {
      filterIndex: 'id',
      type: FilterTypes.INPUT,
      placeholder: I18n.t('form.children.orderNumber.value'), // '№ замовлення',
      initialValue: (inits && inits.orderId) || '',
      onKeyDown: handleDigitInputOnKeyDown,
    },
    {
      filterIndex: 'orderAddress',
      type: FilterTypes.SEARCH,
      placeholder: I18n.t('form.children.address.value'), // 'Адреса',
      initialValue: (inits && inits.orderAddress) || '',
      style: { width: '200px'},
    },
    {
      filterIndex: 'client/phone',
      type: FilterTypes.SEARCH,
      placeholder: I18n.t('form.children.phone.value'), // 'Телефон',
      onKeyDown: handleDigitInputOnKeyDown,
      className: 'phone-filter-input',
      initialValue: (inits && inits['client/phone']) || '',
    },
    {
      filterIndex: 'sellPoint/id',
      type: FilterTypes.SELECT_MULTIPLE,
      values: sellPoints,
      initialValue: (inits && inits['sellPoint/id']) || [],
      disabled: hasDefaultSellPoint,
      placeholder: I18n.t('form.children.salePoint.value'), // 'Точка продажу',
      showField: 'name',
      keyField: 'id',
    },
    {
      filterIndex: 'deliveryType/code',
      filterLangKey: 'form.children.deliveryType.children',
      type: FilterTypes.SELECT,
      values: deliveryTypes,
      disabled: hasDefaultDeliveryType,
      initialValue: (inits && inits['deliveryType/code']) || undefined,
      placeholder: I18n.t('form.children.deliveryType.value'), // 'Тип доставки',
      showField: 'type',
      keyField: 'code',
    },
    {
      filterIndex: 'orderStatus/code',
      filterLangKey: 'form.children.status.children',
      type: FilterTypes.SELECT_MULTIPLE,
      values: orderStatuses,
      initialValue: (inits && inits['orderStatus/code']) || [],
      placeholder: I18n.t('form.children.status.value'), // 'Статус',
      showField: 'status',
      keyField: 'code',
    },
    {
      filterIndex: 'payments/paymentType/id',
      filterLangKey: 'form.children.paymentType.children',
      type: FilterTypes.SELECT,
      values: paymentTypes,
      initialValue: (inits && inits['payments/paymentType/id']) || undefined,
      placeholder: I18n.t('form.children.paymentType.value'), // 'Тип оплати',
      showField: 'type',
      keyField: 'id',
    },
  ];
};

export const initialState: PageStateModel = {
  filters: {
    id: {
      type: FilterTypes.INPUT,
      value: '',
    },
    orderAddress: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    'client/phone': {
      type: FilterTypes.SEARCH,
      value: '',
    },
    'sellPoint/id': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
    'deliveryType/code': {
      type: FilterTypes.SELECT,
      value: null,
    },
    'orderStatus/code': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [
        OrderStatuses.new, OrderStatuses.confirm, OrderStatuses.inprogress,
        OrderStatuses.ready, OrderStatuses.courier, OrderStatuses.ontheway
      ],
    },
    'payments/paymentType/id': {
      type: FilterTypes.SELECT,
      value: null,
    },
  },
  sorter: {
    order: 'descend',
    field: 'id',
  },
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};
