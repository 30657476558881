import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Icon, Spin } from 'antd';
import { getColumnSearchProps } from '../../../getColumnSearchProps/getColumnSearchProps';
import Highlighter from 'react-highlight-words';
import { getMoneyFormat } from '../../../../helpers/getMoneyFormat';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import CouriersService from '../../../../services/CouriersService';
import MoneyPopover from './MoneyPopover';
import { getErrorNotification } from '../../../Notifications/Notifications';

const service = new CouriersService();
const NEW_VALUE = 'NEW_VALUE';

const CashHistory = ({ courierId, visible, closeModal, updateHistory }: any) => {
  const [searchText, setSearchText] = useState<any>({});
  
  const [isLoading, setIsLoading] = useState(false);
  const [additionMode, setAdditionMode] = useState(false);

  const [data, setData] = useState([] as any);

  const getCashOperations = async (courierId: number) => {
    setIsLoading(true);
    setData(await service.getCourierMoneyHistory(courierId));
    setIsLoading(false);
  };

  useEffect(() => {
    getCashOperations(courierId);
  }, [courierId]);

  const columns = [
    {
      title: 'Дата та час запису',
      dataIndex: 'setDate',
      sorter: true,
      width: 200,
      ...getColumnFilterProps('cashRecievedDate', 'дату'),
      render: (date: string) => convertToLocalDate(date),
    },
    {
      title: 'Сума',
      dataIndex: 'value',
      width: 100,
      className: 'centered',
      sorter: true,
      render: (sum: number | string) => {
        if (sum === NEW_VALUE) {
          return <MoneyPopover onAddButton={handleConfirmAddition} onCancelButton={handleCancelAddition}/>;
        }

        return getMoneyFormat(sum);
      },
    },
  ];

  function getColumnFilterProps(dataIndex: string, title: string) {
    return {
      ...getColumnSearchProps(dataIndex, handleSearch, handleReset, title),
      render: (text: any) => (
        text ?
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText[dataIndex]]}
            autoEscape
            textToHighlight={text.toString()}
          /> : null
      ),
    };
  }

  function handleSearch(selectedKeys: any, dataIndex: string, confirm: any) {
    confirm();
    setSearchText({
      ...searchText,
      [dataIndex]: selectedKeys[0],
    });
  }

  function handleReset(clearFilters: any, dataIndex: string) {
    clearFilters();
    setSearchText({
      ...searchText,
      [dataIndex]: '',
    });
  }

  const handleAddButton = (e: React.MouseEvent) => {
    setData([{ setDate: new Date(), value: NEW_VALUE, id: NEW_VALUE}, ...data]);
    setAdditionMode(true);
  };

  const handleConfirmAddition = (value: number | undefined) => {
    setIsLoading(true);
    updateHistory(value)
      .catch((e: Error) => {
        getErrorNotification(e);
      })
      .finally(() => {
        getCashOperations(courierId);
        setAdditionMode(false);
      });
  };

  const handleCancelAddition = () => {
    setAdditionMode(false);
    getCashOperations(courierId);
  };

  return (
    <Modal
      maskClosable={false}
      width={370}
      destroyOnClose
      title={
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: '35px',
        }}>
          <span>Облік готівки кур'єра #{courierId}</span>
          <Button size="small" type="primary" onClick={handleAddButton} disabled={additionMode}>
            <Icon type="file-add"/>
          </Button>
        </div>
      }
      visible={visible}
      onCancel={closeModal}
      onOk={closeModal}
      okText="ОК"
      cancelButtonProps={{
        style: {
          display: 'none',
        }
      }}
    >
      <Spin spinning={isLoading}>
        <Table
          rowKey="id"
          size="small"
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ y: 350 }}
        />
      </Spin>
    </Modal>
  );
};

export default CashHistory;