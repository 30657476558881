import React from 'react';
import ImageCell from '../../../ImageCell/ImageCell';
import { I18n } from 'react-redux-i18n';

interface CategoriesColumnsProps {
  openCarousel: (id: number) => void;
  sort: {
    field: string,
    order: 'ascend' | 'descend',
  };
}

export const cateoriesColumns = ({
  openCarousel,
  sort,
}: CategoriesColumnsProps) => ([
  {
    title: I18n.t('categories.children.title.value'),
    dataIndex: 'name',
    width: 250,
    sorter: true,
    sortOrder: sort.field === 'name' ? sort.order : undefined,
  },
  {
    title: I18n.t('categories.children.image.value'),
    dataIndex: 'categoryImages',
    width: 250,
    render: (text: string, record: any, row: any) => {
      return (
        record.categoryImages ?
        <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
          <ImageCell
            image={record.categoryImages}
            id={row}
            handleClick={openCarousel}
          />
        </div> : null
      );
    },
  },
  {
    title: I18n.t('categories.children.description.value'),
    dataIndex: 'description',
    width: 350,
    sorter: true,
    sortOrder: sort.field === 'description' ? sort.order : undefined,
  },
]);