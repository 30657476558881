import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import style from './TableHeader.module.css';
import Controls, { ControlModel } from '../Controls/Controls';

export interface TableTitleProps {
  title?: string | ReactNode;
  controls?: ControlModel[];
}

const TableTitle = ({ title, controls }: TableTitleProps) => {
  return (
    <Row justify="space-between" type="flex" align="middle">
      <Col>
        {typeof title === 'string' ? <h3 className={style.title}>{title}</h3> : title}
      </Col>
      <Col>
        <Controls controls={controls}/>
      </Col>
    </Row>
  );
};

export default TableTitle;