import React from 'react';
import { Input, Button } from 'antd';
import { checkDigit } from '../../helpers/checkDigit';

const TextFilter = (props: any) => {
  const { setSelectedKeys, selectedKeys, confirm, inputRef,
    clearFilters, dataIndex, handleSearch, handleReset, title, type } = props;
  
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        placeholder={'Введіть ' + (title.toLocaleLowerCase())}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
        className="filter__input"
        onKeyDown={(e) => {
          if (type === 'number' && !checkDigit(e.key, true)) {
            e.preventDefault();
          }
        }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
        className="filter__search"
      >
        Пошук
      </Button>
      <Button 
        onClick={() => handleReset(clearFilters, dataIndex)} 
        size="small" 
        style={{ width: 90 }}
        className="filter__reset"
      >
        Очистити
      </Button>
    </div>
  );
};

export default TextFilter;