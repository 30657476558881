// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StatusSelect_select__3XLU9 {\n  display: block;\n}\n\n.StatusSelect_badge__79mFR {\n  box-shadow: none;\n  font-weight: bold;\n}", ""]);
// Exports
exports.locals = {
	"select": "StatusSelect_select__3XLU9",
	"badge": "StatusSelect_badge__79mFR"
};
module.exports = exports;
