import React, { useState } from 'react';
import { Select, Icon } from 'antd';
import { Translate } from 'react-redux-i18n';

const EditableSelect = ({ value, options, textValue, setValue, keyField, langKey, ...restProps }: any) => {
  const [editMode, setEditMode] = useState(false);

  const handleSelect = (value: any) => {
    setEditMode(false);
    setValue(options.find((item: any) => item.id === value));
  };

  const openEditMode = () => {
    setEditMode(true);
  };

  const handleVisibleChange = (isOpen: boolean) => {
    if (!isOpen) {
      setEditMode(false);
    }
  };

  return (
    editMode ?
      <Select
        style={{ minWidth: `100px`, ...restProps.style }}
        onDropdownVisibleChange={handleVisibleChange}
        size="small"
        defaultOpen={true}
        value={value}
        onSelect={handleSelect}
        onBlur={() => setEditMode(false)}
      >
        {
          options.map((item: any) => (
            <Select.Option key={item.id} value={item.id}>
              {keyField ?
                (Array.isArray(keyField) ?
                  keyField.map((field: string, index: number) => {
                    if (index === keyField.length - 1) {
                      return `${item[field]}`;
                    }
                    return `${item[field]} - `;
                  }) :
                  item[keyField]) : <Translate  value={`${langKey}.${item.code}.value`}/>}</Select.Option>
          ))
        }
      </Select > : 
      <span>
        {textValue}
        <Icon
          onClick={openEditMode}
          type="edit"
          style={{
            fontSize: '85%',
            opacity: '0.8',
            marginLeft: '5px',
          }}
        />
      </span>
  );
};

export default EditableSelect;