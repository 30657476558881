import React from 'react';
import ImageCell from '../../../ImageCell/ImageCell';
import { Table } from 'antd';
import { ProductModel } from '../../../../models/ProductModel';
import { I18n } from 'react-redux-i18n';

interface ProductColumnsProps {
  openCarousel: (id: number) => void;
  sort: {
    field: string,
    order: 'ascend' | 'descend',
  };
}

export const productsColumns = ({
  openCarousel,
  sort
}: ProductColumnsProps) => ([
  {
    title: I18n.t('products.children.title.value'),
    dataIndex: 'title',
    sorter: true,
    sortOrder: sort.field === 'title' ? sort.order : undefined,
    width: 200,
  },
  {
    title: I18n.t('products.children.images.value'),
    dataIndex: 'productImages',
    width: 200,
    render: (text: string, record: any, row: any) => {
      return (
        record.productImages[0] ?
          <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
            <ImageCell
              image={record.productImages[0]}
              id={row}
              handleClick={openCarousel}
            />
          </div> : null
      );
    },
  },
  {
    title: I18n.t('products.children.description.value'),
    dataIndex: 'shortDescription',
    sorter: true,
    sortOrder: sort.field === 'shortDescription' ? sort.order : undefined,
    width: 300
  },
  {
    title: I18n.t('products.children.category.value'),
    dataIndex: 'category.name',
    sortIndex: 'category/name',
    sorter: true,
    sortOrder: sort.field === 'category.name' ? sort.order : undefined,
    width: 160.
  },
  {
    title: I18n.t('products.children.minimalAmount.value'),
    dataIndex: 'minCount',
    width: 115,
    sorter: true,
    sortOrder: sort.field === 'minCount' ? sort.order : undefined,
    className: 'centered',
  },
  {
    title: I18n.t('products.children.productUnits.value'),
    dataIndex: 'unit.type',
    sortIndex: 'unit/type',
    width: 130,
    sorter: true,
    sortOrder: sort.field === 'unit.type' ? sort.order : undefined,
    className: 'centered',
    render: (type: string, product: ProductModel) => {
      console.log(product);
      return I18n.t(`form.children.units.children.${product.unit.code}.value`);
    }
  },
]);

export const additionalColumns = (record: ProductModel, ...args: any) => {
  const columns = [
    {
      title: I18n.t('products.children.ingredients.value'),
      dataIndex: 'ingredients',
    },
    {
      title: I18n.t('products.children.calories.value'),
      dataIndex: 'energyValue',
    },
    {
      title: I18n.t('products.children.sideDish.value'),
      dataIndex: 'garnish',
    },
    {
      title: I18n.t('products.children.description.value'),
      dataIndex: 'fullDescription',
    },
  ];

  return (
    <Table
      className="nested-table"
      dataSource={[record]}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="id"
    />
  );
};