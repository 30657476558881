import { I18n } from 'react-redux-i18n';
import { PageStateModel } from './../../../../reducers/pageStateReducer';
import { UnitModel } from './../../../../models/UnitModel';
import { CategoryModel } from './../../../../models/CategoryModel';
import { FilterTypes } from './../../../../constants/filterTypes';

interface ProductsFiltersArgs { 
  categories: CategoryModel[];
  units: UnitModel[];
  inits?: {
    [prop: string]: any;
  };
}

export const productsFilters = ({categories, units, inits}: ProductsFiltersArgs) => ([
  {
    filterIndex: 'title',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.name.value'),
    initialValue: (inits && inits.title) || '',
  },
  {
    filterIndex: 'shortDescription',
    type: FilterTypes.SEARCH,
    placeholder: I18n.t('form.children.description.value'),
    initialValue: (inits && inits.shortDescription) || '',
  },
  {
    filterIndex: 'category/id',
    type: FilterTypes.SELECT_MULTIPLE,
    placeholder: I18n.t('form.children.category.value'),
    values: categories,
    showField: 'name',
    keyField: 'id',
    initialValue: (inits && inits['category/id']) || [],
  },
  {
    filterIndex: 'unit/id',
    type: FilterTypes.SELECT_MULTIPLE,
    placeholder: I18n.t('form.children.units.value'),
    values: units,
    showField: 'type',
    keyField: 'id',
    initialValue: (inits && inits['unit/id']) || [],
  },
]);

export const productsInitialState: PageStateModel = {
  filters: {
    title: {
      type: FilterTypes.SEARCH,
      value: ''
    },
    shortDescription: {
      type: FilterTypes.SEARCH,
      value: ''
    },
    'category/id': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
    'unit/id': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    }
  },
  sorter: {},
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};