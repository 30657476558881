// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader_title__1nPqs {\n  margin: 0;\n  margin-left: 10px;\n}", ""]);
// Exports
exports.locals = {
	"title": "TableHeader_title__1nPqs"
};
module.exports = exports;
