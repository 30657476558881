import React from 'react';
import { OrderModel } from '../../models/OrderModel';
import { Col, Row } from 'antd';

import css from './AdditionalOrderInfo.module.css';
import { getMoneyFormat } from '../../helpers/getMoneyFormat';
import { Translate } from 'react-redux-i18n';

const AdditionalOrderInfo = ({ order }: { order: OrderModel }) => {
  const delPrice = order.deliveryPrice && order.deliveryPrice.price;

  const purchases = order.purchases.map((item: any, index) => {
    if (!item.product) {
      return null;
    }
    
    return (
      <Row key={item.product.id || index}>
        <Row>
          <Col span={12}>{item.product.title}</Col>
          <Col span={3} className={css.columnCentered}>{item.count}</Col>
          <Col span={3} className={css.columnCentered}>{getMoneyFormat(item.product.productOptions[0].price)}</Col>
          <Col span={3} className={css.columnCentered}>{getMoneyFormat(item.price)}</Col>
        </Row>
        <Row className={css.productComment}>
          {item.comment}
        </Row>
      </Row>
    );
  });

  return (
    <div className={css.container}>
      <Row
        className={css.headRow}
      >
        <Col span={12}><Translate value={`orders.children.orderDrawer.children.title.value`} /></Col>
        <Col span={3} className={css.columnCentered}><Translate value={`orders.children.orderDrawer.children.count.value`} /></Col>
        <Col span={3} className={css.columnCentered}><Translate value={`orders.children.orderDrawer.children.price.value`} /></Col>
        <Col span={3} className={css.columnCentered}><Translate value={`orders.children.orderDrawer.children.cost.value`} /></Col>
      </Row>
      {purchases}
      {delPrice &&
        <Row className={css.totalRow}>
          <Col span={4}><Translate value={`orders.children.orderDrawer.children.delivery.value`} />:</Col>
          <Col offset={14} span={3} className={css.columnCentered}>{getMoneyFormat(delPrice)}</Col>
        </Row>
      }
      <Row className={css.totalRow}>
        <Col span={4}><Translate value={`orders.children.orderDrawer.children.total.value`} />:</Col>
        <Col offset={14} span={3} className={css.columnCentered}>
          {getMoneyFormat(Number(order.productsPrice) + Number(delPrice))}
        </Col>
      </Row>
      {
        order.address && order.address.notes &&
        <Row className={css.commentRow}>
          <span><Translate value={`orders.children.ordersTable.children.addressNotes.value`} /></span>: {order.address.notes}
        </Row>
      }
      {
        order.comment &&
        <Row className={css.commentRow}>
          <span><Translate value={`orders.children.orderDrawer.children.comment.value`} /></span>: {order.comment}
        </Row>
      }
    </div>
  );
};

export default AdditionalOrderInfo;