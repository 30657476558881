import React from 'react';

export interface DateCellProps {
  dateStr: string | null;
}

export const DateCell = ({ dateStr }: DateCellProps) => {
  return (
    dateStr ? 
      <div>
        <div>{dateStr.substring(0, dateStr.indexOf(' '))}</div>
        <div>{dateStr.substring(dateStr.indexOf(' '))}</div>
      </div> : null
  );
};