// @ts-ignore
import buildQuery from 'odata-query';

export interface IODataModel {
    filter?: any;
    expand?: any;
    orderBy?: any;
    top?: number | null;
    skip?: number | null;
    count?: boolean | null;
}

export default (params?: IODataModel) => {
    return buildQuery(params);
};