import { combineReducers } from 'redux';
import { SessionType } from './actions';
import { i18nReducer } from 'react-redux-i18n';

const user = (state = null, { type, payload }: any) => {
  switch (type) {
    case SessionType.LOGIN_SUCCESS:
    case SessionType.SIGNUP_SUCCESS:
      return payload.response.user;

    case SessionType.REFRESH_USER_SUCCESS:
      return payload.user;

    case SessionType.CHANGE_USER_PASSWORD_REQUEST:
      return { username: payload.username };

    case SessionType.LOGOUT:
    case SessionType.CHANGE_USER_PASSWORD_SUCCESS:
      return null;

    default:
      return state;
  }
};

const settings = (state = null, { type, payload }: any) => {
  switch (type) {
    case SessionType.REFRESH_SETTINGS_SUCCESS:
      return payload.settings;
    default:
      return state;
  }
};

const authenticated = (state = false, { type, payload }: any) => {
  switch (type) {
    case SessionType.LOGIN_SUCCESS:
    case SessionType.SIGNUP_SUCCESS:
    case SessionType.REFRESH_USER_SUCCESS:
      return true;

    case SessionType.LOGOUT:
      return false;

    default:
      return state;
  }
};

const token = (state = null, { type, payload }: any) => {
  switch (type) {
    case SessionType.LOGIN_SUCCESS:
    case SessionType.SIGNUP_SUCCESS:
      return payload.response.token;

    case SessionType.LOGOUT:
      return null;

    default:
      return state;
  }
};

const error = (state = null, { type, payload }: any) => {
  switch (type) {
    case SessionType.LOGIN_ERROR:
    case SessionType.SIGNUP_ERROR:
    case SessionType.REFRESH_USER_ERROR:
    case SessionType.CHANGE_USER_PASSWORD_ERROR:
      return payload.error;

    case SessionType.LOGIN_SUCCESS:
      return null;

    default:
      return state;
  }
};

const changePassword = (state = false, { type }: any) => {
  switch (type) {
    case SessionType.CHANGE_USER_PASSWORD_REQUEST:
      return true;
    case SessionType.CHANGE_USER_PASSWORD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const languages = (state = false, { type, payload }: any) => {
  switch (type) {
    case SessionType.GET_APP_LANGUAGES_SUCCESS:
      return payload.languages;
    default:
      return state;
  }
};

const language = (state = false, { type, payload }: any) => {
  switch (type) {
    case SessionType.GET_APP_LANGUAGE_SUCCESS:
      return payload.language;
    default:
      return state;
  }
};

const langCode = (state = false, { type, payload }: any) => {
  switch (type) {
    case SessionType.SET_APP_LANGUAGE_CODE:
      return payload.langCode;
    default:
      return state;
  }
};

export interface IStateSession {
  user: any;
  settings: any;
  authenticated: boolean;
  token: any;
  error: any;
  changePassword: boolean;
  languages: any;
  language: any;
  langCode: string;
}

export default combineReducers({
  user,
  settings,
  authenticated,
  token,
  error,
  changePassword,
  languages,
  language,
  langCode,
});
