import { I18n } from "react-redux-i18n";

export const getAddressColumns = (i18n: I18n) => ([
    {
        title: i18n.t('form.children.addressDetails.children.district.value'),
        dataIndex: 'district'
    },
    {
        title: i18n.t('form.children.addressDetails.children.street.value'),
        dataIndex: 'street'
    },
    {
        title: i18n.t('form.children.addressDetails.children.building.value'),
        dataIndex: 'buildNumber'
    },
    {
        title: i18n.t('form.children.addressDetails.children.corps.value'),
        dataIndex: 'corps'
    },
    {
        title: i18n.t('form.children.addressDetails.children.entrance.value'),
        dataIndex: 'entrance'
    },
    {
        title: i18n.t('form.children.addressDetails.children.floor.value'),
        dataIndex: 'floor'
    },
    {
        title: i18n.t('form.children.addressDetails.children.flat.value'),
        dataIndex: 'flatNumber'
    },
]);