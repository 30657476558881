import * as types from '../constants/createOrder';

export const initialState = {
    id: null,
    statusCode: null,
    clientInfo: null,
    orderInfo: null,
    orderData: [],
    totalSum: 0,
};

const reducer = (
    state: any,
    {
        type,
        id,
        statusCode,
        clientInfo,
        orderInfo,
        orderData,
        totalSum,
    }: any
) => {
    switch (type) {
        case types.SET_CLIENT_INFO: {
            return {...state, clientInfo};
        }
        case types.SET_ORDER_INFO: {
            return {...state, orderInfo};
        }
        case types.SET_CLIENT_ORDER_INFO: {
            return {...state, clientInfo, orderInfo};
        }
        case types.SET_ORDER_DATA: {
            return {...state, orderData};
        }
        case types.SET_TOTAL_SUM: {
            return {...state, totalSum};
        }
        case types.SET_ORDER_AND_TOTAL_SUM: {
            state = {...state, orderData, totalSum};
            return state;
        }
        case types.SET_FULL_ORDER: {
            return {id, statusCode, clientInfo, orderInfo, orderData, totalSum};
        }
        case types.REFRESH:
        default: {
            return initialState;
        }
    }
};

export {reducer};
