import React, { useContext } from 'react';
import {
  Row,
  Col,
  Icon,
  Tooltip,
  notification,
} from 'antd';
import MultipleDragElement from './MultipleDragElement';
import {
  SET_MARKED_ORDER,
} from '../../../../constants/delivery';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import { TIME_FORMAT, DATE_FORMAT } from '../../../../constants/formats';
import { getAddress } from '../../../../helpers/getAddress';
import moment from 'moment';
import { getMoneyFormat } from '../../../../helpers/getMoneyFormat';
import { DeliveryContext } from '../../../../contexts/DeliveryContextProvider';
import { useDrop } from 'react-dnd';
import { deliveriesDnDTypes } from '../../../../constants/deliveriesDnDTypes';
import PointIcon from '../../../icons/PointIcon';

interface OrdersListProps {
  dataSource: any;
  name: string;
  onDropped: (fields: any[], dropItem: any) => void;
}

const OrdersList: React.FC<OrdersListProps> = ({
  dataSource,
  name,
  onDropped,
}) => {

  const { delivery, deliveryDispatch } = useContext(DeliveryContext);
  const { markedOrders } = delivery;

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: deliveriesDnDTypes.ORDER,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: () => ({
      name: 'orders',
    }),
  });

  const markActive = (item: any) => {
    const idx = markedOrders.indexOf(item);

    const isInvalidSellPoints = (data: any[]) => {
      const obj = data.find((item: any) => {
        const zItem = data[0];
        return item.sellPoint.id !== zItem.sellPoint.id;
      });
      if (obj) {
        return true;
      }
      return false;
    };

    const orderDispatch = (arr: any[]) => {
      const isInvalid = isInvalidSellPoints(arr);
      if (isInvalid) {
        notification.warning({
          message: 'Неможлива операція',
          description: 'Можливе виділення замовлень лише з однієї точки продажу!',
        });
      } else {
        deliveryDispatch({
          type: SET_MARKED_ORDER,
          markedOrders: arr,
        });
      }
    };

    if (idx === -1) {
      const arr = [...markedOrders, item];
      orderDispatch(arr);
    } else {
      markedOrders.splice(idx, 1);
      const arr = [...markedOrders];
      orderDispatch(arr);
    }
  };

  const checkIsActiveBlock = (item: any) => {
    if (markedOrders.length > 0 && markedOrders.indexOf(item) >= 0) {
      return true;
    }
    return false;
  };

  let ordersDate = dataSource && dataSource[0] && dataSource[0].minExecuteDate && 
    moment(dataSource[0].minExecuteDate).local();

  return (
    <div 
      ref={drop}
      style={{
        opacity: isOver && canDrop ? 0.4 : 1,
      }}
    >
      {
        dataSource && dataSource.length > 0 && dataSource.map((item: any, index: number) => {
          const isActive = checkIsActiveBlock(item);
          const itemDate = moment(item.minExecuteDate).local();

          let dateBlock = null;
          if (itemDate.date() !== ordersDate.date() || index === 0) {
            dateBlock = <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                          <div style={{ 
                            padding: '12px',
                            width: '100%', 
                            display: 'inline-block', 
                            background: '#fbfbfb',
                            borderTop: '1px solid #e8e8e8',
                          }}>
                            {convertToLocalDate(item.minExecuteDate, DATE_FORMAT)}
                          </div>
                        </div>;
            ordersDate = itemDate;
          }

          return (
            <div key={item.id}>
              {dateBlock}
              <MultipleDragElement
                key={item.id}
                item={item}
                name={name}
                selectedFields={markedOrders}
                selectedSource={item.id}
                onDropped={onDropped}
                isActive={isActive}
                onClick={markActive}>
                <Row>
                  <Col span={3} style={{ fontWeight: 'bold' }}>#{item.id}</Col>
                  <Col span={4}>
                    <PointIcon />
                    {`\t${item.sellPoint.name}`}
                  </Col>
                  <Col span={10}>
                    <PointIcon destination />
                    {`\t${getAddress(item.address)}`}
                  </Col>
                  <Col span={4} style={{textAlign: 'center'}}>
                    {
                      !item.minExecuteDate ?
                        'На найближчий час' :
                        <div><Icon type="clock-circle" />
                          {`\t${convertToLocalDate(item.minExecuteDate, TIME_FORMAT)} - 
                          ${convertToLocalDate(item.maxExecuteDate, TIME_FORMAT)}`}
                        </div>
                    }
                  </Col>
                  <Col span={3} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {
                      item.payments.paymentType.code === 'card' ?
                        <Tooltip placement="top" title="Картка">
                          <Icon type="credit-card" theme="twoTone" twoToneColor="#d4af37" />
                        </Tooltip> :
                        <Tooltip placement="top" title="Готівка">
                          <Icon type="dollar" />
                        </Tooltip>
                    }
                    {`\t${getMoneyFormat(item.payments.totalPrice)}`}
                  </Col>
                </Row>
              </MultipleDragElement>
            </div>
          );
        })
      }
    </div>
  );
};

export default OrdersList;
