import {TypeModal} from '../../constants/typeModal';

const PREFIX = 'client.';

export const SET_CLIENT = PREFIX + 'SET_CLIENT';
export const SET_CLIENT_LOADING = PREFIX + 'SET_CLIENT_LOADING';
export const SET_CLIENT_ERROR = PREFIX + 'SET_CLIENT_ERROR';
export const INIT_CLIENT = PREFIX + 'INIT_CLIENT';

export const ADD_ADDRESS = PREFIX + 'ADD_ADDRESS';
export const UPDATE_ADDRESS = PREFIX + 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = PREFIX + 'DELETE_ADDRESS';

export const ADD_CONTACT = PREFIX + 'ADD_CONTACT';
export const UPDATE_CONTACT = PREFIX + 'UPDATE_CONTACT';
export const DELETE_CONTACT = PREFIX + 'DELETE_CONTACT';

export const SET_TYPE_MODAL = PREFIX + 'SET_TYPE_MODAL';

export const SELECT_EDIT_CONTACT = PREFIX + 'SELECT_EDIT_CONTACT';
export const SELECT_EDIT_ADDRESS = PREFIX + 'SELECT_EDIT_ADDRESS';

export const setClient = (data: any) => ({
  type: SET_CLIENT,
  payload: {
    client: data
  }
});

export const setClientLoading = (loading: boolean) => ({
  type: SET_CLIENT_LOADING,
  payload: {
    isLoading: loading
  }
});

export const setClientError = (error: any) => ({
  type: SET_CLIENT_ERROR,
  payload: {
    error
  }
});

export const initClient = () => ({
  type: INIT_CLIENT
});

export const addAddress = (data: any) => ({
  type: ADD_ADDRESS,
  payload: {
    address: data
  }
});

export const updateAddress = (id: any, data: any) => ({
  type: UPDATE_ADDRESS,
  payload: {
    id,
    address: data
  }
});

export const deleteAddress = (id: any) => ({
  type: DELETE_ADDRESS,
  payload: {
    id
  }
});

export const addContact = (data: any) => ({
  type: ADD_CONTACT,
  payload: {
    contact:  data
  }
});

export const updateContact = (id: any, data: any) => ({
  type: UPDATE_CONTACT,
  payload: {
    id,
    contact: data
  }
});

export const deleteContact = (id: any) => ({
  type: DELETE_CONTACT,
  payload: {
    id
  }
});

export const setTypeModal = (type: TypeModal, id: number | null) => ({
  type: SET_TYPE_MODAL,
  payload: {
    type,
    id
  }
});

export const selectEditAddress = (id: number | null) => ({
  type: SELECT_EDIT_ADDRESS,
  payload: {
    id
  }
});

export const selectEditContact = (id: number | null) => ({
  type: SELECT_EDIT_CONTACT,
  payload: {
    id
  }
});
