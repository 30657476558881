import React, { FormEvent, useState, useEffect } from 'react';
import { Form, Input, Checkbox, Select, Upload, Icon, InputNumber, Spin, Modal, Row } from 'antd';
import '../../../styles/modal-form.css';
import { ProductModel } from '../../../models/ProductModel';
import { CategoryModel } from '../../../models/CategoryModel';
import { UnitModel } from '../../../models/UnitModel';
import { SellPointModel } from '../../../models/SellPointModel';
import ProductsPageService from '../../../services/ProductsService';
import { checkDigit } from '../../../helpers/checkDigit';
import { getErrorNotification } from '../../Notifications/Notifications';
import { getImageURL } from '../../../helpers/getImageURL';
import DictionaryService from '../../../services/DictionaryService';
import { getDefaultModalProps } from '../../../constants/ModalFormConstants';
import { selectFilterOption } from '../../../helpers/selectFilterOption';
import { I18n, Translate } from 'react-redux-i18n';

const { Option } = Select;
const productsPageService = new ProductsPageService();
const dictionaryService = new DictionaryService();

const ProductForm = (props: any) => {
  const { getFieldDecorator } = props.form;
  const { productId, submitProduct, closeForm } = props;

  const [product, setProduct] = useState({} as ProductModel);
  const [categories, setCategories] = useState([] as CategoryModel[]);
  const [units, setUnits] = useState([] as UnitModel[]);
  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  const [activeSellPoints, setActiveSellPoints] = useState([] as any[]);
  const [fileList, setFileList] = useState([] as any[]);
  const [filesToDelete, setFilesToDelete] = useState([] as string[]);

  const [fetchingData, setFetchingData] = useState(false);

  useEffect(() => {
    getFormInfo();
  }, [productId]);

  async function getFormInfo() {
    try {
      setFetchingData(true);
      if (productId) {
        const product = await productsPageService.getProduct(productId);
        getProductImages(product);
        getActiveSellPoints(product);
        setProduct(product);
      }

      const sellPoints = dictionaryService.getSellPoints();
      const units = dictionaryService.getUnits();
      const categories = productsPageService.getCategories();

      setSellPoints(await sellPoints);
      setUnits(await units);
      setCategories(await categories);
    } catch (e) {
      console.error('Error: ' + e);
      getErrorNotification(e);
    } finally {
      setFetchingData(false);
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const {
          available, categoryId, energyValue, ingredients, fullDescription, shortDescription,
          garnish, minCount, prices, activeSellPoints, title, unitId,
        } = values;

        const unit = {
          id: unitId,
        };
        const category = {
          id: categoryId,
        };

        const productOptions: any[] = [];
        if (activeSellPoints) {
          for (const [index, active] of activeSellPoints.entries()) {
            if (active) {
              productOptions.push({
                available: available[index],
                price: prices[index],
                sellPoint: {
                  id: sellPoints[index].id,
                },
              });
            }
          }
        }

        const finalProduct = {
          title: title || null,
          shortDescription: shortDescription || null,
          fullDescription: fullDescription || null,
          garnish: garnish || null,
          energyValue: energyValue || null,
          ingredients: ingredients || null,
          minCount,
          category,
          unit,
          productOptions,
        } as ProductModel;

        if (productId) {
          finalProduct.id = productId;
        }

        const imagesData = new FormData();
        for (const file of fileList) {
          if (file.originFileObj) {
            imagesData.append('files', file.originFileObj);
          }
        }

        setFetchingData(true);
        submitProduct(finalProduct, imagesData, filesToDelete)
          .finally(() => setFetchingData(false));
      }
    });
  };

  const getProductImages = (product: ProductModel) => {
    const fileList = product.productImages.map((img) => ({
      name: img.uuid,
      uid: img.uuid,
      url: getImageURL(img.uuid),
    }));

    setFileList(fileList);
  };

  const getActiveSellPoints = (product: ProductModel) => {
    let activeSellpoints: any[] = [];

    if (product && product.productOptions) {
      activeSellpoints = product.productOptions.map((item: any) => item.sellPoint.id);
    }

    setActiveSellPoints(activeSellpoints);
  };

  const handleSellpoints = (value: boolean, index: number, id: number) => {
    const newActiveSellPoints = activeSellPoints.slice(0);
    if (value) {
      newActiveSellPoints.push(id);
    } else {
      newActiveSellPoints.splice(newActiveSellPoints.indexOf(id), 1);
    }

    setActiveSellPoints(newActiveSellPoints);
  };

  const isSellPointActive = (id: number) => {
    return activeSellPoints.includes(id);
  };

  const getOptionsBySellpoint = (id: number) => {
    if (product.productOptions) {
      for (let i = 0; i < product.productOptions.length; i++) {
        if (id === product.productOptions[i].sellPoint.id) {
          return product.productOptions[i];
        }
      }
    }
  };

  const handleImages = (info: any) => {
    const { file, fileList } = info;
    const newFilesToDelete = filesToDelete.slice(0);

    if (!product.productImages) {
      product.productImages = [];
    }

    if (product.productImages.map((image: any) => image.uuid).includes(file.uid)) {
      newFilesToDelete.push(file.uid);
    }

    setFilesToDelete(newFilesToDelete);
    setFileList(fileList);
  };

  return (
    <Modal
      {...getDefaultModalProps(I18n)}
      title={productId ? I18n.t('products.children.editProduct.value') : I18n.t('products.children.createProduct.value')}
      onCancel={closeForm}
      onOk={handleSubmit}
    >
      <Spin spinning={fetchingData}>
        <Form onSubmit={handleSubmit} wrapperCol={{span: 15}} labelCol={{span: 9}} className="modal-form">
          <Form.Item  label={I18n.t('products.children.title.value')}>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.title,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.shortDesc.value')}>
            {getFieldDecorator('shortDescription', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.shortDescription,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.fullDesc.value')}>
            {getFieldDecorator('fullDescription', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.fullDescription,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.sideDish.value')}>
            {getFieldDecorator('garnish', {
              rules: [{ required: false, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.garnish,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.calories.value')}>
            {getFieldDecorator('energyValue', {
              rules: [{ required: false, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.energyValue,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.ingredients.value')}>
            {getFieldDecorator('ingredients', {
              rules: [{ required: false, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.ingredients,
            })(
              <Input
              />,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.minimalAmount.value')}>
            {getFieldDecorator('minCount', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: product.minCount,
            })(
              <InputNumber
                min={1}
                max={9999999}
                decimalSeparator={','}
                onKeyDown={(e: any) => {
                  if (!checkDigit(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.productUnits.value')}>
            {getFieldDecorator('unitId', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: (product && product.unit ? product.unit.id : null),
            })(
              <Select filterOption={selectFilterOption} showSearch>
                {units.map(((unit: UnitModel) =>
                  <Option value={unit.id} key={unit.id}>
                    <Translate value={`form.children.units.children.${unit.code}.value`}/>
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.category.value')}>
            {getFieldDecorator('categoryId', {
              rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
              initialValue: (product && product.category ? product.category.id : null),
            })(
              <Select filterOption={selectFilterOption} showSearch>
                {categories.map(((category: CategoryModel) =>
                  <Option value={category.id} key={category.id}>{category.name}</Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item  label={I18n.t('products.children.images.value')}>
            {getFieldDecorator('images', {
            })(
              <Upload.Dragger
                name="image"
                accept="image/*"
                beforeUpload={(file) => false}
                onChange={handleImages}
                listType="picture"
                fileList={fileList}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  <Translate value="form.children.dndFiles.value"/>
                </p>
              </Upload.Dragger>,
            )}
          </Form.Item>
          {sellPoints.map((sellPoint: SellPointModel, index: number) => {
            const sellPointIsActive = isSellPointActive(sellPoint.id);
            let options;

            if (!sellPoint.name) {
              return null;
            }

            if (sellPointIsActive) {
              options = getOptionsBySellpoint(sellPoint.id);
            }
            return (
              <Row
                type="flex"
                justify="space-between"
                key={sellPoint.id}
                style={{
                  borderTop: '1px solid #d9d9d9',
                  paddingTop: '8px',
                }}
              >
                <Form.Item 
                  style={{width: '33.333%'}} 
                  label={`${sellPoint.name}`} 
                  labelCol={{span: 18}} 
                  wrapperCol={{span: 6}}>
                  {getFieldDecorator(`activeSellPoints[${index}]`, {
                    valuePropName: 'checked',
                    initialValue: sellPointIsActive,
                  })(
                    <Checkbox
                      onChange={(e) => handleSellpoints(e.target.checked, index, sellPoint.id)}
                      value={sellPoint.id}>
                    </Checkbox>,
                  )}
                </Form.Item>
                <Form.Item  label={I18n.t('products.children.price.value')} style={{width: '33.333%'}}>
                  {getFieldDecorator(`prices[${index}]`, {
                    rules: [{ required: sellPointIsActive, message: I18n.t('form.children.requiredField.value') }],
                    initialValue: (options && options.price ? options.price : ''),
                  })(
                    <InputNumber
                      min={1}
                      onKeyDown={(e: any) => {
                        if (!checkDigit(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      disabled={!sellPointIsActive}
                    />
                  )}
                </Form.Item>
                <Form.Item  
                  style={{width: '33.333%'}} 
                  label={I18n.t('products.children.isAvalible.value')} 
                  labelCol={{span: 20}} 
                  wrapperCol={{span: 4}}
                >
                  {getFieldDecorator(`available[${index}]`, {
                    valuePropName: 'checked',
                    initialValue: (options && options.available ? options.available : false),
                  })(
                    <Checkbox disabled={!sellPointIsActive}></Checkbox>,
                  )}
                </Form.Item>
              </Row>
            );
          })
          }
        </Form>
      </Spin>
    </Modal>
  );
};

export default Form.create<any>({ name: 'ProductForm' })(ProductForm);
