/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form, Collapse, Icon, Tooltip, Spin, Popconfirm, Button } from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import ClientInfoAddress from './ClientInfoAddress';
import ClientInfoDelivery from './ClientInfoDelivery';
import ClientsService from '../../../../services/ClientsService';
import DictionaryService from '../../../../services/DictionaryService';
import { getErrorNotification, getSuccessNotification, getNotification } from '../../../Notifications/Notifications';
import { SET_CLIENT_INFO, SET_CLIENT_ORDER_INFO, SET_FULL_ORDER } from '../../../../constants/createOrder';
import { ModalAddressForm } from './AddressForm';
import { DictionaryContext } from '../../../../contexts/DictionaryContextProvider';
import OrdersService from '../../../../services/OrdersService';
import { ClientModel } from '../../../../models/ClientModel';
import { OrderModel } from '../../../../models/OrderModel';
import ClientInfoPhone from './ClientInfoPhone';

import { I18n, Translate } from 'react-redux-i18n';

export interface CustomPhoneNumbers {
  mainNumberIsCustom: boolean;
  contacts: Array<{ isCustom: boolean }>;
}

const collapseKeys = {
  address: 'address',
  deliveryTime: 'deliveryTime',
  notice: 'notice',
};

const clientsService = new ClientsService();
const dictionaryService = new DictionaryService();
const ordersService = new OrdersService();

const ClientInfoModal: any =
  Form.create({ name: 'create_order_form' })(({
    visible,
    onOk,
    onCancel,
    wHeight,
    form,
    getDeliveryPrices,
    getDeliveryTypes,
    getSellPoints,
    getPaymentsTypes,
    defaultDeliveryType
  }: any
  ) => {
    const { createOrder: context, createOrderDispatch: dispatchContext } = useContext(CreateOrderContext);
    const { clientInfo } = context;
    const { dictionary } = useContext(DictionaryContext);
    const { deliveryPrices, deliveryTypes, sellPoints, paymentTypes } = dictionary;

    const clientAddressId = clientInfo && clientInfo.address && clientInfo.address.id;
    const clientAddresses = clientInfo && clientInfo.addresses ? clientInfo.addresses : null;
    const isFoundClient = clientAddresses && clientAddresses.length > 0;

    const [addresses, setAddresses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedAddress, setSelectedAdress] = useState(null as number | null);
    useEffect(() => {
      setSelectedAdress(clientAddressId);
    }, [clientAddressId]);

    const [addressModal, setAddressModal] = useState({
      open: false,
      addressId: null
    } as { open: boolean, addressId: number | null });

    const formItemStyle = {
      marginBottom: '5px',
    };

    const getAddresses = (filter?: any) => {
      dictionaryService.getAddresses(filter)
        .then((res: any) => {
          setAddresses(res);
        })
        .catch((e) => {
          getErrorNotification(e);
        });
    };

    const getClientByPhone = (phone: string) => {
      if (!phone) {
        return;
      }

      setIsLoading(true);
      clientsService.getClientByPhone(phone)
        .then((res: ClientModel) => {
          dispatchContext({
            type: SET_CLIENT_INFO,
            clientInfo: {
              ...clientInfo,
              ...res,
            }
          });
        })
        .catch((e) => {
          dispatchContext({
            type: SET_CLIENT_INFO,
            clientInfo: {
              ...clientInfo,
              firstName: null,
              contacts: [],
              addresses: [],
            }
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const handleDuplicateButton = async () => {
      try {
        setIsLoading(true);
        if (clientInfo.id) {
          const order: OrderModel = await getClientLastOrder(clientInfo.id);

          if (!order) {
            getNotification('info', 'Клієнт не має замовлень');
            return;
          }
          const deliveryPrice = Number(order.deliveryPrice && order.deliveryPrice.price);

          dispatchContext({
            type: SET_FULL_ORDER,
            clientInfo: {
              ...clientInfo,
              address: order.address,
            },
            orderInfo: {
              deliveryType: order.deliveryType,
              paymentType: order.payments.paymentType,
              deliveryPrice: order.deliveryPrice,
              sellPoint: order.sellPoint
            },
            orderData: order.purchases.map((item: any) => {
              return {
                ...item.product,
                count: item.count * item.product.minCount,
                comment: item.comment,
                commentEditable: false,
                productOptions: [{ price: item.price }]
              };
            }),
            totalSum: Number(order.productsPrice) + deliveryPrice,
          });

          getSuccessNotification('Останнє замовлення клієнта продубльовано');
        } else {
          throw new Error('Клієнта не знайдено');
        }
      } catch (e) {
        getErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    };

    const getClientLastOrder = async (id: number) => {
      const filter = [{ 'client/id': id }];
      const orderBy = 'createdDate desc';
      const top = 1;
      const lastOrder = await ordersService.getFilteredOrders({ filter, orderBy, top });
      return lastOrder[0];
    };

    const searchAddress = (text: string) => {
      if (!text) {
        setAddresses([]);
      } else {
        const filter = { 'tolower(street)': { contains: encodeURIComponent(text.toLowerCase()) } };
        getAddresses(filter);
      }
    };

    const submitForm = () => {
      form.validateFieldsAndScroll((err: any, values: any) => {
        if (!err) {
          try {
            let address = {} as any;
            if (values.typeOfDelivery === 'courier') {
              if (values.street) {
                const street: any = addresses.find(
                  (address: any) => Number(values.street) === address.id
                );
                address.street = street ? street.street : values.street;
                address.district = values.district || null;
                address.buildNumber = values.buildNumber || null;
                address.corps = values.corps || null;
                address.entrance = values.entrance || null;
                address.floor = values.floor || null;
                address.flatNumber = values.flatNumber || null;
                address.notes = values.notes || null;
              } else {
                address = clientAddresses &&
                  clientAddresses.find((address: any) => address.id === selectedAddress);
                if (!address) {
                  throw new Error('Оберіть адресу!');
                }
              }
            } else {
              address = null;
            }
            const body = clientsService.getDTOClientInfo(values, address);
            setIsLoading(true);
            clientsService.createClientWithAddress(body)
              .then((res: any) => {
                dispatchContext({
                  type: SET_CLIENT_ORDER_INFO,
                  clientInfo: {
                    ...res,
                    contacts: body.contacts,
                  },
                  orderInfo: {
                    date: values.date,
                    timeFrom: values.timeFrom,
                    timeTo: values.timeTo,
                    deliveryType:
                      deliveryTypes.find((item: any) => item.code === values.typeOfDelivery),
                    paymentType:
                      paymentTypes.find((item: any) => item.code === values.typeOfPayment),
                    deliveryPrice: values.deliveryPrice ?
                      deliveryPrices.find((item: any) => item.id === values.deliveryPrice) : null,
                    sellPoint: sellPoints.find((item: any) => item.id === values.sellPoint),
                  },
                });
                onOk();
              })
              .catch((e) => {
                getErrorNotification(e);
              })
              .finally(() => {
                setIsLoading(false);
              });
          } catch (err) {
            getErrorNotification(err);
          }
        }
      });
    };

    useEffect(() => {
      getDeliveryTypes();
      getDeliveryPrices();
      getPaymentsTypes();
      getSellPoints();
      if (clientInfo && clientInfo.phone) {
        getClientByPhone(clientInfo.phone);
      }
    }, []);

    const getAddressControls = () => {
      const style = {
        marginLeft: '8px',
      };

      return (
        isFoundClient ?
          <div>
            <Tooltip title="Додати адресу">
              <span id="create-address-btn">
                <Icon type="file-add" onClick={openCreateAddressModal} style={style} />
              </span>
            </Tooltip>
            <Tooltip title="Редагувати адресу">
              <span id="update-address-btn">
                <Icon type="edit" onClick={openUpdateAddressModal} style={style} />
              </span>
            </Tooltip>
            <span onClick={(e: any) => e.stopPropagation()}>
              <Popconfirm
                placement="top"
                icon={<Icon type="question-circle" />}
                title={'Видалити адресу?'}
                okText="Так"
                cancelText="Ні"
                onConfirm={(e: any) => {
                  deleteAddress(selectedAddress);
                }}
              >
                <Tooltip title="Видалити адресу">
                  <span id="delete-address-btn">
                    <Icon type="delete" style={style} />
                  </span>
                </Tooltip>
              </Popconfirm>
            </span>
          </div> : null
      );
    };

    const deleteAddress = (addressId: number | null) => {
      try {
        if (addressId) {
          clientsService.deleteAddress(addressId)
            .then(() => {
              getSuccessNotification('Адресу видалено');
              getClientByPhone(clientInfo.phone);
            })
            .catch((e) => {
              getErrorNotification(e);
            });
        } else {
          throw new Error('Адресу не вибрано');
        }
      } catch (e) {
        getErrorNotification(e);
      }
    };

    const openCreateAddressModal = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setAddressModal({
        open: true,
        addressId: null,
      });
    };

    const openUpdateAddressModal = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setAddressModal({
        open: true,
        addressId: selectedAddress,
      });
    };

    const closeAddressModal = (id?: number) => {
      if (id) {
        setSelectedAdress(id);
      }
      setAddressModal({
        open: false,
        addressId: null,
      });
    };

    return (
      <Modal
        maskClosable={false}
        title={
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: '35px',
          }}>
            <span>
              <Translate value={`form.children.createOrder.value`} />
            </span>
            <Tooltip overlay={I18n.t('form.children.btns.children.repeatLastOrder.value')}>
              <Button
                onClick={handleDuplicateButton}
                disabled={!!(context.id || !(clientInfo && clientInfo.id))}
              >
                <Icon type="copy" />
              </Button>
            </Tooltip>
          </div>
        }
        okText={I18n.t('form.children.btns.children.save.value')}
        cancelText={I18n.t('form.children.btns.children.cancel.value')}
        visible={visible}
        onOk={submitForm}
        onCancel={onCancel}
        centered
        width="750px"
        bodyStyle={{
          maxHeight: wHeight - 50,
          overflow: 'auto',
        }}
        destroyOnClose={true}
      >
        <Spin spinning={isLoading} >
          <Form layout="vertical" onSubmit={onOk}>
            <ClientInfoPhone
              form={form}
              getClientByPhone={getClientByPhone}
              formItemStyle={formItemStyle}
              setIsLoading={setIsLoading}
            />
            <Collapse
              defaultActiveKey={Object.values(collapseKeys)}
              expandIconPosition="right"
            >
              <Collapse.Panel header={I18n.t('form.children.deliveryDetails.value')} key={collapseKeys.deliveryTime}>
                <ClientInfoDelivery
                  form={form}
                  formItemStyle={formItemStyle}
                  deliveryTypes={deliveryTypes}
                  deliveryPrices={deliveryPrices}
                  paymentsTypes={paymentTypes}
                  sellPoints={sellPoints} />
              </Collapse.Panel>
              {defaultDeliveryType !== 'self' &&
                <Collapse.Panel
                  header={I18n.t('form.children.addressDetails.value')}
                  key={collapseKeys.address}
                  extra={getAddressControls()}>
                  <ClientInfoAddress
                    form={form}
                    formItemStyle={formItemStyle}
                    addresses={addresses}
                    searchAddress={searchAddress}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAdress}
                    isFoundClient={isFoundClient}
                    clientAddresses={clientAddresses} />
                </Collapse.Panel>}
            </Collapse>
          </Form>
          <ModalAddressForm
            getClient={() => getClientByPhone(form.getFieldValue('phone'))}
            visible={addressModal.open}
            initialAddress={
              clientInfo &&
              clientInfo.addresses &&
              clientInfo.addresses.find((address: any) => address.id === addressModal.addressId)}
            closeModal={closeAddressModal}
            formItemStyle={formItemStyle}
            addresses={addresses}
            searchAddress={searchAddress}
            addressRequired={true}
          />
        </Spin>
      </Modal>
    );
  });

export default ClientInfoModal;
