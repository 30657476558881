/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { Table, Row, Card, Switch } from 'antd';
import { getErrorNotification, getSuccessNotification } from '../../Notifications/Notifications';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import UsersService from '../../../services/UsersService';
import CourierOrdersForm from './CourierOrdersForm';
import CashHistory from './components/CashHistory';
import CouriersService from '../../../services/CouriersService';
import { CourierModel } from '../../../models/CourierModel';
import { FormTypes } from './enum/FormTypes';
import { couriersColumns } from './enum/CouriersColumns';
import { pageStateReducer, SET_FILTERS, RESET_FILTERS, SET_SORTER, SET_PAGINATION, PageStateModel } from '../../../reducers/pageStateReducer';
import { isEquivalent } from '../../../helpers/isEquivalent';
import { initialState, couriersFilters } from './enum/CouriersFilters';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import { getQueryObjFromState } from '../../../helpers/getQueryObjFromState';
import DictionaryService from '../../../services/DictionaryService';
import { SellPointModel } from '../../../models/SellPointModel';
import TableTitle from '../../TableTitle/TableTitle';
import { courierControls } from './enum/CourierControls';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { getRowClassName } from '../../../helpers/getRowClassName';
import { useFiltersPanelHeight } from '../../../hooks/useFiltersPanelHeight';
import { PagesContext } from '../../../contexts/PageStateContextProvider';
import { setCouriers as setCouriersPage } from './../../../reducers/pagesReducer';
import { getFilterInitsFromPageState } from '../../../helpers/getFilterValuesFromPageState';
import { getUser } from '../../../redux/session/sessionSelectors';
import { IStore } from '../../../redux/store';
import { connect } from 'react-redux';
import UserForm from '../Users/UserForm';
import { UserModel } from '../../../models/UserModel';
import { Translate } from 'react-redux-i18n';

const service = new CouriersService();
const usersService = new UsersService();
const dictionaryService = new DictionaryService();

const Couriers = (props: any) => {
  const defaultSellPointId = props.user.sellPoint ? props.user.sellPoint.id : null;

  const [couriers, setCouriers] = useState([]);
  const [formVisible, setFormVisible]: any = useState({
    visible: false,
    id: null,
    type: null,
  });
  const [fetchingData, setFetchingData] = useState(false);
  const { selected, handleRow: onRow, handleSelectionAfterUpdate, setMultipleMode, setSelected } = useTableSelection();
  const { panelHeight: topPanelHeight, getPanelRef } = useFiltersPanelHeight(52);
  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const [sellPoints, setSellPoints] = useState([] as SellPointModel[]);
  useEffect(() => {
    getSellPoints();
  }, []);

  const { pages: { couriers: couriersPage }, pagesDispatch } = useContext(PagesContext);
  const [state, dispatch] = useReducer(pageStateReducer, couriersPage as PageStateModel);
  const stateRef = useRef(state);
  useEffect(() => {
    if (state.needUpdate) {
      getCouriers(state.filters, state.sorter, state.pagination);
    }
    pagesDispatch(setCouriersPage(state));
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => pagesDispatch(setCouriersPage(stateRef.current));
  }, [pagesDispatch]);

  const setFilters = (filters: any) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };

  const resetFilters = () => {
    dispatch({
      type: RESET_FILTERS,
      filters: initialState.filters,
    });
  };

  // ----------------- TABLE HANDLING ----------------- //
  function handleTableChange(pagination: any, filters: any, sorter: any) {
    if (state.sorter.field !== sorter.field || state.sorter.order !== sorter.order) {
      dispatch({
        type: SET_SORTER,
        sorter
      });
    }
    if (!isEquivalent(pagination, state.pagination)) {
      dispatch({
        type: SET_PAGINATION,
        pagination
      });
    }
  }

  // ----------------- Couriers HANDLING ----------------- //

  function getCouriers(filters = state.filters, sorter = state.sorter, pagination = state.pagination) {
    setFetchingData(true);
    service.getCouriersList(getQueryObjFromState(filters, sorter, pagination))
      .then((value) => {
        setCouriers(value.items);
        handleSelectionAfterUpdate(value.items);
        if (state.pagination.total !== value.count) {
          dispatch({
            type: SET_PAGINATION,
            pagination: {...state.pagination, total: value.count},
          });
        }
      })
      .catch((e) => {
        getErrorNotification(e);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }

  function updateCourier(user: UserModel) {
    return usersService.updateUser(user.id, JSON.stringify(user))
      .then((value) => {
        getCouriers();
        getSuccessNotification('Кур\'єра оновлено');
        closeForm();
      })
      .catch((e) => {
        console.error('ERROR: ', e);
        getErrorNotification(e);
      });
  }

  const changeUsersWorkStatus = async (courierIds: number[], isWork: boolean) => {
    try {
      if (courierIds.length) {
      setFetchingData(true);
      await usersService.changeUsersWorkStatus(courierIds, isWork);
      getSuccessNotification(`Статус обраних кур'єрів змінено на ${isWork ? '"На роботі"' : '"Відсутній"'}`);
      getCouriers();
      }
    } catch (e) {
      getErrorNotification(e);
    }
  };

  const updateCourierWorkStatus = (courierId: number, isWork: boolean) => async (status: number | boolean) => {
    if (Number(isWork) !== Number(status)) {
      setFetchingData(true);
      try {
        await usersService.changeUserWorkStatus(courierId, Boolean(status));
        getSuccessNotification(`Статус кур'єра #${courierId} змінено на ${status ? '"На роботі"' : '"Відсутній"'}`);
        getCouriers();
      } catch (e) {
        getErrorNotification(e);
      }
    }
  };

  const updateCourierMoney = (courierId: number) => (sum: number | undefined) => {
    if (sum) {
      setFetchingData(true);
      return service.updateCourierMoney(courierId, sum)
        .then((value) => {
          getSuccessNotification();
          return value;
        })
        .catch((e) => {
          getErrorNotification(e);
        })
        .finally(() => {
          getCouriers();
        });
    }
  };

  const getSellPoints = () => {
    dictionaryService.getSellPoints()
      .then((sellPoints: SellPointModel[]) => setSellPoints(sellPoints))
      .catch((e) => getErrorNotification(e));
  };

  // ----------------- MODALS HANDLING ----------------- //

  const openOrdersList = (courier: CourierModel) => {
    openForm(courier.deliveryPackage ? courier.deliveryPackage.id : undefined, FormTypes.ORDERS, courier);
  };

  const openUserForm = (user: CourierModel) => {
    openForm(user.id, FormTypes.USER);
  };

  const openForm = (id?: number, type?: FormTypes, courier?: CourierModel) => {
    setFormVisible({
      visible: true,
      id: id || null,
      type: type || null,
      courier
    });
  };

  const closeForm = () => {
    setFormVisible({
      visible: false,
      id: null,
      type: null
    });
  };

  const goToOrder = (id: number) => {
    props.history.push({
      pathname: `/orders/${id}`,
      state: {
        truePath: `/couriers`
      }
    });
  };

  return (
    <div className="page-container">
      <Row className="top-panel" ref={getPanelRef}>
        <Card>
          <Row type="flex" align="middle" justify="start">
            <FiltersPanel
              filters={couriersFilters({
                sellPoints,
                inits: {
                  ...getFilterInitsFromPageState(couriersPage.filters),
                  'defaultSellPoint/id': defaultSellPointId ? [defaultSellPointId] : couriersPage.filters['defaultSellPoint/id'].value,
                },
              })}
              onFiltersConfirm={setFilters}
              onFiltersReset={resetFilters}
            />
          </Row>
        </Card>
      </Row>
      <Table
        title={() => (
          <TableTitle 
            title={
              <Row style={{ paddingLeft: '16px'}}>
                <span style={{ paddingRight: '8px'}}>
                  <Translate value={'controls.children.multipleChoise.value'} />:</span>
                <Switch size="small" onChange={(value) => setMultipleMode(value)} />
              </Row>
            }
            controls={courierControls(
              { openOrdersList, current: selected, refresh: getCouriers, changeUsersWorkStatus, openUserForm }
            )}
          />
        )}
        className="main-table"
        onChange={handleTableChange}
        onRow={onRow}
        bordered
        dataSource={couriers}
        columns={couriersColumns({ 
          openForm, updateCourierMoney, updateCourierWorkStatus, sort: state.sorter 
        })}
        rowClassName={getRowClassName(selected)}
        rowSelection={Array.isArray(selected) ? {
          selectedRowKeys: selected,
          onChange: (selectedRowKeys) => setSelected(selectedRowKeys),
        } : undefined}
        size="middle"
        rowKey="id"
        pagination={state.pagination}
        loading={{
          size: 'large',
          spinning: fetchingData,
          delay: 100
        }}
        scroll={{ y: wHeight - 100 - topPanelHeight - 68 - 32 - 42, x: wWidth - 300 }}
      />
      {
        formVisible.type === FormTypes.ORDERS && formVisible.visible &&
        <CourierOrdersForm
          visible={formVisible.type === FormTypes.ORDERS && formVisible.visible}
          closeForm={closeForm}
          packageId={formVisible.id}
          courier={formVisible.courier}
          goToOrder={goToOrder}
        />
      }
      {
        formVisible.type === FormTypes.CASH_HISTORY && formVisible.visible &&
        <CashHistory
          updateHistory={updateCourierMoney(formVisible.id)}
          visible={formVisible.type === FormTypes.CASH_HISTORY && formVisible.visible}
          closeModal={closeForm}
          courierId={formVisible.id}
        />
      }
      {
        formVisible.type === FormTypes.USER && formVisible.visible &&
        <UserForm userId={formVisible.id} closeForm={closeForm} submitUser={updateCourier}/>
      }
    </div>
  );
};

const mapStateToProps: any = (state: IStore) => ({
  user: getUser(state),
});

export default connect<{}, {}, any>(mapStateToProps)(Couriers);
