import React from 'react';
import {Layout, ConfigProvider} from 'antd';
import ukUA from 'antd/es/locale/uk_UA';
import enUS from 'antd/es/locale/en_US';
import deDE from 'antd/es/locale/de_DE';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from './Header/Header';
import NavMenu from './NavMenu/NavMenu';
import CreateOrderDrawer from '../../CreateOrderDrawer/CreateOrderDrawer';
import MainContextProvider from '../../../contexts/MainContextProvider';
import { withAuthRedirect } from '../../HOC/withAuthRedirect';
import { IStore } from '../../../redux/store';
import * as selectors from '../../../redux/session/sessionSelectors';
import RouteList from '../../Routes/enum/RouterList';
import { RouterListModel } from '../../../models/RouterListModel';
import Routes from '../../Routes/Routes';

import css from './Layout.module.css';
import '../../../styles/editable-table.css';
import '../../../styles/pages.css';
import PagesContextProvider from '../../../contexts/PageStateContextProvider';

const { Content } = Layout;
const ua = {...ukUA, Pagination: {...ukUA.Pagination, items_per_page: 'на стр.' }};

const MainLayout = (props: any) => {
  const { match, location, user, langCode } = props;

  const functions = user ? user.functions : [];

  const getRouting = (functions: RouterListModel[] = []) => {
    const routerList = RouteList;
    const result: any[] = [];
    functions.forEach((item: RouterListModel) => {
      const route = routerList.find((routerItem: any) => routerItem.code === item.path);
      if (route) {
        const pushItem = {...route, name: item.name, childrens: item.child};
        result.push(pushItem);
      }
    });
    return result;
  };

  const routings = getRouting(functions);

  let locale: typeof ua = enUS;
  switch(langCode){
    case 'uk':
      locale = ua;
      break;
    case 'de':
      locale = deDE;
      break;
  }

  return (
    <MainContextProvider>
      <ConfigProvider locale={locale}>
        <PagesContextProvider>
          <Layout className={css.mainLayout}>
            <Header />
            <Layout>
              <NavMenu match={match} location={location} />
              <Content className={css.content}>
                <Routes options={routings} />
              </Content>
            </Layout>
            <CreateOrderDrawer />
          </Layout>
        </PagesContextProvider>
      </ConfigProvider>
    </MainContextProvider>
  );
};

const mapStateToProps: any = (state: IStore) => ({
    langCode: selectors.getLangCode(state),
    user: selectors.getUser(state),
  }
);
export default compose(
  connect<{}, {}, any>(
    mapStateToProps
  ),
)(withAuthRedirect(MainLayout));