import React, {createContext, useReducer} from 'react';
import { dictionaryReducer, initialState, DictionaryState } from '../reducers/dictionayReducer';

export const DictionaryContext = createContext(({} as any));

const DictionaryContextProvider: React.FC = (props: any) => {
    const [dictionary, dispatchDictionary] = useReducer(dictionaryReducer, initialState);

    return (
        <DictionaryContext.Provider value={{ dictionary, dispatchDictionary }}>
            {props.children}
        </DictionaryContext.Provider>
    );
};

export default DictionaryContextProvider;