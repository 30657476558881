import React from 'react';
import { Tag } from 'antd';
import { DateCell } from '../../../DateCell/DateCell';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import { ColumnProps } from 'antd/lib/table';
import { RoleModel } from '../../../../models/RoleModel';
import { FunctionModel } from '../../../../models/FunctionModel';
import { I18n, Translate } from 'react-redux-i18n';

interface RolesColumnsProps {
  sort: {
    field: string,
    order: 'ascend' | 'descend',
  };
}

export const rolesColumns = ({sort}: RolesColumnsProps): Array<ColumnProps<RoleModel>> => ([
  {
    title: I18n.t('roles.children.title.value'),
    dataIndex: 'name',
    width: 300,
    sorter: true,
    sortOrder: sort.field === 'name' ? sort.order : undefined,
  },
  {
    title: I18n.t('roles.children.description.value'),
    dataIndex: 'description',
    width: 300,
    sorter: true,
    sortOrder: sort.field === 'description' ? sort.order : undefined,
  },
  {
    title: I18n.t('roles.children.functions.value'),
    dataIndex: 'menuFunctions',
    width: 300,
    render: (functions: FunctionModel[]) => (
      <div>
        {functions.map((item) => (
          <Tag 
            key={item.id}
            style={{ 
              backgroundColor: 'rgba(46, 44, 118, .08)', 
              color: '#2E2C76', 
              borderColor: '#2E2C76',
              margin: '2px 8px 2px 0'
            }}
          >
              <Translate value={`${item.path}.value`}/>
          </Tag>))}
      </div>
    ),
  },
  {
    title: I18n.t('roles.children.creationDate.value'),
    dataIndex: 'createDate',
    width: 120,
    className: 'column-centered',
    sorter: true,
    sortOrder: sort.field === 'createDate' ? sort.order : undefined,
    render: (text: string) => <DateCell dateStr={convertToLocalDate(text)} />,
  },
]);