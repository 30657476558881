import React, { useState, useEffect } from 'react';
import { OrderModel } from '../../../../models/OrderModel';
import { Row, Descriptions, TimePicker } from 'antd';
import { getAddress } from '../../../../helpers/getAddress';
import moment from 'moment';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../constants/formats';
import { I18n, Translate } from 'react-redux-i18n';

interface OrderSuccessProps {
  order: OrderModel;
  updated?: boolean;
  stateRef: any;
}

const { Item } = Descriptions;

const OrderSuccess = ({ order, updated, stateRef }: OrderSuccessProps) => {
  const courier = order.deliveryType.code === 'courier';
  const now = moment();
  const minExecuteDate = moment(order.minExecuteDate);

  const [timeToPrepare, setTimeToPrepare] = useState(now);
  useEffect(() => {
    stateRef.current = { timeToPrepare:  moment(timeToPrepare).format(TIME_FORMAT)};
  }, [timeToPrepare]);
  
  const handleTimeSelect = (time: moment.Moment) => {
    if (time) {
      if (time > minExecuteDate) {
        setTimeToPrepare(minExecuteDate);
      } else if (time < now) {
        setTimeToPrepare(now);
      } else {
        setTimeToPrepare(time);
      }
    }
  };

  const getDisabledHours = () => {
    const disabled: number[] = [];
    for (let i = 0; i < 24; i++) {
      if (i < now.hour() || i > minExecuteDate.hour()) {
        disabled.push(i);
      }
    }
    return disabled;
  };

  const getDisabledMinutes = (hour: number) => {
    const disabled: number[] = [];
    for (let i = 0; i < 60; i += 5) {
      if (hour === minExecuteDate.hour() && i > minExecuteDate.minute()) {
        disabled.push(i);
      }
      if (hour === now.hour() && i < now.minute()) {
        disabled.push(i);
      }
    }
    return disabled;
  };

  return (
    <div>
      <Row>
        <h3>{I18n.t('orders.children.orderDrawer.children.orderCreateSuccessHeading.value').replace(/\$.*\$/, order.id.toString())}</h3>
      </Row>
      <Descriptions
        column={2}
        className="order-success-descriptions"
      >
        <Item label={I18n.t('form.children.deliveryType.value')} span={1}>
          <Translate value={`form.children.deliveryType.children.${order.deliveryType.code}.value`} />
        </Item>
        {courier && 
        <Item label={I18n.t('form.children.deliveryPrice.value')} span={1}>
          {order.deliveryPrice.price}
        </Item>}
        {courier && 
          <Item label={I18n.t('orders.children.orderDrawer.children.address.value')} span={2}>
            {getAddress(order.address)}
          </Item>
        }
        <Item label={I18n.t('orders.children.ordersTable.children.salePoint.value')} span={2}>
          {order.sellPoint.name}
        </Item>
        <Item label={I18n.t('form.children.deliveryDate.value')} span={2}>
          {`${moment(order.minExecuteDate).format(DATE_TIME_FORMAT)} - ${moment(order.maxExecuteDate).format(TIME_FORMAT)}`}
        </Item>
        <Item label={I18n.t('orders.children.orderDrawer.children.orderReadyTime.value')} span={2}>
          <TimePicker
            value={timeToPrepare}
            hideDisabledOptions
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
            placeholder=""
            style={{width: '100px'}}
            format={TIME_FORMAT}
            minuteStep={5}
            onChange={handleTimeSelect} />
        </Item>
      </Descriptions>
    </div>
  );
};

export default OrderSuccess;