import React from 'react';
import { Row, Col, Icon, Popconfirm } from 'antd';
import { getAddress } from '../../../../helpers/getAddress';
import { useDrag } from 'react-dnd';
import { deliveriesDnDTypes } from '../../../../constants/deliveriesDnDTypes';
import { I18n } from 'react-redux-i18n';

const DraggableCourierOrder = (props: any) => {
  const { order , onDelete, onDropped } = props;

  const [, drag] = useDrag({
    item: {
      ...order,
      type: deliveriesDnDTypes.ORDER,
    },
    end: (item: any, monitor) => {
      const dropTarget = monitor.getDropResult();
      if (dropTarget.name === 'couriers' && item.courierId !== dropTarget.data.id) {
        onDropped([item], dropTarget);
      } else if (dropTarget.name === 'orders') {
        onDelete(item.id);
      }
    }
  });

  return (
    <div ref={drag} style={{ ...props.style, cursor: 'move' }} onClick={(e) => e.stopPropagation()}>
      <Row gutter={8} key={order.id}>
        <Col span={3}>
          #{order.id}
        </Col>
        <Col span={19} style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          <Icon type="car" />
          {`\t: ${getAddress(order.address)}`}
        </Col>
        <Col span={2} onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            placement="topRight"
            arrowPointAtCenter
            title={I18n.t('deliveries.children.couriersList.children.deleteOrderFromPackage.value')}
            okText={I18n.t('system.children.modals.children.yes.value')}
            cancelText={I18n.t('system.children.modals.children.no.value')}
            onConfirm={() => onDelete(order.id)}>
            <Icon type="close" />
          </Popconfirm>
        </Col>
      </Row>
    </div>
  );
};

export default DraggableCourierOrder;