import { I18n } from 'react-redux-i18n';
import { PageStateModel } from './../../../../reducers/pageStateReducer';
import { SellPointModel } from './../../../../models/SellPointModel';
import { FilterTypes } from './../../../../constants/filterTypes';
import { Moment } from 'moment';

interface DeliveryPackageFiltersProps {
  sellPoints: SellPointModel[];
  districts: any[];
  inits?: {
    sellPoints?: Array<SellPointModel['id']>;
    districts?: any[];
    minExecuteDate?: Moment[];
  };
}

export const deliveryPackageFilters = ({sellPoints, districts, inits}: DeliveryPackageFiltersProps) => ([
  {
    filterIndex: 'sellPoints',
    type: FilterTypes.SELECT_MULTIPLE,
    placeholder: I18n.t('form.children.salePoint.value'),
    values: sellPoints,
    initialValue: (inits && inits.sellPoints) || [],
  },
  {
    filterIndex: 'district',
    type: FilterTypes.SELECT_MULTIPLE,
    placeholder: I18n.t('form.children.region.value'),
    values: districts,
    initialValue: (inits && inits.districts) || [],
  },
  {
    filterIndex: 'minExecuteDate',
    type: FilterTypes.DATE_RANGE,
    placeholder: [
      I18n.t('form.children.deliveryDate.children.complexPlaceholder1.value'), 
      I18n.t('form.children.deliveryDate.children.complexPlaceholder2.value'),
    ],
    initialValue: (inits && inits.minExecuteDate) || [],
    // TO DO:
    // mode: ['time', 'time'],
  }
]);

export const deliveryPackageInitialState: PageStateModel = {
  filters: {
    sellPoints: {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
    district: {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    },
    minExecuteDate: {
      type: FilterTypes.DATE_RANGE,
      value: [],
    }
  }
};