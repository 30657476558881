import React from 'react';
import DeliveryContextProvider from './DeliveryContextProvider';

export const withDeliveryContext = (Component: any) => {
  return ((props: any) => {
    return (
      <DeliveryContextProvider>
        <Component {...props} />
      </DeliveryContextProvider>
    );
  });
};