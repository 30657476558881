import {setClient, setClientError, setClientLoading} from './clientActions';
import ClientsService from '../../services/ClientsService';

const service = new ClientsService();

export const fetchClient = (id: any) => async (dispatch: any) => {
  dispatch(setClientLoading(true));

  try {
    const res = await service.getClient(id);
    dispatch(setClient(res));
  } catch (e) {
    dispatch(setClientError(e));
  } finally {
    dispatch(setClientLoading(false));
  }
};