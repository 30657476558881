import React from 'react';
import {
    Radio,
    Icon,
} from 'antd';

interface RadioGroupViewOrdersModel {
    type: string;
    onChange: any;
}

const RadioGroupViewOrders: React.FC<RadioGroupViewOrdersModel> = ({
    type,
    onChange,
}: RadioGroupViewOrdersModel) => {
    return (
        <Radio.Group
            defaultValue={type}
            buttonStyle="solid"
            onChange={onChange}>
            <Radio.Button 
                value="list"
                style={{textAlign: 'center'}}>
                <Icon type="bars" />
            </Radio.Button>
            <Radio.Button 
                value="grid"
                style={{textAlign: 'center'}}>
                <Icon type="appstore" />
            </Radio.Button>
        </Radio.Group>
    );
};

export default RadioGroupViewOrders;
