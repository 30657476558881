import { I18n } from 'react-redux-i18n';
import { handleDigitInputOnKeyDown } from './../../../../helpers/checkDigit';
import { SellPointModel } from './../../../../models/SellPointModel';
import { PageStateModel } from './../../../../reducers/pageStateReducer';
import { FilterTypes } from '../../../../constants/filterTypes';

interface CouriersFiltersModel {
  sellPoints: SellPointModel[];
  inits: {
    [x: string]: any,
  };
}

export const couriersFilters = ({ sellPoints, inits }: CouriersFiltersModel) => {
  return [
    {
      filterIndex: 'phone',
      type: FilterTypes.SEARCH,
      placeholder: I18n.t('form.children.phone.value'),
      className: 'phone-filter-input',
      onKeyDown: handleDigitInputOnKeyDown,
      initialValue: (inits && inits.phone) || '',
    },
    {
      filterIndex: 'firstName',
      type: FilterTypes.SEARCH,
      placeholder: I18n.t('form.children.firstName.value'),
      initialValue: (inits && inits.firstName) || '',
    },
    {
      filterIndex: 'lastName',
      type: FilterTypes.SEARCH,
      placeholder: I18n.t('form.children.lastName.value'),
      initialValue: (inits && inits.lastName) || '',
    },
    {
      filterIndex: 'isActive',
      type: FilterTypes.SELECT,
      placeholder: I18n.t('form.children.status.value'),
      values: [{ title: 'На роботі', value: true }, { title: 'Відсутній', value: false }],
      keyField: 'value',
      showField: 'title',
      initialValue: (inits && inits.isActive) || undefined,
    },
    {
      filterIndex: 'defaultSellPoint/id',
      type: FilterTypes.SELECT_MULTIPLE,
      values: sellPoints,
      placeholder: I18n.t('form.children.salePoint.value'),
      showField: 'name',
      keyField: 'id',
      initialValue: (inits && inits['defaultSellPoint/id']) || [],
    },
  ];
};

export const initialState: PageStateModel = {
  filters: {
    phone: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    firstName: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    lastName: {
      type: FilterTypes.SEARCH,
      value: '',
    },
    'isActive': {
      type: FilterTypes.SELECT,
      value: undefined,
    },
    'defaultSellPoint/id': {
      type: FilterTypes.SELECT_MULTIPLE,
      value: [],
    }
  },
  sorter: {
    order: 'descend',
    field: 'isActive',
  },
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 10,
  },
  needUpdate: true,
  isWithFilters: false,
};