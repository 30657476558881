import React, { useContext, useState } from 'react';
import { Input, Row, Col, Form, AutoComplete, Modal, Spin } from 'antd';
import { CreateOrderContext } from '../../../../contexts/CreateOrderContextProvider';
import ClientsService from '../../../../services/ClientsService';
import { getErrorNotification, getSuccessNotification } from '../../../Notifications/Notifications';

import { I18n } from 'react-redux-i18n';

const clientsService = new ClientsService();

export const AddressForm = (props: any) => {
  const { form, formItemStyle, addresses, searchAddress, initialAddress } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const handleSelect = (value: any) => {
    const address = addresses.find((item: any) => item.id === Number(value));
    if (address) {
        form.setFieldsValue({district: address.district});
    }
  };

  const renderAddress = addresses.map((item: any) => (
      <AutoComplete.Option key={item.id} value={item.id.toString()}>
          {item.street}
      </AutoComplete.Option>
  ));

  const addressRequired = props.addressRequired || getFieldValue('typeOfDelivery') === 'courier';
  return (<>
    <Row gutter={6}>
      <Col span={12}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.street.value')} style={formItemStyle}>
          {getFieldDecorator('street', {
            rules: [{ required: addressRequired, message: I18n.t('form.children.requiredField.value') }],
            initialValue: initialAddress && initialAddress.street,
          })(
            <AutoComplete
              dataSource={renderAddress}
              dropdownMatchSelectWidth={false}
              optionLabelProp="children"
              onSearch={searchAddress}
              onBlur={handleSelect} />,
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.district.value')} style={formItemStyle}>
          {getFieldDecorator('district', {
            initialValue: initialAddress && initialAddress.district,
          })(
            <Input disabled={true} />,
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={6}>
      <Col span={6}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.building.value')} style={formItemStyle}>
          {getFieldDecorator('buildNumber', {
            rules: [{ required: addressRequired, message: I18n.t('form.children.requiredField.value') }],
            initialValue: initialAddress && initialAddress.buildNumber,
          })(
            <Input />,
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.corps.value')} style={formItemStyle}>
          {getFieldDecorator('corps', {
            initialValue: initialAddress && initialAddress.corps,
          })(
            <Input />,
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.entrance.value')} style={formItemStyle}>
          {getFieldDecorator('entrance', {
            initialValue: initialAddress && initialAddress.entrance,
          })(
            <Input />,
          )}
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.floor.value')} style={formItemStyle}>
          {getFieldDecorator('floor', {
            initialValue: initialAddress && initialAddress.floor,
          })(
            <Input />,
          )}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.flat.value')} style={formItemStyle}>
          {getFieldDecorator('flatNumber', {
            initialValue: initialAddress && initialAddress.flatNumber,
          })(
            <Input />,
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={6}>
      <Col span={24}>
        <Form.Item label={I18n.t('form.children.addressDetails.children.notes.value')} style={formItemStyle}>
          {getFieldDecorator('notes', {
            initialValue: initialAddress && initialAddress.notes
          })(
            <Input.TextArea rows={3} />,
          )}
        </Form.Item>
      </Col>
    </Row>
  </>);
};

export const ModalAddressForm = Form.create<any>()(({
  visible, closeModal, form, initialAddress, ...props
}: any) => {
  const {createOrder: context} = useContext(CreateOrderContext);
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = () => {
    form.validateFields((err: any, values: any) => {
      if (!err) {
        setIsLoading(true);
        let handleAddress;
        const addr = props.addresses.find((item: any) => item.id === Number(values.street));
        const addressObj = {
          ...values,
          street: addr ? addr.street : values.street,
          district: addr ? addr.district : null,

        };
        for (let prop in addressObj) {
          if (addressObj.hasOwnProperty(prop)) {
            addressObj[prop] = addressObj[prop] || null;
          }
        }

        if (initialAddress) {
          values.id = initialAddress.id;
          handleAddress = clientsService.updateAddress(values.id, JSON.stringify(values));
        } else {
          handleAddress = clientsService.createAddress(JSON.stringify(values), context.clientInfo.id);
        }
        handleAddress.then((res: any) => {
          props.getClient();
          closeModal(res.id);
          getSuccessNotification(initialAddress ?
              I18n.t('form.children.addressDetails.children.addressUpdated.value') :
              I18n.t('form.children.addressDetails.children.addressCreated.value'))
        })
        .catch((e) => {
          getErrorNotification(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <Modal
      maskClosable={false}
      onOk={submitForm}
      onCancel={onCancel}
      okText={I18n.t('form.children.btns.children.save.value')}
      cancelText={I18n.t('form.children.btns.children.cancel.value')}
      title={initialAddress ? I18n.t('form.children.addressDetails.children.updateAddress.value') : I18n.t('form.children.addressDetails.children.createAddress.value')}
      visible={visible}
      destroyOnClose={true} >
      <Spin spinning={isLoading}>
        <Form>
          <AddressForm
            form={form}
            initialAddress={initialAddress}
            {...props}
          />
        </Form>
      </Spin>
    </Modal>
  );
});
