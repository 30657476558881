import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './redux/store';
import { CookiesProvider } from 'react-cookie';

const Root = () => (
    <Provider store={store}>
        <CookiesProvider>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Route component={App} />
                </Router>
            </PersistGate>
        </CookiesProvider>
    </Provider>
);

export default Root;
