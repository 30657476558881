import React from 'react';
import { List, Popconfirm, Button, Icon } from 'antd';
import { getAddress } from '../../helpers/getAddress';
import PointIcon from '../icons/PointIcon';
import css from './OrdersPackageList.module.css';
import { Translate, I18n } from 'react-redux-i18n';

export interface CourierDeliveriesProps {
  orders: any[];
  onDelete?: (id: number) => void;
  goToOrder?: (id: number) => void;
  [x: string]: any;
}

const OrdersPackageList = ({ orders, onDelete, goToOrder }: CourierDeliveriesProps) => {
  const onDel = onDelete === undefined ? (...args: any) => null : onDelete;
  const withDelete = !!onDelete;

  const goToOrd = goToOrder === undefined ? (...args: any) => null : goToOrder;
  const withLinks = !!goToOrder;

  return (
    <List
      itemLayout="horizontal"
      dataSource={orders}
      renderItem={(item: any) => (
        <List.Item 
          className={`${withLinks ? css.isLink : ''}`}
          onClick={(e) => {
            if (withLinks) {
              goToOrd(item.id);
            }
          }}
        >
          <List.Item.Meta
            avatar={
              withDelete ?
                <Popconfirm
                  title={I18n.t('deliveries.children.couriersList.children.deleteOrderFromPackage.value')}
                  okText={I18n.t('system.children.modals.children.yes.value')}
                  cancelText={I18n.t('system.children.modals.children.no.value')}
                  onConfirm={() => onDel(item.id)}>
                  <Button id="delete-order-btn" type="danger" ghost>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm> : null
            }
            title={
              <div>
                #{item.id}, <Translate value="orders.children.ordersTable.children.orderStatus.value"/>: {item.orderStatus ? I18n.t(`form.children.status.children.${item.orderStatus.code}.value`) : 'status'}
              </div>
            }
            description={
              <div>
                <PointIcon />
                {item.sellPoint && `\t${item.sellPoint.name}`}
                <span style={{ padding: '0 14px'}}><Icon type="arrow-right"/></span>
                <PointIcon destination />
                {`\t${getAddress(item.address)}`}
              </div>
            }
          />
        </List.Item>
      )}>
    </List>
  );
};

export default OrdersPackageList;