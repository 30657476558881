import { 
  getAddButtonDefaults, 
  getUpdateButtonDefaults, 
  getDeleteButtonDefaults, 
  getRefreshButtonDefaults, 
  ControlModel
} from '../../../Controls/Controls';

export interface RolesControlsProps {
  current: string | null;
  openForm: (name?: string) => void;
  deleteRole: (name: string) => void;
  refresh: () => void;
}

export const rolesControls = ({openForm, deleteRole, current, refresh}: RolesControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      onClick: () => openForm(),
    },
    {
      ...getUpdateButtonDefaults(),
      disabled: !current,
      onClick: () => current ? openForm(current) : null,
    },
    {
      ...getDeleteButtonDefaults(),
      disabled: !current,
      onClick: () => current ? deleteRole(current) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};