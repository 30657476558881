import React from 'react';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import { DateCell } from '../../../DateCell/DateCell';
import { UserModel } from '../../../../models/UserModel';
import { ColumnProps } from 'antd/lib/table';
import { RoleModel } from '../../../../models/RoleModel';
import { I18n } from 'react-redux-i18n';

interface UserColumnsArgs {
  sorter: {
    field: string;
    order?: 'ascend' | 'descend';
  };
  defaultSellPointId: number;
}

const userColumns = ({ sorter, defaultSellPointId }: UserColumnsArgs): Array<ColumnProps<UserModel>> => {
  const id = {
    title: '#',
    dataIndex: 'id',
    width: 70,
    sorter: true,
    sortOrder: sorter.field === 'id' ? sorter.order : undefined,
  };
  const roles = {
    title: I18n.t('users.children.userRoles.value'),
    dataIndex: 'roles',
    width: 120,
    render: (roles: RoleModel[]) => {
      return roles.map((role) => role.description || role.name).join(', ');
    },
  };
  const phone = {
    title: I18n.t('users.children.phone.value'),
    dataIndex: 'phone',
    width: 150,
  };
  const login = {
    title: I18n.t('users.children.login.value'),
    dataIndex: 'login',
    width: 150,
    sorter: true,
    sortOrder: sorter.field === 'login' ? sorter.order : undefined,
  };
  const firstName = {
    title: I18n.t('users.children.name.value'),
    dataIndex: 'firstName',
    width: 150,
    sorter: true,
    sortOrder: sorter.field === 'fristName' ? sorter.order : undefined,
  };
  const lastName = {
    title: I18n.t('users.children.surname.value'),
    dataIndex: 'lastName',
    width: 200,
    sorter: true,
    sortOrder: sorter.field === 'lastName' ? sorter.order : undefined,
  };
  const sellPoint = {
    title: I18n.t('users.children.salePoint.value'),
    dataIndex: 'sellPoint',
    width: 120,
    render: (value: string, row: any) => row.sellPoint ? row.sellPoint.name : '',
  };
  const lastLoginDate = {
    title: I18n.t('users.children.lastLoginDate.value'),
    dataIndex: 'lastLoginDate',
    width: 100,
    className: 'column-centered',
    render: (text: string) => convertToLocalDate(text),
  };
  const failedLoginAttempts = {
    title: I18n.t('users.children.failedLoginAttempts.value'),
    width: 120,
    className: 'column-centered',
    dataIndex: 'failedLoginAttempts',
    sorter: true,
    sortOrder: sorter.field === 'failedLoginAttempts' ? sorter.order : undefined,
  };
  const lockedDate = {
    title: I18n.t('users.children.lockDate.value'),
    dataIndex: 'lockedDate',
    className: 'column-centered',
    render: (text: string) => <DateCell dateStr={convertToLocalDate(text)} />,
    width: 110,
  };
  const isPasswordExpired = {
    title: I18n.t('users.children.passwordExpired.value'),
    dataIndex: 'isPasswordExpired',
    className: 'column-centered',
    width: 100,
  };
  const lastPasswordChangeDate = {
    title: I18n.t('users.children.lastPassword.value'),
    dataIndex: 'lastPasswordChangeDate',
    className: 'column-centered',
    sorter: true,
    sortOrder: sorter.field === 'lastPasswordChangeDate' ? sorter.order : undefined,
    width: 100,
    render: (text: string) => <DateCell dateStr={convertToLocalDate(text)} />,
  };

  if (defaultSellPointId) {
    return [
      id, roles, phone, login, firstName, lastName, lastLoginDate, failedLoginAttempts,
      lockedDate, isPasswordExpired, lastPasswordChangeDate, sellPoint,
    ];
  }

  return [
    id, roles, phone, login, firstName, lastName, sellPoint,
    lastLoginDate, failedLoginAttempts, lockedDate, isPasswordExpired, lastPasswordChangeDate,
  ];
};

export default userColumns;