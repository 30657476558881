// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChangePasswordForm_wrp__15SKN {\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    background-color: #f0f2f5;\n}\n\n.ChangePasswordForm_submitBtn__2dqH8 {\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"wrp": "ChangePasswordForm_wrp__15SKN",
	"submitBtn": "ChangePasswordForm_submitBtn__2dqH8"
};
module.exports = exports;
