import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { refreshUser, getSessionSettings, getLanguages, getLanguage } from './redux/session/sessionOperations';
import { connect } from 'react-redux';
import LoginFormPage from './components/pages/Login/LoginFormPage';
import ChangePasswordPage from './components/pages/ChangePasswordPage/ChangePasswordPage';
import { getToken } from './redux/session/sessionSelectors';
import MainLayout from './components/pages/Layout/Layout';

import { withCookies, Cookies } from 'react-cookie';

const App = (props: any) => {
  useEffect(() => {
    props.refreshUser();
    props.getSessionSettings();
    props.getLanguages();
  }, []);

  return (
    <div className="page-component">
      <Switch>
        <Route path="/login" component={LoginFormPage} />
        <Route path="/chg-pass" component={ChangePasswordPage} />
        <Route path="/" component={MainLayout}/>
      </Switch>
    </div>
  );
};

const mdtp: any = (state: any, ownProps: any) => ({
  token: getToken(state),
  cookies: ownProps.cookies
});
const mapDispatchToProps: any = {
  refreshUser,
  getSessionSettings,
  getLanguages,
  getLanguage,
};
export default withCookies(connect<{}, {}, any>(
  mdtp,
  mapDispatchToProps,
)(App));
