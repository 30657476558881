import React, { useState } from 'react';
import { Badge, Popconfirm, Select } from 'antd';
import { isColor } from '../../helpers/isColor';
import css from './StatusSelect.module.css';
import { I18n } from 'react-redux-i18n';

interface IProps {
  statuses: any[];
  statusValue: any;
  keyField: string;
  showField: string;
  langKey?: string;
  confirmText?: string;
  statusColors?: {
    [color: string]: string | number | number[] | string[];
  };
  setStatus: (value: any) => void;
}

const StatusSelect = (props: IProps) => {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const { statusValue, statuses, setStatus, confirmText, statusColors, keyField, showField, langKey } = props;
  const [selected, setSelected] = useState(false as any);

  const handleSelect = (value: any) => {
    if (statusValue !== value) {
        setConfirmVisible(true);
        setSelected(value);
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    setConfirmVisible(visible);
  };

  const handleConfirm = () => {
    setStatus(selected);
    setSelected(false);
  };

  return (
    <Popconfirm
      title={confirmText || 'Змінити поточний статус?'}
      visible={confirmVisible}
      onVisibleChange={handleVisibleChange}
      onConfirm={handleConfirm}
      okText={I18n.t('system.children.modals.children.yes.value')}
      cancelText={I18n.t('system.children.modals.children.no.value')}
    >
      <Select
        className={css.select}
        showArrow={false}
        value={selected === undefined ? selected : statusValue}
        onSelect={handleSelect}
      >
        {statuses.map((statusItem: any) => {
          let badgeColor: string = '#52c41a';
          if  (statusColors) {
            for (const color in statusColors) {
              if (Array.isArray(statusColors[color]) && 
                (statusColors[color] as string[]).includes(statusItem[keyField])) { 
                  badgeColor = isColor(color) ? color : badgeColor;
              } else if (statusColors[color] === statusItem[keyField]) {
                badgeColor = isColor(color) ? color : badgeColor;
              }
          }
          }

          return (
            <Select.Option
              key={statusItem[keyField]}
              value={statusItem[keyField]}
              className={`status-select-dropdown-option-${statusItem[keyField]}`}
            >
              <Badge 
                count={langKey ? I18n.t(`${langKey}.${statusItem.code}.value`) : statusItem[showField]} 
                style={{ backgroundColor: badgeColor }} className={css.badge}
              />
            </Select.Option>
          );
        })}
      </Select>
    </Popconfirm>
  );
};

export default StatusSelect;