import React from 'react';
import { DateCell } from '../../../DateCell/DateCell';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import { I18n } from 'react-redux-i18n';

export interface ClientsColumnsProps {
  sorter: {
    field: string;
    order: 'ascend' | 'descend',
  };
}

export const clientsColumns = ({
  sorter,
}: ClientsColumnsProps) => ([
  {
    title: I18n.t('clients.children.clientsTable.children.phone.value'),
    dataIndex: 'phone',
    width: 150,
  },
  {
    title: I18n.t('clients.children.clientsTable.children.firstName.value'),
    dataIndex: 'firstName',
    width: 250,
    sorter: true,
    sortOrder: sorter.field === 'firstName' ? sorter.order : undefined,
  },
  {
    title: I18n.t('clients.children.clientsTable.children.surname.value'),
    dataIndex: 'lastName',
    width: 250,
    sorter: true,
    sortOrder: sorter.field === 'lastName' ? sorter.order : undefined,
  },
  {
    title: I18n.t('clients.children.clientsTable.children.lastLoginDate.value'),
    dataIndex: 'lastLoginDate',
    className: 'column-centered',
    width: 120,
    render: (text: string) => <DateCell dateStr={convertToLocalDate(text)} />,
  },
]);

export const contactsColumns = () => ([
  {
    title: I18n.t('clients.children.clientsTable.children.phone.value'),
    dataIndex: 'phone',
  },
  {
    title: I18n.t('clients.children.clientsTable.children.firstName.value'),
    dataIndex: 'firstName',
  },
  {
    title: I18n.t('clients.children.clientsTable.children.surname.value'),
    dataIndex: 'lastName',
  },
]);