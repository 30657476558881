export const SessionType = {
  LOGIN_REQUEST: 'session/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'session/LOGIN_SUCCESS',
  LOGIN_ERROR: 'session/LOGIN_ERROR',
  SIGNUP_REQUEST: 'session/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'session/SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'session/SIGNUP_ERROR',
  LOGOUT: 'session/LOGOUT',
  REFRESH_USER_REQUEST: 'session/REFRESH_USER_REQUEST',
  REFRESH_USER_SUCCESS: 'session/REFRESH_USER_SUCCESS',
  REFRESH_USER_ERROR: 'session/REFRESH_USER_ERROR',
  REFRESH_SETTINGS_REQUEST: 'session/REFRESH_SETTINGS_REQUEST',
  REFRESH_SETTINGS_SUCCESS: 'session/REFRESH_SETTINGS_SUCCESS',
  REFRESH_SETTINGS_ERROR: 'session/REFRESH_SETTINGS_ERROR',
  CHANGE_USER_PASSWORD_REQUEST: 'session/CHANGE_USER_PASSWORD_REQUEST',
  CHANGE_USER_PASSWORD_SUCCESS: 'session/CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_ERROR: 'session/CHANGE_USER_PASSWORD_ERROR',

  GET_APP_LANGUAGES_REQUEST: 'session/GET_APP_LANGUAGES_REQUEST',
  GET_APP_LANGUAGES_SUCCESS: 'session/GET_APP_LANGUAGES_SUCCESS',
  GET_APP_LANGUAGES_ERROR: 'session/GET_APP_LANGUAGES_ERROR',
  GET_APP_LANGUAGE_REQUEST: 'session/GET_APP_LANGUAGE_REQUEST',
  GET_APP_LANGUAGE_SUCCESS: 'session/GET_APP_LANGUAGE_SUCCESS',
  GET_APP_LANGUAGE_ERROR: 'session/GET_APP_LANGUAGE_ERROR',
  SET_APP_LANGUAGE_CODE: 'session/SET_APP_LANGUAGE_CODE',
};

export const changePasswordRequest = (username: string) => ({
  type: SessionType.CHANGE_USER_PASSWORD_REQUEST,
  payload: {
    username,
  },
});
export const changePasswordSuccess = () => ({
  type: SessionType.CHANGE_USER_PASSWORD_SUCCESS,
});
export const changePasswordError = (error: any) => ({
  type: SessionType.CHANGE_USER_PASSWORD_ERROR,
  payload: {
    password: error,
  },
});
/*
 * Login
 */
export const loginRequest = () => ({
  type: SessionType.LOGIN_REQUEST,
});

export const loginSuccess = (response: any) => ({
  type: SessionType.LOGIN_SUCCESS,
  payload: { response },
});

export const loginError = (error: any) => ({
  type: SessionType.LOGIN_ERROR,
  payload: { error },
});

/*
 * Refresh
 */

export const refreshUserRequest = () => ({
  type: SessionType.REFRESH_USER_REQUEST,
});
export const refreshUserSuccess = (user: any) => ({
  type: SessionType.REFRESH_USER_SUCCESS,
  payload: { user },
});
export const refreshUserError = (error: any) => ({
  type: SessionType.REFRESH_USER_ERROR,
  payload: { error },
});

/**
 * Session Settings
 */
export const refreshSettingsRequest = () => ({
  type: SessionType.REFRESH_SETTINGS_REQUEST,
});
export const refreshSettingsSuccess = (settings: any) => ({
  type: SessionType.REFRESH_SETTINGS_SUCCESS,
  payload: { settings },
});
export const refreshSettingsError = (error: any) => ({
  type: SessionType.REFRESH_SETTINGS_ERROR,
  payload: { error },
});

/*
 * Logout
 */

export const logOutSuccess = () => ({
  type: SessionType.LOGOUT,
});

/**
 * App languages
 */
export const languagesRequest = () => ({
  type: SessionType.GET_APP_LANGUAGES_REQUEST,
});
export const languagesSuccess = (languages: any) => ({
  type: SessionType.GET_APP_LANGUAGES_SUCCESS,
  payload: { languages },
});
export const languagesError = (error: any) => ({
  type: SessionType.GET_APP_LANGUAGES_ERROR,
  payload: { error },
});

/**
 * App session language
 */
export const languageRequest = () => ({
  type: SessionType.GET_APP_LANGUAGE_REQUEST,
});
export const languageSuccess = (language: any) => ({
  type: SessionType.GET_APP_LANGUAGE_SUCCESS,
  payload: { language },
});
export const languageError = (error: any) => ({
  type: SessionType.GET_APP_LANGUAGE_ERROR,
  payload: { error },
});

export const setAppLanguageCode = (langCode: any) => ({
  type: SessionType.SET_APP_LANGUAGE_CODE,
  payload: { langCode },
});
