import { IODataModel } from './../helpers/generateODataQuery';
import HttpService from './common/httpService';
import ODataService from './common/ODataService';
import generateODataQuery from '../helpers/generateODataQuery';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class CategoriesPageService {
  private httpService: HttpService;
  private ODataService: ODataService;
  private categoriesURL: string;

  constructor() {
    this.httpService = new HttpService();
    this.ODataService = new ODataService();
    this.categoriesURL = BASE_URL + 'categories';
  }

  public getCategories(categoriesQuery?: IODataModel) {
    const query = generateODataQuery(categoriesQuery);
    return this.httpService.get(`${this.categoriesURL}${query}`);
  }

  public getActiveCategories = () => {
    const URL = BASE_URL + 'categories?$select=id&$select=name&$filter=isActive eq true';
    return this.httpService.get(URL);
  }

  public getCategory = (id: number) => {
    const URL = `${this.categoriesURL}/${id}`;
    return this.httpService.get(URL);
  }

  public createCategory = (body: any) => {
    const URL = this.categoriesURL;
    return this.httpService.post(URL, body);
  }

  public updateCategory = (id: number, body: any = null) => {
    const URL = `${this.categoriesURL}/${id}`;
    return this.httpService.post(URL, body);
  }

  public deleteCategory = (id: number) => {
    const URL = `${this.categoriesURL}/${id}`;
    return this.httpService.delete(URL);
  }

  public getImage = (uuid: string) => {
    const URL = `${BASE_URL}images/${uuid}`;
    return this.httpService.getFile(URL);
  }

  public uploadCategoryImages = (id: number, images: any) => {
    const URL = `${this.categoriesURL}/images/${id}`;
    return this.httpService.postFile(URL, images);
  }

  public deleteCategoryImages = (images: string) => {
    const URL = `${this.categoriesURL}/images`;
    return this.httpService.delete(URL, images);
  }

  public getProducts = () => {
    const URL = BASE_URL + 'products?$select=id&$select=name';
    return this.httpService.get(URL);
  }

  public getExternalIds = () => {
    const URL = `${this.categoriesURL}?$select=externalId`;
    return this.httpService.get(URL);
  }
}

export default CategoriesPageService;
