import React, { useRef } from 'react';
import { Carousel, Icon, Modal } from 'antd';
import css from './ModalCarousel.module.css';
import { getImageURL } from '../../helpers/getImageURL';

interface IProps {
  images: Array<{
    uuid: string;
  }>;
  handleCarousel: () => void;
}

const ModalCarousel = (props: IProps) => {
  let carousel: any = useRef(null);
  const modalContainerRef = useRef(null);
  
  const { images, handleCarousel } = props; 

  const previous = () => {
    carousel.prev();
  };

  const next = () => {
    carousel.next();
  };

  const closeCarousel = (e: React.MouseEvent) => {
    if (e.target === modalContainerRef.current) {
      handleCarousel();
    }
  };

  return (
    <Modal 
      destroyOnClose={true}
      visible={true}
      title={null}
      footer={null}
      width={840}
      bodyStyle={{
        width: '800px',
      }}
      onCancel={() => handleCarousel()}
    >
      <div className={css.carouselContainer}>
        <Icon
          className={css.button}
          type="left-circle"
          onClick={previous}
        />
        <Carousel ref={(node) => (carousel = node)}>
          {
            images.map((img) => {
              return (
                <div key={img.uuid} className={css.imageContainer}>
                  <img src={getImageURL(img.uuid)} alt={img.uuid} className={css.image} />
                </div>
              );
            })
          }
        </Carousel>
        <Icon
          className={`${css.button} ${css.next}`}
          type="right-circle"
          onClick={next}
        />
      </div>
    </Modal>
  );
};

export default ModalCarousel;