import React, { FormEvent, useState, useEffect } from 'react';
import {
  Form, Input,
  Transfer, Spin, Select,
  Tabs, Modal, 
} from 'antd';
import '../../../styles/modal-form.css';
import { RoleModel, RoleParameter } from '../../../models/RoleModel';
import RolesService from '../../../services/RolesService';
import { getErrorNotification } from '../../Notifications/Notifications';
import FunctionsService from '../../../services/FunctionsService';
import { FunctionModel } from '../../../models/FunctionModel';
import { ParameterModel } from '../../../models/ParameterModel';
import UsersService from '../../../services/UsersService';
import Params from './components/Params';
import { UserModel } from '../../../models/UserModel';
import { getDefaultModalProps } from '../../../constants/ModalFormConstants';
import { I18n, Translate } from 'react-redux-i18n';

const service = new RolesService();
const functionsService = new FunctionsService();
const usersService = new UsersService();

const { Option } = Select;
const { TabPane } = Tabs;

const RoleForm = (props: any) => {
  const { getFieldDecorator } = props.form;
  const { roleName, submitRole, closeForm } = props;

  const [role, setRole] = useState({} as RoleModel);
  const [users, setUsers] = useState([] as any[]);
  const [usersWithRole, setUsersWithRole]: any = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [functions, setFunctions] = useState([] as FunctionModel[]);
  const [params, setParams] = useState([] as ParameterModel[]);

  useEffect(() => {
    async function getFormInfo(roleName: string) {
      setFetchingData(true);
      try {
        const menuFunctions: Promise<FunctionModel[]> = functionsService.getFunctions();
        const params: Promise<ParameterModel[]> = service.getParams();
        if (roleName) {
          const role = service.getRole(roleName);
          const users = usersService.getUsers();
          getUsersWithRole(await users, await role);
          setRole(await role);
          setUsers(await users);
        } else {
          setUsers(await usersService.getUsers());
        }
        setParams(await params);
        setFunctions(await menuFunctions);
      } catch (e) {
        console.error(e);
        getErrorNotification(e);
      } finally {
        setFetchingData(false);
      }
    }
    getFormInfo(roleName);
  }, [roleName]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const params = values.params ?
          Object.entries(values.params).map((item) => ({ 
            paramsCode: item[0], 
            value: item[1] === '' ? null : item[1],
          })) : role.params;
        const userIDs = usersWithRole.map((key: string) => parseInt(key, 10));
        const roleObj = {
          name: values.name,
          description: values.description,
          users: userIDs,
          menuFunctions: values.menuFunctions,
          params: params || [],
        };

        setFetchingData(true);
        submitRole(roleObj)
          .finally(() => setFetchingData(false));
      }
    });
  };

  // ------------TRANSFER ---------- //
  function getUsersWithRole(users: UserModel[], role: RoleModel) {
    let usersWithRole = [] as number[];
    for (const user of users) {
      if (user.roles.map((role) => role.name).includes(role.name)) {
        usersWithRole.push(user.id);
      }
    }
    setUsersWithRole(usersWithRole);
  }

  const handleChange = (usersWithRole: any) => {
    setUsersWithRole(usersWithRole);
  };

  const transferFilterOption = (inputValue: any, option: any) => {
    return option.login.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  // ----------- PARAMS ----------- //
  const setRoleParams = (params: RoleParameter[]) => {
    setRole({ ...role, params });
  };

  return (
    <Modal
      {...getDefaultModalProps(I18n)}
      title={roleName ? I18n.t('roles.children.editRole.value') : I18n.t('roles.children.createRole.value')}
      centered={false}
      onCancel={closeForm}
      onOk={handleSubmit}
      width={576}
      bodyStyle={{
        paddingTop: 0,
        maxHeight: '70vh',
        overflow: 'auto',
      }}
      style={{
        top: '50px'
      }}
    >
      <Form onSubmit={handleSubmit} wrapperCol={{ span: 19 }} labelCol={{ span: 5}} className="modal-form">
        <Spin spinning={fetchingData}>
          <Tabs>
            <TabPane tab={I18n.t('roles.children.roleOptions.value')} key="info">
              <Form.Item  label={I18n.t('roles.children.title.value')}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                  initialValue: role.name,
                })(
                  <Input
                    disabled={!!role.name}
                  />,
                )}
              </Form.Item>
              <Form.Item  label={I18n.t('roles.children.description.value')}>
                {getFieldDecorator('description', {
                  rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
                  initialValue: role.description,
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item  label={I18n.t('roles.children.functions.value')}>
                {getFieldDecorator('menuFunctions', {
                  rules: [{ required: false, message: I18n.t('form.children.requiredField.value') }],
                  initialValue: role.menuFunctions ? role.menuFunctions.map((func) => func.id) : [],
                })(
                  <Select
                    mode="multiple"
                    optionLabelProp="title"
                    optionFilterProp="title"
                    filterOption
                    placeholder={I18n.t('roles.children.functionsPlaceholder.value')}
                  >
                    {functions.map((func) => {
                      return (
                        <Option
                          value={func.id}
                          key={func.id}
                          title={func.name}
                          disabled={!func.isActive}
                        >
                          <Translate value={`${func.path}.value`} />
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item  label={I18n.t('roles.children.roleUsers.value')}>
                <Transfer
                  locale={{
                    itemUnit: I18n.t('roles.children.transferUnit.value'),
                    itemsUnit: I18n.t('roles.children.transferUnits.value'),
                    notFoundContent: null,
                    searchPlaceholder: I18n.t('form.children.search.value'),
                  }}
                  dataSource={users}
                  showSearch
                  filterOption={transferFilterOption}
                  targetKeys={usersWithRole}
                  onChange={handleChange}
                  render={(user) => user.login}
                  rowKey={(user) => user.id}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab={I18n.t('roles.children.parameters.value')} key="params">
              <Params 
                params={params} 
                roleParams={role.params} 
                getFieldDecorator={getFieldDecorator}
                setRoleParams={setRoleParams}
              />
            </TabPane>
          </Tabs>
        </Spin>
      </Form>
    </Modal>
  );
};

export default Form.create<any>({ name: 'role_settings' })(RoleForm);