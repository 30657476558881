export enum FilterTypes {
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  // INPUT to search for equality, SEARCH to search for a substring
  SEARCH = 'SEARCH',
  INPUT = 'INPUT',
  SWITCH = 'SWITCH',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
}