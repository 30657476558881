// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NavMenu_sider__S7QeL {\n  box-shadow: 3px 0 5px -2px rgba(0,0,0,0.45);\n}\n\n.NavMenu_container__2KSp_ {\n  display: flex;\n}\n\n.NavMenu_collapseButton__4QwjN {\n  width: 100%;\n  text-align: right;\n}\n\n.NavMenu_collapseButton__4QwjN:hover {\n  width: 100%;\n  text-align: right;\n}\n\n.NavMenu_openCreateOrderButton__3p4l4 {\n  position: absolute;\n  bottom: 15px;\n  width: 100%;\n  cursor: pointer;\n  padding: 10px;\n  text-align: center;\n}\n\n.NavMenu_openCreateOrderButton__3p4l4:hover {\n  background: #acaab5;\n}", ""]);
// Exports
exports.locals = {
	"sider": "NavMenu_sider__S7QeL",
	"container": "NavMenu_container__2KSp_",
	"collapseButton": "NavMenu_collapseButton__4QwjN",
	"openCreateOrderButton": "NavMenu_openCreateOrderButton__3p4l4"
};
module.exports = exports;
