class ODataService {
  public composeQuery(url: string, filters?: any, sorter?: any, pagination?: any): string {
    let paginationQuery;
    let filtersQuery; 
    let sorterQuery;
    const fullQuery = [];

    if (filters) {
      filtersQuery = this.getFilters(filters);
      if (filtersQuery) {
        fullQuery.push(filtersQuery);
      }
    }

    if (sorter) {
      sorterQuery = this.getSorter(sorter);
      if (sorterQuery) {
        fullQuery.push(sorterQuery);
      }
    }

    if (pagination) {
      paginationQuery = this.getPagination(pagination);
      if (paginationQuery) {
        fullQuery.push(paginationQuery);
      }
    }

    fullQuery.push('$count=true');
    
    if (fullQuery.length > 0) {
      return url + '?' + fullQuery.join('&');
    } else {
      return url;
    }
  }

  private getFilters(filters: any) {
    if (!filters) {
      return '';
    }

    const props = Object.keys(filters);
    if (!props.length) {
      return '';
    }

    let queries: string[] | string = props.map((filter: string) => {
      if (filter === 'roles') {
        return `roles/any(c:contains(c, '${filters[filter].toLocaleLowerCase()}'))`;
      }
      if (filter === 'id') {
        return `id eq ${filters[filter]}`;
      }
      if (filter === 'productOptionsFilter') {
        return 'sellPoint/id eq productOptions/sellPoint/id';
      }

      const field = filter.replace(/\./g, '/');

      return `contains(tolower(${field}),'${filters[filter].toLocaleLowerCase()}')`;
    });
    queries = queries.join(' and ');
    return '$filter=' + encodeURI(queries);
  }

  private getSorter(sorter: any) {
    if (!sorter.field || !sorter) {
      return '';
    }

    const sorterField = sorter.field.replace(/\./g, '/');
    const order = (sorter.order === 'ascend' ? 'asc' : 'desc');
    return `$orderby=${sorterField} ${order}`;
  }

  private getPagination(pagination: any) {
    if (!pagination.current || !pagination) {
      return '';
    }

    const skip = pagination.pageSize * (pagination.current - 1);
    const top = pagination.pageSize;

    return `$skip=${skip}&$top=${top}`;
  }
}

export default ODataService;