import React, { useState } from 'react';
import { Icon, Input } from 'antd';

const EditableTextArea = ({ value, setValue, maxLength }: any) => {
  const [editMode, setEditMode] = useState(false);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEditMode(false);
    setValue(e.target.value);
  };

  const openEditMode = () => {
    setEditMode(true);
  };

  return (
      editMode ?
      <Input.TextArea
        maxLength={maxLength}
        autoFocus
        autoSize
        defaultValue={value}
        onBlur={handleBlur}
      /> :
      <span style={{
        fontStyle: 'italic',
        wordBreak: value && value.search(' ') < 0 ? 'break-all' : 'normal',
      }}>
        {value}
        <Icon 
          onClick={openEditMode}
          type="edit" 
          style={{
            fontSize: '85%',
            opacity: '0.8',
            marginLeft: '5px',
          }}
        />
      </span>
  );
};

export default EditableTextArea;