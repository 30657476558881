// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ModalCarousel_carouselContainer__3vhcI {\n  position: relative;\n  width: 800px;\n  height: 400px;\n}\n\n.ModalCarousel_button__2hQNZ {\n  position: absolute;\n  z-index: 1;\n  top: 50%;\n  transform: translateY(-50%);\n  color: rgba(88,88,88, .5);\n  width: 40px;\n  height: 40px;\n  font-size: 40px;\n}\n\n.ModalCarousel_next__2TqsH {\n  right: 0;\n}\n\n.ModalCarousel_imageContainer__16CHu {\n  position: relative;\n  height: 400px;\n}\n\n.ModalCarousel_image__hsv6b {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  max-height: 400px;\n  transform: translate(-50%, -50%);\n}", ""]);
// Exports
exports.locals = {
	"carouselContainer": "ModalCarousel_carouselContainer__3vhcI",
	"button": "ModalCarousel_button__2hQNZ",
	"next": "ModalCarousel_next__2TqsH",
	"imageContainer": "ModalCarousel_imageContainer__16CHu",
	"image": "ModalCarousel_image__hsv6b"
};
module.exports = exports;
