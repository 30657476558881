import React from 'react';
import { convertToLocalDate } from '../../../../helpers/converToLocalDate';
import { DateCell } from '../../../DateCell/DateCell';
import OrderStatusSelect from '../../../StatusSelect/StatusSelect';
import { getMoneyFormat } from '../../../../helpers/getMoneyFormat';
import { OrderStatusModel } from '../../../../models/OrderStatusModel';
import { TIME_FORMAT } from '../../../../constants/formats';
import { Badge } from 'antd';
import { OrderStatuses } from '../../../../constants/orderStatuses';

import { I18n } from 'react-redux-i18n';
import { OrderModel } from '../../../../models/OrderModel';

interface OrderColumnArgs {
  setOrderStatus: (id: number) => (code: string) => void;
  orderStatuses: OrderStatusModel[];
  defaultSort: {
    field: string;
    order: 'ascend' | 'descend',
  };
  defaultDeliveryType: string;
  defaultSellPointId: number;
}

const orderColumns = ({
  setOrderStatus,
  orderStatuses,
  defaultSort,
  defaultDeliveryType,
  defaultSellPointId,
}: OrderColumnArgs
) => {
  const id = {
    title: '#',
    dataIndex: 'id',
    className: 'column-centered',
    width: 65,
    sorter: true,
    sortOrder: defaultSort.field === 'id' ? defaultSort.order : undefined,
  };
  const clientPhone = {
    title: I18n.t('orders.children.ordersTable.children.phone.value'), // 'Телефон клієнта',
    dataIndex: 'client.phone',
    width: 120,
  };
  const orderAddress = {
    title: I18n.t('orders.children.ordersTable.children.address.value'), // 'Адреса',
    dataIndex: 'orderAddress',
    width: 200,
    sorter: true,
    sortOrder: defaultSort.field === 'orderAddress' ? defaultSort.order : undefined,
  };
  const sellPoint = {
    title: I18n.t('orders.children.ordersTable.children.salePoint.value'), // 'Точка продажу',
    dataIndex: 'sellPoint.name',
    sortIndex: 'sellPoint/name',
    className: 'column-centered',
    width: 130,
    sorter: true,
    sortOrder: defaultSort.field === 'sellPoint.name' ? defaultSort.order : undefined,
  };
  const deliveryType = {
    title: I18n.t('orders.children.ordersTable.children.deliveryType.value'), // 'Тип доставки',
    dataIndex: 'deliveryType.type',
    sortIndex: 'deliveryType/type',
    className: 'column-centered',
    width: 120,
    sorter: true,
    sortOrder: defaultSort.field === 'deliveryType.type' ? defaultSort.order : undefined,
    render: (type: string, order: OrderModel) => {
      return I18n.t(`form.children.deliveryType.children.${order.deliveryType.code}.value`);
    }
  };
  const minExecuteDate = {
    title: I18n.t('orders.children.ordersTable.children.deliveryTime.value'), // 'Час доставки',
    dataIndex: 'minExecuteDate',
    className: 'column-centered',
    width: 120,
    sorter: true,
    sortOrder: defaultSort.field === 'minExecuteDate' ? defaultSort.order : undefined,
    render: (text: string, order: any) => {
      if (order.minExecuteDate && order.maxExecuteDate) {
        const str = `${convertToLocalDate(text)} - ${convertToLocalDate(order.maxExecuteDate, TIME_FORMAT)}`;
        return <DateCell dateStr={str} />;
      }
      return null;
    }
  };
  const courier = {
    title: I18n.t('orders.children.ordersTable.children.courier.value'), // 'Кур\'єр',
    dataIndex: 'deliveryPackage.courier',
    className: 'column-centered',
    width: 110,
    render: (text: string, order: any) => {
      if (order.deliveryPackage && order.deliveryPackage.courier) {
        return `${order.deliveryPackage.courier.firstName} ${order.deliveryPackage.courier.lastName}`;
      }
      return '';
    }
  };
  const orderStatus = {
    title: I18n.t('orders.children.ordersTable.children.orderStatus.value'), // 'Статус',
    dataIndex: 'orderStatus.status',
    sortIndex: 'orderStatus/status',
    className: 'column-centered',
    width: 140,
    sorter: true,
    sortOrder: defaultSort.field === 'orderStatus.status' ? defaultSort.order : undefined,
    render: (status: string, record: any) => {
      return (
        <OrderStatusSelect
          statusValue={record.orderStatus.code}
          showField={'status'}
          keyField={'code'}
          langKey="form.children.status.children"
          setStatus={setOrderStatus(record.id)}
          statuses={orderStatuses}
          confirmText={I18n.t('orders.children.ordersTable.children.changeOrderStatus.value')}
          statusColors={{
            '#C7C3C3': OrderStatuses.new,
            '#33FF11': OrderStatuses.confirm,
            '#F3DD1B': OrderStatuses.inprogress,
            '#F18102': OrderStatuses.ready,
            '#4FC7FF': OrderStatuses.courier,
            '#115BDF': OrderStatuses.ontheway,
            '#67C72C': OrderStatuses.done,
            '#FF2410': OrderStatuses.reject,
          }}
        />
      );
    },
  };
  const totalPrice = {
    title: I18n.t('orders.children.ordersTable.children.price.value'), // 'Ціна',
    dataIndex: 'payments.totalPrice',
    sortIndex: 'payments/totalPrice',
    className: 'column-centered',
    width: 100,
    sorter: true,
    sortOrder: defaultSort.field === 'payments.totalPrice' ? defaultSort.order : undefined,
    render: getMoneyFormat
  };
  const paymentType = {
    title: I18n.t('orders.children.ordersTable.children.paymentType.value'), // 'Тип оплати',
    dataIndex: 'payments.paymentType.type',
    sortIndex: 'paymentType/type',
    className: 'column-centered',
    width: 110,
    sorter: true,
    sortOrder: defaultSort.field === 'payments.paymentType.type' ? defaultSort.order : undefined,
    render: (type: string, order: OrderModel) => {
      return I18n.t(`form.children.paymentType.children.${order.payments.paymentType.code}.value`); 
    }
  };
  const paymentStatus = {
    title: I18n.t('orders.children.ordersTable.children.paymentStatus.value'), // 'Статус оплати',
    dataIndex: 'payments.paymentStatus',
    className: 'column-centered',
    width: 120,
    render: (text: string, record: any) => {
      const paymentMessage: { text?: string, color?: string } = {};
      if (record.payments.paymentStatus) {
        paymentMessage.text = I18n.t('orders.children.ordersTable.children.paymentStatus.children.paid.value');
        paymentMessage.color = 'green';
      } else {
        paymentMessage.text = I18n.t('orders.children.ordersTable.children.paymentStatus.children.notPaid.value');
        paymentMessage.color = 'blue';
      }
      return (
        <Badge
          count={paymentMessage.text}
          style={{ backgroundColor: paymentMessage.color }}
        />
      );
    },
  };
  const createdDate = {
    title: I18n.t('orders.children.ordersTable.children.orderTime.value'), // 'Час замовлення',
    dataIndex: 'createdDate',
    className: 'column-centered',
    width: 150,
    sorter: true,
    sortOrder: defaultSort.field === 'createdDate' ? defaultSort.order : undefined,
    render: (date: string) => {
      const str = convertToLocalDate(date);
      return <DateCell dateStr={str} />;
    }
  };

  if (defaultDeliveryType === 'self') {
    return [
      id, clientPhone, minExecuteDate, orderStatus, totalPrice, paymentType, paymentStatus, sellPoint, deliveryType
    ];
  } else if (defaultSellPointId) {
    return [
      id, clientPhone, minExecuteDate, deliveryType, orderStatus, totalPrice, paymentType, paymentStatus, sellPoint
    ];
  }

  return [
    id, clientPhone, orderAddress, sellPoint, deliveryType,
    minExecuteDate, courier, orderStatus, totalPrice, paymentType, paymentStatus, createdDate
  ];
};

export default orderColumns;
