import React from 'react';
import { CONTROLS, getAddButtonDefaults, getUpdateButtonDefaults, getRefreshButtonDefaults, ControlModel } from '../../../Controls/Controls';
import { Icon } from 'antd';
import { UserModel } from '../../../../models/UserModel';

export interface UserControlsProps {
  currentUser?: UserModel | null;
  openForm: (id?: number) => void;
  toggleUserActivation: (id: number, lockedDate: string | null) => void;
  refresh: () => void;
}

export const userControls = ({
  openForm, toggleUserActivation, currentUser, refresh
}: UserControlsProps): ControlModel[] => {
  return [
    {
      ...getAddButtonDefaults(),
      onClick: () => openForm(),
    },
    {
      ...getUpdateButtonDefaults(),
      disabled: !currentUser,
      onClick: () => currentUser ? openForm(currentUser.id) : null,
    },
    {
      type: CONTROLS.BUTTON,
      buttonType: 'link',
      icon: <Icon type={currentUser && currentUser.lockedDate ? 'check' : 'stop'} />,
      disabled: !currentUser,
      tooltip: {
        overlay: currentUser && currentUser.lockedDate ? 'Активувати' : 'Деактивувати',
      },
      onClick: () => currentUser ? toggleUserActivation(currentUser.id, currentUser.lockedDate) : null,
    },
    {
      ...getRefreshButtonDefaults(),
      onClick: () => refresh(),
    }
  ];
};