/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import DeliveryPackagesService from '../../../../services/DeliveryPackagesService';
import { Spin } from 'antd';
import { getErrorNotification } from '../../../Notifications/Notifications';
import { DeliveryContext } from '../../../../contexts/DeliveryContextProvider';
import OrdersPackageList from '../../../OrdersPackageList/OrdersPackageList';

const deliveryPackagesService = new DeliveryPackagesService();

const CourierDeliveries: React.FC<any> = (props: any) => {
  const { onDeleteOrder, onDeletePackage, packageId, } = props;
  const onDelOrder = onDeleteOrder === undefined ? () => null : onDeleteOrder;
  const onDelPackage = onDeletePackage === undefined ? () => null : onDeletePackage;

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const { delivery } = useContext(DeliveryContext);

  const getOrders = (packageId: number) => {
    if (packageId) {
      setIsLoading(true);
      deliveryPackagesService.getOrdersByPackageId(packageId)
        .then((res: any) => {
          setOrders(res);
        })
        .catch((e) => {
          console.error('ERROR: ', e);
          getErrorNotification(e);
          setOrders([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setOrders([]);
    }
  };

  const onDelete = (orderId: number) => {
    setIsLoading(true);
    if (orders.length > 1) {
      onDelOrder(orderId);
    } else {
      onDelPackage(packageId);
    } 
  };

  useEffect(() => {
    getOrders(packageId);
  }, [packageId]);

  useEffect(() => {
    if (delivery.isRefresh) {
      getOrders(packageId);
    }
  }, [delivery.isRefresh]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <OrdersPackageList orders={orders} onDelete={onDelete}/>
      </Spin>
    </div>
  );
};

export default CourierDeliveries;