import React, { useState, useEffect } from 'react';
import DictionaryService from '../../services/DictionaryService';
import { getErrorNotification } from '../Notifications/Notifications';
import { Select } from 'antd';
import { selectFilterOption } from '../../helpers/selectFilterOption';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

interface DictionarySelectProps {
  dictionaryName: string | null;
  keyField: string;
  showField: string;
  onChange?: (value: any) => void;
  value?: any;
  [prop: string]: any;
}

const service = new DictionaryService();

const DictionarySelect = ({
  dictionaryName, keyField, showField,
  onChange, getFieldDecorator, value, item, ...restProps
}: DictionarySelectProps) => {
  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    if (dictionaryName) {
      service.getDictionary(dictionaryName)
        .then((options) => {
          setOptions(options);
        })
        .catch((e) => {
          getErrorNotification(e);
        });
    }
  }, [dictionaryName]);

  const backupOnChange = () => null;

  return (
    getFieldDecorator(`params.${item.paramsCode || item.param.code}`, {
      rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
      initialValue: item.value,
    })(<Select onChange={onChange || backupOnChange} {...restProps} filterOption={selectFilterOption}>
      {options.map((option) => <Option key={option[keyField]} value={option[keyField]}>{option[showField]}</Option>)}
    </Select>)
    
  );
};

export default DictionarySelect;