import React, { useState } from 'react';
import { Select, Form, Icon, Descriptions, Checkbox, InputNumber, DatePicker, Input, Row, Button } from 'antd';
import { ParameterModel } from '../../../../models/ParameterModel';
import { RoleParameter } from '../../../../models/RoleModel';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { checkDigit } from '../../../../helpers/checkDigit';
import DictionarySelect from '../../../DictionarySelect/DictionarySelect';
import { SelectValue } from 'antd/lib/select';
import { selectFilterOption } from '../../../../helpers/selectFilterOption';
import moment from 'moment';
import { I18n, Translate } from 'react-redux-i18n';

interface ParamsProps {
  params: ParameterModel[];
  roleParams: RoleParameter[];
  getFieldDecorator: <T extends object = {}>(
    id: keyof T, options?: GetFieldDecoratorOptions | undefined
    ) => (node: React.ReactNode) => React.ReactNode;
  setRoleParams: (params: RoleParameter[]) => void;
}

const { Option } = Select;

const Params = (props: ParamsProps) => {
  const { params, roleParams, getFieldDecorator, setRoleParams } = props;

  const [addMode, setAddMode] = useState(false);

  const handleAddButton = () => {
    setAddMode(true);
  };

  const handleParamSelect = (value: SelectValue) => {
    const param = params.find((item) => item.id === value);
    if (param) {
      setRoleParams(roleParams ? [...roleParams, { param }] : [{ param }]);
      setAddMode(false);
    }
  };

  const deleteParam = (id: number) => {
    setRoleParams(roleParams.filter((item) => item.param.id !== id));
  };

  const getParameterField = (item: RoleParameter) => {
    const fieldName = `params.${item.paramsCode || item.param.code}`;
    switch (item.param.type.code) {
      case 'boolean':
        return (
          getFieldDecorator(fieldName, {
            valuePropName: 'checked',
            initialValue: true,
            normalize: () => true
          })(
            <Checkbox />
          )
        );
      case 'number':
        return (
          getFieldDecorator(fieldName, {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
            initialValue: item.value ? JSON.parse(item.value) : 0,
          })(
            <InputNumber onKeyDown={(e) => { if (!checkDigit(e.key)) { e.preventDefault(); }} } />
          )
        );
      case 'string':
        return (
          getFieldDecorator(fieldName, {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
            initialValue: item.value || '',
          })(
            <Input />
          )
        );
      case 'date':
        return (
          getFieldDecorator(fieldName, {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
            initialValue: moment(item.value) || moment(),
          })(
            <DatePicker showToday />
          )
        );
      case 'timestamp':
        return (
          getFieldDecorator(fieldName, {
            rules: [{ required: true, message: I18n.t('form.children.requiredField.value') }],
            initialValue: moment(item.value) || moment(),
          })(
          <DatePicker showTime showToday />
          )
        );
      case 'dictionary':
        return (
            <DictionarySelect
              dictionaryName={item.param.dictionaryName}
              keyField="code"
              showField="type"
              getFieldDecorator={getFieldDecorator}
              item={item}
            />
        );
      default:
        return null;
    }
  };

  const filteredParams = params.filter((item) => {
    if (!roleParams) {
      return true;
    } else {
      return !(roleParams.map((item) => item.param.id).includes(item.id));
    }
  });

  return (
    <>
      <Row type="flex" justify="end" style={{ marginBottom: '8px' }}>
        {addMode && 
        <Select
          autoFocus
          defaultOpen
          placeholder={I18n.t('roles.children.parameterSelectPlaceholder.value')}
          onChange={handleParamSelect}
          showSearch 
          filterOption={selectFilterOption}
          style={{
            width: '200px',
            marginRight: '8px',
          }}
        >
          {filteredParams.map((item) => <Option key={item.id} value={item.id}>{item.code}</Option>)}
        </Select>}
        {!addMode && 
          <Button disabled={!filteredParams.length} onClick={handleAddButton}>
            <Translate value="roles.children.addParameter.value" />
          </Button>
        }
      </Row>
      {roleParams && roleParams.length > 0 &&
        <Descriptions bordered column={1} size="small" className="parameter-descriptions">
          {roleParams.map((item) => {
            return (
            <Descriptions.Item
              key={item.param.code}
              label={item.paramsCode || item.param.code}
            >
              <Row type="flex" justify="space-between" align="middle">
                <Form.Item className="description-form-item">
                  {getParameterField(item)}   
                </Form.Item>
                <Icon
                  type="close"
                  style={{ cursor: 'pointer', marginLeft: '6px' }}
                  onClick={() => deleteParam(item.param.id)}
                />
              </Row>
            </Descriptions.Item>
          );
        })}
        </Descriptions>
      }
    </>
  );
};

export default Params;