import React, { ReactNode } from 'react';
import { Button, Row, Icon, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { TooltipProps } from 'antd/lib/tooltip';
import { I18n } from 'react-redux-i18n';

export enum CONTROLS {
  BUTTON = 'BUTTON',
  LINK = 'LINK',
  ICON = 'ICON',
}

export interface ControlModel {
  type: CONTROLS;
  icon?: ReactNode;
  text?: string;
  url?: string;
  buttonType?: any;
  tooltip?: TooltipProps;
  [prop: string]: any;
}

export interface ControlsProps {
  controls?: ControlModel[];
}

export const getAddButtonDefaults = (): ControlModel => ({
  type: CONTROLS.BUTTON,
  buttonType: 'link',
  icon: <Icon type="file-add" />,
  tooltip: {
    overlay: I18n.t(`controls.children.create.value`),
  }
});

export const getUpdateButtonDefaults = (): ControlModel => ({
  type: CONTROLS.BUTTON,
  buttonType: 'link',
  icon: <Icon type="edit" />,
  tooltip: {
    overlay: I18n.t(`controls.children.edit.value`),
  }
});

export const getDeleteButtonDefaults = (): ControlModel => ({
  type: CONTROLS.BUTTON,
  buttonType: 'link',
  icon: <Icon type="delete" />,
  tooltip: {
    overlay: I18n.t(`controls.children.delete.value`),
  }
});

export const getRefreshButtonDefaults = (): ControlModel => ({
  type: CONTROLS.BUTTON,
  buttonType: 'link',
  icon: <Icon type="reload" />,
  tooltip: {
    overlay: I18n.t(`controls.children.refresh.value`),
  }
});

export const getApproveButtonDefaults = (): ControlModel => ({
  type: CONTROLS.BUTTON,
  buttonType: 'link',
  icon: <Icon type="check" />,
  tooltip: {
    overlay: I18n.t(`controls.children.approve.value`),
  }
});

const Controls = ({ controls }: ControlsProps) => {
  return (
    <Row>
      {controls && controls.map((item, index) => {
        const { type, icon, url, buttonType, text, tooltip, ...props } = item;

        switch (item.type) {
          case CONTROLS.ICON:
            return <Tooltip key={index} overlay {...tooltip}>{icon}</Tooltip>;
          case CONTROLS.LINK:
            return (
              <Tooltip key={index} overlay {...tooltip}>
                <Link key={index} to={url || ''} {...props}>
                  <Button type="link">{icon}{text}</Button>
                </Link>
              </Tooltip>
            );
          case CONTROLS.BUTTON:
            return (
              <Tooltip key={index} overlay {...tooltip}>
                <Button key={index} type={buttonType} {...props}>{icon}{text}</Button>
              </Tooltip>
            );
          default:
            return null;
        }
      })}
    </Row>
  );
};

export default Controls;