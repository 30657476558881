import {
    REFRESH,
    SET_IS_LOADING,
    SET_MARKED_ORDER,
    CLEAR_MARKED_ORDERS,
    SET_SELECTED_COURIER,
    CLEAR_SELECTED_COURIER,
    SET_IS_SHOW_COURIER_CONFIRM,
    SET_IS_HIDE_COURIER_CONFIRM,
    SET_SELECTED_PACKAGE,
    SET_IS_SHOW_PACKAGE_CONFIRM,
    SET_IS_HIDE_PACKAGE_CONFIRM,
    CLEAR_SELECTED_PACKAGE,
    SET_IS_SHOW_DRAWER,
    SET_IS_HIDE_DRAWER,
    SET_CURRENT_COURIER,
    CLEAR_CURRENT_COURIER,
} from '../constants/delivery';

export const initialState = {
    isRefresh: false,
    markedOrders: [],
    selectedCourier: null,
    isShowCourierConfirm: false,
    selectedPackage: null,
    isShowPackageConfirm: false,
    data: null,
    currentCourier: null,
    drawer: null,
    isShowDrawer: false,
    isLoading: false
};

const deliveryReducer = (
    state: any,
    {
        type,
        isRefresh,
        markedOrders,
        selectedCourier,
        selectedPackage,
        drawer,
        data,
        currentCourier,
        isLoading
}: any) => {
    switch (type) {
        case SET_MARKED_ORDER: {
            return { ...state, markedOrders };
        }
        case CLEAR_MARKED_ORDERS: {
            return { ...state, markedOrders: [] };
        }
        case SET_SELECTED_COURIER: {
            return { ...state, selectedCourier };
        }
        case CLEAR_SELECTED_COURIER: {
            return { ...state, selectedCourier: null };
        }
        case SET_IS_SHOW_COURIER_CONFIRM: {
            return { ...state, isShowCourierConfirm: true, data };
        }
        case SET_IS_HIDE_COURIER_CONFIRM: {
            return { ...state, isShowCourierConfirm: false, data: null };
        }
        case SET_SELECTED_PACKAGE: {
            return { ...state, selectedPackage };
        }
        case SET_IS_SHOW_PACKAGE_CONFIRM: {
            return { ...state, isShowPackageConfirm: true, data };
        }
        case SET_IS_HIDE_PACKAGE_CONFIRM: {
            return { ...state, isShowPackageConfirm: false, data: null };
        }
        case CLEAR_SELECTED_PACKAGE: {
            return { ...state, selectedPackage: null };
        }
        case SET_CURRENT_COURIER: {
            return { ...state, currentCourier };
        }
        case CLEAR_CURRENT_COURIER: {
            return { ...state, currentCourier: null };
        }
        case SET_IS_SHOW_DRAWER: {
            return { ...state, isShowDrawer: true, drawer };
        }
        case SET_IS_HIDE_DRAWER: {
            return { ...state, isShowDrawer: false, drawer: null };
        }
        case SET_IS_LOADING: {
            return { ...state, isLoading };
        }
        case REFRESH: {
            return { ...state, isRefresh, markedOrders: [] };
        }
        default: {
            return initialState;
        }
    }
};

export {deliveryReducer};