import React, { Component } from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import { IStore } from '../../../redux/store';
import * as selectors from '../../../redux/session/sessionSelectors';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

interface IPropsChangePassword {
  isChangePassword: boolean;
}

class ChangePasswordPage extends Component<IPropsChangePassword> {
  public render() {
    let { isChangePassword } = this.props;
    // @ts-ignore
    return isChangePassword ? <ChangePasswordForm /> : <Redirect to="/login" />;
  }
}

const mapStateToProps: any = (state: IStore) => ({
  isChangePassword: selectors.getChangePassword(state),
});
export default connect<{}, {}, any>(mapStateToProps)(ChangePasswordPage);
